import React, {useEffect, useState} from "react";
import Layout from "../../page-layouts/Layout/Layout";
import "../../global.css";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AvCheckbox, AvCheckboxGroup, AvField, AvForm, AvRadioGroup, AvRadio} from "availity-reactstrap-validation";
import {Button, FormGroup} from "reactstrap";
import {APP_CONFIGURATION} from "../../../app-config/configuration";
import api from '../../../app-config/axiosConfig'


const NewDocument = (props) => {
    const [category, setCategory] = useState();
    const [dealStatus, setDealStatus] = useState();
    const [editMainData, setEditMainData] = useState();
    const [checkedValues, setCheckedValues] = useState([]);
    const [checkedAllowUpload, setAllowUpload] = useState([]);
    const [checkedDocumentType, setCheckedDocumentType] = useState([]);
    const [checkedFormat, setCheckedFormat] = useState([]);
    const [mainStatus, setMainStatus] = useState('Deal')
    const [isUpdate, setIsUpdate] = useState(false);
    const [dealMainStatus, setDealMainStatus] = useState()

    const handleRadioChange = (event) => {
        setMainStatus(event.target.value);
    };

    const [urlId, setUrlId] = useState("");
    useEffect(() => {
        const {location} = props;
        const url = location.pathname;
        const documentId = url.substring(url.lastIndexOf("/") + 1);
        const validate = documentId && url.split("/").length === 4;
        if (documentId && url.split("/").length === 4) {
            const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
            api.get(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/get_master_document/${documentId}/`)
                .then(
                    (res) => {
                        setMainStatus(res.data.data.data.document_type)
                        setEditMainData(res.data.data.data);
                        setUrlId(documentId);
                        setIsUpdate(true);
                        setCheckedValues(res.data.data.data.block_visible_to);
                        setAllowUpload(res.data.data.data.allow_upload_for);
                        setCheckedDocumentType(res.data.data.data.document_type);
                        setCheckedFormat(res.data.data.data.acceptable_file_formats);

                        // alert('success')
                    },
                    (err) => {
                        // alert('fail')
                    }
                );
        }
    }, []);

    useEffect(() => {
        getAllDocumentCategory();
        getAllDealStatus();

    }, []);


    const getAllDocumentCategory = () => {
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/list_document_categories/`)
            .then(
                (res) => {
                    setCategory(res.data.data.results);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };


    const handleCheckboxChange = (value) => {
        if (!Array.isArray(checkedValues)) {
            return;
        }
        if (checkedValues.includes(value)) {
            setCheckedValues(checkedValues.filter((item) => item !== value));
        } else {
            return setCheckedValues([...checkedValues, value]);
            // setCheckedValues([value]);
        }
    };
    const handleAllowUpload = (value) => {
        if (!Array.isArray(checkedAllowUpload)) {
            return;
        }
        if (checkedAllowUpload.includes(value)) {
            setAllowUpload(checkedAllowUpload.filter((item) => item !== value));
        } else {
            return setAllowUpload([...checkedAllowUpload, value]);
        }
    };
    const handleDocumentType = (value) => {
        if (!Array.isArray(checkedDocumentType)) {
            return;
        }
        if (checkedDocumentType.includes(value)) {
            setCheckedDocumentType(
                checkedDocumentType.filter((item) => item !== value)
            );
        } else {
            // return setCheckedDocumentType([...checkedDocumentType, value]);
            return setCheckedDocumentType(value);
        }
    };
    const handleFileFormat = (value) => {
        if (!Array.isArray(checkedFormat)) {
            return;
        }
        if (checkedFormat.includes(value)) {
            setCheckedFormat(checkedFormat.filter((item) => item !== value));
        } else {
            return setCheckedFormat([...checkedFormat, value]);
        }
    };

    const handleValidSubmit = (e, values) => {
        if (isUpdate) {
            const payload = {
                category: values.category,
                name: values.name,
                block_visible_to: checkedValues,
                allow_upload_for: checkedAllowUpload,
                document_type: mainStatus,
                deal_status: values.document_required_on_deal_status,
                acceptable_file_formats: checkedFormat,
            };
            api.post(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/update_document_master/${urlId}/`, payload)
                .then((res) => {
                    if (res.status) {
                        toast("Document Updated Successfully");
                        setTimeout(() => {
                            window.location.replace("/document-management");
                        }, 1000);
                    }
                })
                .catch((err) => {
                    toast.error(err.message);
                });
        } else {
            const payload = {
                category: values.category,
                name: values.name,
                block_visible_to: checkedValues,
                allow_upload_for: checkedAllowUpload,
                document_type: mainStatus,
                deal_status: values.document_required_on_deal_status,
                acceptable_file_formats: checkedFormat,
            };
            api.post(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/create_document_master/`, payload)
                .then((res) => {
                    if (res.status) {
                        toast("Document Created Successfully");
                        setTimeout(() => {
                            window.location.replace("/document-management");
                        }, 1000);
                    }
                })
                .catch((err) => {
                    toast.error(err.message);
                });
        }
    };

    const getAllDealStatus = () => {
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/deal/list_deal_status/`)
            .then(
            (res) => {

                setDealMainStatus(res.data.status_types);
                let dealStatusData = res.data.status_types;
                dealStatusData = dealStatusData.map((data) => {
                    return {...data, key: `${data.status}`, cat: `${data.status}`};
                });
                setDealStatus(dealStatusData);
            },
            (err) => {
                // alert('fail')
            }
        );
    };

    return (
        <>
            <Layout>
                <main id="main" className="main">
                    <ToastContainer/>
                    <div className="pagetitle mt-3">
                        <h1>New Document</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    Home
                                </li>
                                <li className="breadcrumb-item active">New Documents</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section dashboard">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <AvField
                                            type="select"
                                            name="category"
                                            label="Document Category"
                                            id="Category"
                                            value={
                                                editMainData && editMainData
                                                    ? editMainData.category.id
                                                    : ""
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Please Select the Document Category",
                                                },
                                            }}
                                            className="select-input-field"
                                        >
                                            <option
                                                value={editMainData ? editMainData.category.id : ""}
                                            >
                                                {(editMainData && editMainData.category.name) ||
                                                    "Select Document Category"}
                                            </option>

                                            {category &&
                                                category.map((data) => {
                                                    return (
                                                        <option
                                                            key={data.id}
                                                            value={data.id}
                                                            label={data.name}
                                                        />
                                                    );
                                                })}
                                        </AvField>

                                        <AvField
                                            type="text"
                                            name="name"
                                            label="Document Type"
                                            id="name"
                                            placeholder="Enter Document Type"
                                            value={(editMainData && editMainData.name) || ""}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Please Enter Document Type",
                                                },
                                            }}
                                        />
                                        <AvCheckboxGroup
                                            inline
                                            name="block_visible_to"
                                            label="Block Visibility For"
                                            className="form-d-style"
                                            value={
                                                editMainData ? editMainData.block_visible_to : [""]
                                            }

                                        >
                                            <AvCheckbox
                                                value="Borrower"
                                                label="Borrower"
                                                onClick={() => handleCheckboxChange("Borrower")}
                                            />
                                            <AvCheckbox
                                                value="Lender"
                                                label="Lender"
                                                onClick={() => handleCheckboxChange("Lender")}
                                            />

                                        </AvCheckboxGroup>
                                        <AvCheckboxGroup
                                            inline
                                            name="allow_upload_for"
                                            label="Allow Upload for"
                                            required
                                            className="form-d-style"
                                            value={
                                                (editMainData && editMainData.allow_upload_for) || [""]
                                            }
                                        >
                                            <AvCheckbox
                                                label="Borrower"
                                                value="Borrower"
                                                name="Upload"
                                                onClick={() => handleAllowUpload("Borrower")}
                                            />
                                            <AvCheckbox
                                                label="Lender"
                                                value="Lender"
                                                name="allowUpload"
                                                onClick={() => handleAllowUpload("Lender")}
                                            />
                                        </AvCheckboxGroup>
                                        <AvRadioGroup
                                            inline
                                            name="document_type"
                                            label="Document Owner"
                                            className="form-d-style"
                                            // value={
                                            //     (editMainData && [editMainData.document_type]) || [""]
                                            // }
                                            value={String(mainStatus)}
                                            onChange={handleRadioChange}
                                        >
                                            <AvRadio label="Borrower" value="Borrower"/>
                                            <AvRadio label="Lender" value="Lender"/>
                                            <AvRadio label="Deal" value="Deal"/>
                                        </AvRadioGroup>

                                        <AvField
                                            type="select"
                                            name="document_required_on_deal_status"
                                            label="Document Required on Deal Status"
                                     Block Visibility For       value={editMainData ? editMainData.deal_status : ""}
                                            validate={{
                                                required: {value: true, errorMessage: 'Please Select a Deal Status'}
                                            }}

                                        >
                                            <option
                                                value={
                                                    editMainData
                                                        ? editMainData.document_required_on_deal_status
                                                        : ""
                                                }
                                            >
                                                {(editMainData &&
                                                        editMainData.document_required_on_deal_status) ||
                                                    "Select Status"}
                                            </option>

                                            {dealMainStatus &&
                                                dealMainStatus.map((data) => {
                                                    return (
                                                        <option
                                                            key={data.status}
                                                            value={data.status}
                                                            label={data.status}
                                                        />
                                                    );
                                                })}
                                        </AvField>

                                        <AvCheckboxGroup
                                            name="acceptable_file_formats"
                                            label="Acceptable File Formats"
                                            className="form-d-style"
                                            value={
                                                (editMainData &&
                                                    editMainData.acceptable_file_formats) || [""]
                                            }
                                            required

                                        >
                                            <AvCheckbox
                                                label="XLS/XLSX/Googlesheets"
                                                value="xls"
                                                onClick={() => handleFileFormat("xls")}
                                            />
                                            <AvCheckbox
                                                label="Word/Google Docs"
                                                value="doc"
                                                onClick={() => handleFileFormat("doc")}
                                            />
                                            <AvCheckbox
                                                label="PDF"
                                                value="pdf"
                                                onClick={() => handleFileFormat("pdf")}
                                            />
                                            <AvCheckbox
                                                label="PPT"
                                                value="ppt"
                                                onClick={() => handleFileFormat("ppt")}
                                            />
                                            <AvCheckbox
                                                label="TXT"
                                                value="txt"
                                                onClick={() => handleFileFormat("txt")}
                                            />
                                        </AvCheckboxGroup>
                                        <div className="mt-4 text-center create-deal-btn">
                                            <FormGroup>
                                                <Button>Submit</Button>
                                            </FormGroup>
                                        </div>
                                    </AvForm>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    );
};

export default NewDocument;
