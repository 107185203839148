import React, {useState} from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import '../../global.css';

const Layout = ({children}) => {
    const [isToggled, setToggled] = useState(false);
    const handleToggle = (childData) => {
        setToggled(childData);
    };
    return (
        <div className={isToggled ? 'toggle-sidebar' : ''}>
            <Navbar onToggle={handleToggle}/>
            <Sidebar/>
            <div>
                {children}
            </div>
        </div>
    )
}
export default Layout;