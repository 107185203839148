import React, {useEffect, useState, useContext} from "react";
import Layout from "../../page-layouts/Layout/Layout";
import "../../global.css";
import Multiselect from "multiselect-react-dropdown";
import Modal from "react-awesome-modal";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {FormGroup} from "reactstrap";
import {
    AvField,
    AvForm,
    AvRadio,
    AvRadioGroup,
} from "availity-reactstrap-validation";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {APP_CONFIGURATION} from "../../../app-config/configuration";
import api from "../../../app-config/axiosConfig";
import {PermissionsContext} from "../../../context";
import {createDuplicate, deactivateDeal, createProduct} from "../../../api-service/api-service";
import LogoutOverlay from "../logout-overlay/LogoutOverlay";

const DealManagement = () => {
    const [visible, setVisible] = useState(false);
    const [productVisible, setProductVisible] = useState(false);
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [duplicateId, setDuplicateId] = useState("");
    const [lenderVisible, setlenderVisible] = useState(false);
    const [borrowerVisibile, setBorrowerVisible] = useState(false);
    const [dealStatusVisible, setDealStatusVisible] = useState(false);
    const [borrowerDetails, setBorrowerDetails] = useState();
    const [dealStatusValue, setDealStatusValue] = useState([]);
    const [productDetails, setProductDetails] = useState();
    const [error, setErrow] = useState("");
    const [panNOId, setPanNoId] = useState("");
    const [dealStatus, setDealStatus] = useState();
    const [selectBorrowerData, setSelectBorrowerData] = useState();
    const [selectProductType, setSelectProductType] = useState();
    const [allLendersDetails, setAllLendersDetails] = useState();
    const [selectLenderData, setSelectLenderData] = useState();
    const [primaryData, setPrimaryData] = useState();
    const [tableData, setTableData] = useState([]);
    const [datefilter, setDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState();
    const [selectDealStatus, setSelectDealStatus] = useState();
    const [handleSearch, setHandleSearch] = useState("");
    const [rmSelectData, setRMSelectData] = useState();
    const [userData, setUserData] = useState();
    const [mainDealStatus, setMainDealStatus] = useState();
    const [rmStatusData, setRmStatus] = useState();
    const [cinNoId, setCinNoId] = useState();
    const [borrowerList, setBorrowerList] = useState();
    const [productTypeList, setProductList] = useState();
    const [constitution, setConstitution] = useState();
    const [mainStatus, setMainStatus] = useState(true);
    const [lenderListData, setLenderListData] = useState();
    const [showDeactivate, setShowDeactivate] = useState(false);
    const [boStatusData, setBoStatus] = useState();
    const [deactivateId, setDeactivateId] = useState("");
    const [dealMainStatus, setDealMainStatus] = useState();
    const [agentDataMain, setSelectAgentData] = useState();
    const [borrowerIdMain, setBorrrowerId] = useState();
    const [mainRmIdData, setRmIdData] = useState();
    const [dealActionType, setDealActionType] = useState("");
    const [borrowerActionType, setBorrowerActionType] = useState("");
    const [lenderActionType, setLenderActionType] = useState("");
    const [primaryLenderData, setPrimaryLenderData] = useState();
    const userType = localStorage.getItem("user_type");
    const [selectionModel, setSelectionModel] = useState([]);
    const {permissions} = useContext(PermissionsContext);
    const [overlay, setOverlay] = useState(false)
    const borrowerIdData = localStorage.getItem("user_id");
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
    });
    useEffect(() => {
        getAllborrowerData(handleSearch);
        getAllProductTypeData(handleSearch);
        getAllStatusMainData(handleSearch);
        getAllLenderData(handleSearch);
        getAllUserDataType();
        getAllDealStatus(handleSearch);
        getAllConstitution();
        getAllRmStatus(handleSearch);
        getAllBoAgent(handleSearch);
    }, [handleSearch]);

    useEffect(() => {
        function areAllPermissionsFalse(permissions) {
            // Loop through each key in the permissions object
            for (let key in permissions) {
                // Check if any permission is true, if so return false
                if (permissions[key]) {
                    return false;
                }
            }
            // If no permission is true, return true
            return true;
        }

// Call the function with your permissions object
        const allPermissionsFalse = areAllPermissionsFalse(permissions);
        setOverlay(allPermissionsFalse)
    }, [permissions]);

    useEffect(() => {
        const fetchData = async (url) => {
            try {
                const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
                const response = await api.get(url, {headers: headersParam});
                setTableData(response.data.data);
                setPageState((old) => ({
                    ...old,
                    isLoading: false,
                    data: response.data.data,
                    total: response.data.count,
                }));
            } catch (error) {
                // Handle error if needed
            }
        };

        setPageState((old) => ({...old, isLoading: true}));

        switch (userType) {
            case "admin":
                fetchData(
                    `marketkredit/api/v1/deal/?page_number=${pageState.page}&page_size=${pageState.pageSize}`
                );
                break;

            case "borrower":
                fetchData(
                    `marketkredit/api/v1/deal/list_deals/borrower/${borrowerIdData}/?page_number=${pageState.page}&page_size=${pageState.pageSize}`
                );
                break;

            default:
                fetchData(
                    `marketkredit/api/v1/deal/list_deals/lender/${borrowerIdData}/?page_number=${pageState.page}&page_size=${pageState.pageSize}`
                );
                break;
        }
    }, [pageState.page, pageState.pageSize, userType, borrowerIdData]);


    const columns = [
        {
            field: "deal",
            headerName: "Deal Name",
            width: 480,
            renderCell:
            // <div>{params.row.deal_name}</div>,
                (params) => (
                    <div>
                        {" "}
                        {permissions && permissions.retrieve_deal ? (
                            <Link
                                className="nav-link custom-link"
                                to={`/deal-setup/${params.id}`}
                            >
                                {params.row.deal_name}
                            </Link>
                        ) : (
                            <span>{params.row.deal_name}</span>
                        )}
                    </div>
                )
        },
        {
            field: "borrower",
            headerName: "Borrower",
            width: 200,
            renderCell: (params) => (
                <div>
                    {" "}
                    {permissions && permissions.retrieve_borrower ? (
                        <Link
                            className="nav-link custom-link"
                            to={userType === 'admin' || userType === 'borrower' ? `/borrower-setup/${params.row.borrower["id"]}` : '#'}
                        >
                            {params.row.borrower ? params.row.borrower["borrower_name"] : ""}
                        </Link>
                    ) : (
                        <span>{params.row.borrower ? params.row.borrower["borrower_name"] : ""}</span>
                    )}
                </div>
            ),
        },
        {
            field: "constitution",
            headerName: "Constitution",
            width: 200,
            renderCell: (params) => (
                <div>
                    {params.row.borrower.constitution
                        ? params.row.borrower.constitution
                        : ""}
                </div>
            ),
        },
        {
            field: "dealValue",
            headerName: "Deal Value",
            width: 200,
            renderCell: (params) => (
                <div>{params.row && params.row.credit_requirement}</div>
            ),
        },
        {
            field: "prefferedProductType",
            headerName: "Preffered Product Type(s)",
            width: 270,
            renderCell: (params) => <div>{params.row.product.name}</div>,
        },
        {
            field: "lender",
            headerName: "Lender",
            width: 200,
            renderCell: (params) => (
                <div>
                    {" "}
                    {permissions && permissions.retrieve_lender ? (
                        <Link
                            className={`nav-link ${userType === 'admin' || userType === 'lender' ? 'custom-link' : ''}`}
                            to={userType === 'admin' || userType === 'lender' ? `/lender-setup/${params.row.lender.id}` : '#'}
                        >
                            {params.row.lender.lender_name}
                        </Link>
                    ) : (
                        <span>{params.row.lender.lender_name}</span>
                    )}
                </div>
            ),
        },
        {
            field: "dealStatus",
            headerName: "Deal Status",
            width: 200,
            renderCell: (params) => <div>{params.row.deal_status}</div>,
        },
        {
            field: "rm",
            headerName: "Relationship Manager",
            width: 200,
            renderCell: (params) => (
                <div>
                    {params.row.relationship_manager
                        ? params.row.relationship_manager.first_name +
                        " " +
                        params.row.relationship_manager.last_name
                        : ""}
                </div>
            ),
        },
        {
            field: "boAgent",
            headerName: "BO Agent",
            width: 200,
            renderCell: (params) => <div>{params.row.bo_agent && params.row.bo_agent.first_name}</div>,
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 400,
            renderCell: (params) => (
                <div>
                    {permissions && permissions.change_deal && (
                        <button
                            className="btn edit-btn"
                            onClick={() => handleEditRedirect(params.id)}
                        >
                            Edit
                        </button>
                    )}
                    {permissions && permissions.duplicate_deal && (
                        <button
                            className="btn duplicate-btn"
                            style={{marginLeft: "10px", marginRight: "10px"}}
                            onClick={() => handleDuplicate(params.id)}
                        >
                            Duplicate
                        </button>
                    )}
                    {permissions && permissions.deactivate_deal && (
                        <button
                            className="btn deactivate-btn"
                            onClick={() => handleDeactive(params.id)}
                        >
                            Deactivate
                        </button>
                    )}
                    {permissions && permissions.view_deal_document && (
                        <button className="btn actions-btn" onClick={() => handleEditRedirect(params.id)}>
                            View
                        </button>
                    )}
                </div>
            ),
        },
    ];

    const initialValues = {
        selectBorrower: "",
        productType: "",
        selectLender: "",
        selectDate: "",
        selectStatus: "",
        panNumber: "",
        selectRM: "",
        selectBOAgent: "",
    };

    const initialCreateUserValues = {
        userSelectBorrower: "",
        creditRequirement: "",
        productType: "",
        dealStatus: "",
        lender: "",
        relationshipManager: "",
        boAgent: "",
    };

    const initialBorrowerValues = {
        borrowerName: "",
        borrowerPanNo: "",
        bName: "",
        contactNumber: "",
        emailId: "",
        designation: "",
    };

    const validationBorrowerSchema = Yup.object().shape({
        borrowerName: Yup.string().required("Borrower Name is Required"),
        borrowerPanNo: Yup.string().required("Pan No is required"),
        bName: Yup.string().required("Name is Required"),
        contactNumber: Yup.string().required("Contact Number is required"),
        emailId: Yup.string().required("Email is Required"),
        designation: Yup.string().required("Designation is Required"),
    });

    const initialCreateNewDealValues = {
        borrowerName: "",
        creditRequirements: "",
        productTypes: "",
        cDealStatus: "",
        lenders: "",
        rmanager: "",
        boAgents: "",
        primaryUserForLender: "",
        primaryUserForBorrower: "",
    };

    const validationCreateNewDealSchema = Yup.object().shape({
        borrowerName: Yup.string().required("Borrower Name is required"),
        creditRequirements: Yup.string().required("Credit Requirement is required"),
        productTypes: Yup.string().required("Product Type is required"),
        cDealStatus: Yup.string().required("Deal Status is required"),
        lenders: Yup.string().required("Lender is required"),
        rmanager: Yup.string().required("Relationship Manager is required"),
        boAgents: Yup.string().required("BO Agents is required"),
        primaryUserForBorrower: Yup.string().required(
            "Primary User for Borrower is required"
        ),
    });

    const validationUserSchema = Yup.object().shape({
        userSelectBorrower: Yup.string().required("Borrower is required"),
        creditRequirement: Yup.string().required("Credit Requirement is required"),
        productType: Yup.string().required("Produt Type is required"),
        dealStatus: Yup.string().required("Deal Status is required"),
        lender: Yup.string().required("Lender is required"),
        relationshipManager: Yup.string().required(
            "Relationship Manager is required"
        ),
        boAgent: Yup.string().required("BO Agent is required"),
    });

    const initialLenderValues = {
        createLender: "",
    };

    const validationLenderSchema = Yup.object().shape({
        createLender: Yup.string().required("Lender Name is Mandatory"),
    });

    const initialProductValues = {
        newProduct: "",
    };

    const validationProductSchema = Yup.object().shape({
        newProduct: Yup.string().required("Please Enter Product Name"),
    });

    const initialDealStatuslValues = {
        dealStatus: "",
    };

    const validationDealStatusSchema = Yup.object().shape({
        dealStatus: Yup.string().required("Please select a Deal Status"),
    });


    const handleEditRedirect = (id) => {
        window.location.replace(`/deal-setup/${id}`);
    };

    const handleDuplicate = (id) => {
        setDuplicateId(id);
        setShowDuplicate(true);
    };

    const handleDeactive = (id) => {
        setShowDeactivate(true);
        setDeactivateId(id);
    };

    const openModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
        window.location.reload();
    };

    const openNewProductModal = () => {
        setProductVisible(true);
    };

    const closeNewProductModal = () => {
        setProductVisible(false);
    };

    const openLender = () => {
        setlenderVisible(true);
    };

    const closeLender = () => {
        setlenderVisible(false);
    };

    const openBorrower = () => {
        setBorrowerVisible(true);
        setVisible(false);
    };

    const closeBorrower = () => {
        setBorrowerVisible(false);
    };

    const openDealStatus = () => {
        setDealStatusVisible(true);
    };

    const closeDealStatus = () => {
        setDealStatusVisible(false);
    };

    const handleDate = (e) => {
        setDateFilter(e.target.value);
    };

    const handleEndDate = (e) => {
        setEndDateFilter(e.target.value);
    };

    const handlePanNo = (e) => {
        setPanNoId(e.target.value);
    };

    const hanldeCinNo = (e) => {
        setCinNoId(e.target.value);
    };

    const handleSubmitClearFilter = () => {
        window.location.reload();
    };

    const handleDealSubmitWithAction = (action) => {
        setDealActionType(action);
        document.getElementById("submitFormDealCreate").click();
    };

    const handleBorrowerSubmitWithAction = (action) => {
        setBorrowerActionType(action);
        document.getElementById("submitFormBorrowerCreate").click();
    };

    const handleLenderSubmitWithAction = (action) => {
        setLenderActionType(action);
        document.getElementById("submitFormLenderCreate").click();
    };

    const closeDuplicateModal = () => {
        setShowDuplicate(false);
        setShowDeactivate(false);
    };

    const selectBorrower = (e) => {
        const arrayOfFormattedStrings = e.map((obj) => `${obj.id}`);
        setSelectBorrowerData(arrayOfFormattedStrings);
    };
    const onRemoveBorrower = (selectBorrowerData, removedItem) => {
        const updatedValues = selectBorrowerData.filter(
            (item) => item !== removedItem
        );
        const arrayOfFormattedBorrower = updatedValues.map((obj) => `${obj.id}`);
        setSelectBorrowerData(arrayOfFormattedBorrower);
    };

    const selectProduct = (e) => {
        const arrayOfFormattedProduct = e.map((obj) => `${obj.id}`);
        setSelectProductType(arrayOfFormattedProduct);
    };

    const onRemoveProduct = (selectProductType, removedItem) => {
        const updatedValues = selectProductType.filter(
            (item) => item !== removedItem
        );
        const arrayOfFormattedProduct = updatedValues.map((obj) => `${obj.id}`);
        setSelectProductType(arrayOfFormattedProduct);
    };

    const selectLender = (e) => {
        const arrayOfFormattedStrings = e.map((obj) => `${obj.id}`);
        setSelectLenderData(arrayOfFormattedStrings);
    };

    const onRemoveLender = (selectLenderData, removedItem) => {
        const updatedValues = selectLenderData.filter(
            (item) => item !== removedItem
        );
        const arrayOfFormattedLender = updatedValues.map((obj) => `${obj.id}`);
        setSelectLenderData(arrayOfFormattedLender);
    };

    const selectStatus = (e) => {
        const arrayOfFormattedStrings = e.map((obj) => `${obj.status}`);
        setSelectDealStatus(arrayOfFormattedStrings);
    };

    const onRemove = (selectDealStatus, removedItem) => {
        const updatedValues = selectDealStatus.filter(
            (item) => item !== removedItem
        );
        const arrayOfFormattedStrings = updatedValues.map((obj) => `${obj.status}`);
        setSelectDealStatus(arrayOfFormattedStrings);
    };

    const handleBoAgentData = (e) => {
        const arrayOfFormattedStrings = e.map((obj) => `${obj.id}`);
        setSelectAgentData(arrayOfFormattedStrings);
    };

    const onRemoveAgent = (agentDataMain, removedItem) => {
        const updatedValues = agentDataMain.filter((item) => item !== removedItem);
        const arrayOfFormattedAgent = updatedValues.map((obj) => `${obj.id}`);
        setSelectAgentData(arrayOfFormattedAgent);
    };

    const handleRmData = (e) => {
        const arrayOfFormattedRm = e.map((obj) => `${obj.id}`);
        setRmIdData(arrayOfFormattedRm);
    };

    const onRemoveRm = (mainRmIdData, removedItem) => {
        const updatedValues = mainRmIdData.filter((item) => item !== removedItem);
        const arrayOfFormattedRm = updatedValues.map((obj) => `${obj.id}`);
        setRmIdData(arrayOfFormattedRm);
    };

    const handleSelectionChange = (newSelection) => {
        const objectArray = newSelection.map((str) => ({id: str}));
        setDealStatusValue(objectArray);
        setSelectionModel(newSelection);
    };

    const handleRadioChange = (event) => {
        const newValue = event.target.value === "true";
        setMainStatus(newValue);
    };

    const submitDuplicate = () => {
        createDuplicate(duplicateId)
    };

    const submitDeactivate = () => {
        deactivateDeal(deactivateId)
    };

    const handleProductSubmit = (values) => {
        createProduct(values)
    };

    const getAllConstitution = () => {
        api.get(`marketkredit/api/v1/borrower/list_constitution/`)
            .then((res) => {
                setConstitution(res.data.constitution_types);
            })
            .catch((err) => {
                // alert('fail');
            });
    };

    const getAllborrowerData = (handleSearch) => {
        api.get(`marketkredit/api/v1/borrower/?search=${handleSearch}`)
            .then(
                (res) => {
                    setBorrowerList(res.data.data);
                    let borrowerDropdownData = res.data.data;
                    borrowerDropdownData = borrowerDropdownData.map((data) => {
                        return {
                            ...data,
                            key: `${data.borrower_name}`,
                            cat: `${data.borrower_name}`,
                        };
                    });
                    setBorrowerDetails(borrowerDropdownData);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };

    const getAllDealData = () => {
        setPageState((old) => ({...old, isLoading: true}));
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .get(
                `marketkredit/api/v1/deal/?page_number=${pageState.page}&page_size=${pageState.pageSize}`,
                {headers: headersParam}
            )
            .then(
                (res) => {
                    if (res) {
                        setTableData(res.data.data);
                        setPageState((old) => ({
                            ...old,
                            isLoading: false,
                            data: res.data.data,
                            total: 20,
                        }));
                    }
                },
                (err) => {
                }
            );
    };

    const getAllLenderData = (handleSearch) => {
        api.get(`marketkredit/api/v1/lender/?search=${handleSearch}`)
            .then(
                (res) => {
                    setLenderListData(res.data.data);
                    let lenderDropdownData = res.data.data;
                    lenderDropdownData = lenderDropdownData.map((data) => {
                        return {
                            ...data,
                            key: `${data.lender_name}`,
                            cat: `${data.lender_name}`,
                        };
                    });
                    setAllLendersDetails(lenderDropdownData);
                },
                (err) => {
                }
            );
    };

    const getAllUserDataType = () => {
        api.get(`marketkredit/api/v1/user/`).then(
            (res) => {
                setUserData(res.data.data);
                let userDropdown = res.data.data;
                userDropdown = userDropdown.map((data) => {
                    return {
                        ...data,
                        key: `${data.first_name}`,
                        cat: `${data.first_name}`,
                    };
                });
                setRMSelectData(userDropdown);
            },
            (err) => {
                // alert('fail')
            }
        );
    };

    const getAllProductTypeData = (handleSearch) => {
        api
            .get(`marketkredit/api/v1/product/?search=${handleSearch}`)
            .then(
                (res) => {
                    setProductList(res.data.data);
                    let productTypeData = res.data.data;
                    productTypeData = productTypeData.map((data) => {
                        return {...data, key: `${data.name}`, cat: `${data.name}`};
                    });
                    setProductDetails(productTypeData);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };

    const getAllStatusMainData = (handleSearch) => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .get(`marketkredit/api/v1/deal/list_deal_status/`, {
                headers: headersParam,
            })
            .then(
                (res) => {
                    setDealMainStatus(res.data.data);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };

    const getAllDealStatus = (handleSearch) => {
        api.get(
            `marketkredit/api/v1/deal/list_deal_status/?search=${handleSearch}`)
            .then(
                (res) => {
                    setMainDealStatus(res.data.status_types);
                    setDealMainStatus(res.data.status_types);
                    let dealStatusData = res.data.status_types;
                    dealStatusData = dealStatusData.map((data) => {
                        return {...data, key: `${data.status}`, cat: `${data.status}`};
                    });
                    setDealStatus(dealStatusData);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };

    const getAllRmStatus = (handleSearch) => {
        api
            .get(
                `marketkredit/api/v1/user/list_agent/relationship_manager/?search=${handleSearch}`)
            .then(
                (res) => {
                    let rmStatus = res.data.data;
                    rmStatus = rmStatus.map((data) => {
                        return {
                            ...data,
                            key: `${data.first_name + " " + data.last_name}`,
                            cat: `${data.id}`,
                        };
                    });
                    setRmStatus(rmStatus);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };

    const getAllBoAgent = (handleSearch) => {
        api.get(
            `marketkredit/api/v1/user/list_agent/bo_agent/?search=${handleSearch}`)
            .then(
                (res) => {
                    let boStatus = res.data.data;
                    boStatus = boStatus.map((data) => {
                        return {
                            ...data,
                            key: `${data.first_name + " " + data.last_name}`,
                            cat: `${data.id}`,
                        };
                    });
                    setBoStatus(boStatus);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };

    const handleSubmitFilter = (values) => {
        if (userType === 'admin') {
            setPageState((old) => ({...old, isLoading: true}));
            // Construct the query string
            let queryString = `marketkredit/api/v1/deal/?page_number=${pageState.page}&page_size=${pageState.pageSize}`;
            queryString +=
                selectLenderData && selectLenderData.length > 0
                    ? `&lender_id__in=${selectLenderData}`
                    : "";
            queryString +=
                selectBorrowerData && selectBorrowerData.length > 0
                    ? `&borrower_id__in=${selectBorrowerData}`
                    : "";
            queryString += datefilter ? `&created_at__gte=${datefilter}` : "";
            queryString += endDateFilter ? `&created_at__lte=${endDateFilter}` : "";
            queryString += selectProductType
                ? `&product_id__in=${selectProductType}`
                : "";
            queryString +=
                agentDataMain && agentDataMain.length > 0
                    ? `&bo_agent_id__in=${agentDataMain}`
                    : "";
            queryString +=
                mainRmIdData && mainRmIdData.length > 0
                    ? `&relationship_manager_id__in=${mainRmIdData}`
                    : "";
            queryString += cinNoId ? `&borrower__cin_number__icontains=${cinNoId}` : "";
            queryString += panNOId ? `&borrower__pan_number__icontains=${panNOId}` : "";
            queryString +=
                selectDealStatus && selectDealStatus.length > 0
                    ? `&deal_status=${selectDealStatus}`
                    : "";

            api.get(queryString)
                .then((res) => {
                    if (res) {
                        setTableData(res.data.data);
                        setPageState((old) => ({
                            ...old,
                            isLoading: false,
                            data: res.data.data,
                            total: res.data.count,
                        }));
                    }
                })
                .catch((err) => {
                    // Handle error here, if needed
                });
        } else if (userType === 'borrower') {
            setPageState((old) => ({...old, isLoading: true}));
            // Construct the query string
            let queryString = `marketkredit/api/v1/deal/list_deals/borrower/${borrowerIdData}/?page_number=${pageState.page}&page_size=${pageState.pageSize}`;
            queryString +=
                selectLenderData && selectLenderData.length > 0
                    ? `&lender_id__in=${selectLenderData}`
                    : "";
            queryString +=
                selectBorrowerData && selectBorrowerData.length > 0
                    ? `&borrower_id__in=${selectBorrowerData}`
                    : "";
            queryString += datefilter ? `&created_at__gte=${datefilter}` : "";
            queryString += endDateFilter ? `&created_at__lte=${endDateFilter}` : "";
            queryString += selectProductType
                ? `&product_id__in=${selectProductType}`
                : "";
            queryString +=
                agentDataMain && agentDataMain.length > 0
                    ? `&bo_agent_id__in=${agentDataMain}`
                    : "";
            queryString +=
                mainRmIdData && mainRmIdData.length > 0
                    ? `&relationship_manager_id__in=${mainRmIdData}`
                    : "";
            queryString += cinNoId ? `&borrower__cin_number__icontains=${cinNoId}` : "";
            queryString += panNOId ? `&borrower__pan_number__icontains=${panNOId}` : "";
            queryString +=
                selectDealStatus && selectDealStatus.length > 0
                    ? `&deal_status=${selectDealStatus}`
                    : "";

            api.get(queryString)
                .then((res) => {
                    if (res) {
                        setTableData(res.data.data);
                        setPageState((old) => ({
                            ...old,
                            isLoading: false,
                            data: res.data.data,
                            total: res.data.count,
                        }));
                    }
                })
                .catch((err) => {
                    // Handle error here, if needed
                });
        } else {
            setPageState((old) => ({...old, isLoading: true}));
            // Construct the query string
            let queryString = `marketkredit/api/v1/deal/list_deals/lender/${borrowerIdData}/?page_number=${pageState.page}&page_size=${pageState.pageSize}`;
            queryString +=
                selectLenderData && selectLenderData.length > 0
                    ? `&lender_id__in=${selectLenderData}`
                    : "";
            queryString +=
                selectBorrowerData && selectBorrowerData.length > 0
                    ? `&borrower_id__in=${selectBorrowerData}`
                    : "";
            queryString += datefilter ? `&created_at__gte=${datefilter}` : "";
            queryString += endDateFilter ? `&created_at__lte=${endDateFilter}` : "";
            queryString += selectProductType
                ? `&product_id__in=${selectProductType}`
                : "";
            queryString +=
                agentDataMain && agentDataMain.length > 0
                    ? `&bo_agent_id__in=${agentDataMain}`
                    : "";
            queryString +=
                mainRmIdData && mainRmIdData.length > 0
                    ? `&relationship_manager_id__in=${mainRmIdData}`
                    : "";
            queryString += cinNoId ? `&borrower__cin_number__icontains=${cinNoId}` : "";
            queryString += panNOId ? `&borrower__pan_number__icontains=${panNOId}` : "";
            queryString +=
                selectDealStatus && selectDealStatus.length > 0
                    ? `&deal_status=${selectDealStatus}`
                    : "";

            api.get(queryString)
                .then((res) => {
                    if (res) {
                        setTableData(res.data.data);
                        setPageState((old) => ({
                            ...old,
                            isLoading: false,
                            data: res.data.data,
                            total: res.data.count,
                        }));
                    }
                })
                .catch((err) => {
                    // Handle error here, if needed
                });
        }
    };


    const handleLenderSubmit = (values) => {
        const payload = {
            lender_name: values.createLender,
        };
        api.post(`marketkredit/api/v1/lender/create_lender/`, payload)
            .then((res) => {
                if (res.status) {
                    toast("Lender Created Successfully");
                    const id = res.data.data.id;
                    if (lenderActionType === "proceed") {
                        window.location.href = `/lender-setup/${id}`;
                    } else if (lenderActionType === "quickCreate") {
                        window.location.reload();
                    } else {
                        closeModal();
                    }
                }
            })
            .catch((err) => {
                // Handle the error
            });
    };

    const handleDealSubmit = (values) => {
        const newKeyValuePair = {deal_status: values.dealStatus};
        const newArray = dealStatusValue.map((obj) => ({
            ...obj,
            ...newKeyValuePair,
        }));
        const payload = {
            deal_ids: newArray,
        };
        api.post(`marketkredit/api/v1/deal/change_deal_status/`, payload
        )
            .then((res) => {
                if (res.status) {
                    toast("Status Change Successfully");
                    setTimeout(() => {
                        closeDealStatus();
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleCreateNewDealSubmit = (values) => {
        let payload1 = {
            borrower: values.borrowerName,
            credit_requirement: values.creditRequirements,
            product: values.productTypes,
            lender: values.lenders,
            relationship_manager: values.rmanager,
            bo_agent: values.boAgents,
            deal_status: values.cDealStatus,
            primary_user_borrower: values.primaryUserForBorrower,
            primary_user_lender: values.primaryUserForBorrower,
            is_active: true,
        };
        api.post(`marketkredit/api/v1/deal/create_deal/`, payload1)
            .then((res) => {
                if (res.status) {
                    toast("Deal Created Successfully");
                    const id = res.data.data.id;
                    if (dealActionType === "proceed") {
                        window.location.href = `/deal-setup/${id}`;
                    } else if (dealActionType === "quickCreate") {
                        closeModal();
                    }
                }
            })
            .catch((err) => {
                // Handle the error
            });
    };

    const handleValidSubmit = (e, values) => {
        let payload = {
            borrower_name: values.borrowerName,
            pan_number: values.borrowerPanNo,
            constitution: values.constitution,
            primary_user: {
                first_name: values.bName,
                last_name: values.bName,
                phone_number: values.contactNumber,
                email_address: values.emailId,
                designation: values.designation,
                communications_to_send: mainStatus,
                is_active: true,
            },
            is_active: true,
        };

        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.post(`marketkredit/api/v1/borrower/create_borrower/`, payload)
            .then((res) => {
                if (res.status) {
                    toast.success("Borrower Created Successfully");
                    const id = res.data.data.id;
                    if (borrowerActionType === "proceed") {
                        window.location.href = `/borrower-setup/${id}`;
                    } else if (borrowerActionType === "quickCreate") {
                        window.location.reload();
                    } else {
                        closeModal();
                    }
                }
            })
            .catch((err) => {
                // Handle the error
                err.response.data.error.map((data) => {
                    toast.error(data.error)
                })

            });
    };

    const handleProced = (e, values) => {
        let payload = {
            borrower_name: values.borrowerName,
            pan_number: values.borrowerPanNo,
            constitution: values.constitution,
            primary_user: {
                first_name: values.bName,
                last_name: values.bName,
                phone_number: values.contactNumber,
                email_address: values.emailId,
                designation: values.designation,
                communications_to_send: values.all_communication[0],
                is_active: true,
            },
            is_active: true,
        };
        api.post(`marketkredit/api/v1/borrower/create_borrower/`, payload)
            .then((res) => {
                if (res.status) {
                    toast("Borrower Created Successfully");
                    setTimeout(() => {
                        closeBorrower();
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.pan_number[0]);
                // setErrow(err)
            });
    };

    const handleBorrowerChange = (id) => {
        setBorrrowerId(id);
        api.get(`marketkredit/api/v1/user/list_agent/borrower/?object_id=${id}`)
            .then(
                (res) => {
                    setPrimaryData(res.data.data);
                },
                (err) => {
                    toast.error(err.message);
                }
            );
    };

    const handleLenderChange = (id) => {
        api.get(`marketkredit/api/v1/user/list_agent/lender/?object_id=${id}`)
            .then(
                (res) => {
                    setPrimaryLenderData(res.data.data);
                },
                (err) => {
                    toast.error(err.message);
                }
            );
    };
    return (
        <>
            {/*{overlay ? <LogoutOverlay/> : ''}*/}
            <Layout>
                <main id="main" className="main">
                    <ToastContainer/>
                    <div className="pagetitle mt-3">
                        <h1>Deal Management</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">Home</li>
                                <li className="breadcrumb-item active">Deal Management</li>
                            </ol>
                        </nav>
                    </div>
                    {/*{permissions && (!permissions.can_filter_on_borrower || !permissions.can_filter_on_product || !permissions.can_filter_on_lender || !permissions.can_filter_on_start_date || !permissions.can_filter_on_end_date || permissions.can_filter_on_deal_status || permissions.can_filter_on_borrower_pan_number) ?*/}
                    {/*    '' :*/}
                    <section className="section dashboard">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="panelsStayOpen-headingTwo"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                Filter
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <form>
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        {permissions && permissions.can_filter_on_borrower && (
                                                            <div className="col-md-4 mt-4">
                                                                <label htmlFor="last">Borrower</label>
                                                                <Multiselect
                                                                    displayValue="key"
                                                                    placeholder="Select Borrower"
                                                                    className="mt-1"
                                                                    options={borrowerDetails}
                                                                    showCheckbox
                                                                    onSelect={selectBorrower}
                                                                    onRemove={onRemoveBorrower}
                                                                    onSearch={(e) => setHandleSearch(e)}
                                                                />
                                                            </div>
                                                        )}
                                                        {permissions && permissions.can_filter_on_product && (
                                                            <div className="col-md-4 mt-4">
                                                                <label htmlFor="last">
                                                                    Preferred Product Type
                                                                </label>
                                                                <Multiselect
                                                                    displayValue="key"
                                                                    placeholder="Select Preferred Product Type"
                                                                    className="mt-1"
                                                                    options={productDetails}
                                                                    showCheckbox
                                                                    onSelect={selectProduct}
                                                                    onRemove={onRemoveProduct}
                                                                />
                                                            </div>
                                                        )}
                                                        {permissions && permissions.can_filter_on_lender && (
                                                            <div className="col-md-4 mt-4">
                                                                <label htmlFor="last"> Lender</label>
                                                                <Multiselect
                                                                    displayValue="key"
                                                                    placeholder="Select Lender"
                                                                    className="mt-1"
                                                                    options={allLendersDetails}
                                                                    showCheckbox
                                                                    onSelect={selectLender}
                                                                    onRemove={onRemoveLender}
                                                                    onSearch={(e) => setHandleSearch(e)}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row">
                                                        {permissions && permissions.can_filter_on_start_date && (
                                                            <div className="col-md-4 mt-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="last">
                                                                        Deal Creation Start Date
                                                                    </label>
                                                                    <div
                                                                        className="input-group date"
                                                                        id="datetimepicker1"
                                                                    >
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            onChange={(e) => handleDate(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {permissions && permissions.can_filter_on_end_date && (
                                                            <div className="col-md-4 mt-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="last">
                                                                        Deal Creation End Date
                                                                    </label>
                                                                    <div
                                                                        className="input-group date"
                                                                        id="datetimepicker1"
                                                                    >
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            onChange={(e) => handleEndDate(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {permissions && permissions.can_filter_on_deal_status && (
                                                            <div className="col-md-4 mt-4">
                                                                <div className="form-group">
                                                                    <label product_namehtmlFor="last">
                                                                        Deal Status
                                                                    </label>
                                                                    <Multiselect
                                                                        displayValue="key"
                                                                        placeholder="Select Deal Status"
                                                                        className="mt-1"
                                                                        options={dealStatus}
                                                                        showCheckbox
                                                                        onSelect={selectStatus}
                                                                        onRemove={onRemove}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {permissions && permissions.can_filter_on_borrower_pan_number && (
                                                            <div className="col-md-4 mt-4">
                                                                <div className="form-group">
                                                                    <label htmlForfor="last">PAN No</label>
                                                                    <input
                                                                        type="text"
                                                                        id="panNO"
                                                                        name="panNo"
                                                                        className="form-control"
                                                                        placeholder="Enter PAN No"
                                                                        onChange={(e) => handlePanNo(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {permissions && permissions.can_filter_on_relationship_manager && (
                                                            <div className="col-md-4 mt-4">
                                                                <label htmlForfor="last">
                                                                    {" "}
                                                                    Relationship Manager
                                                                </label>
                                                                <Multiselect
                                                                    displayValue="key"
                                                                    placeholder="Select Relationship Manager"
                                                                    className="mt-1"
                                                                    options={rmStatusData}
                                                                    showCheckbox
                                                                    onSelect={handleRmData}
                                                                    onRemove={onRemoveRm}
                                                                />
                                                            </div>
                                                        )}
                                                        {permissions && permissions.can_filter_bo_agent && (
                                                            <div className="col-md-4 mt-4">
                                                                <label htmlForfor="last">BO Agent</label>

                                                                <Multiselect
                                                                    displayValue="key"
                                                                    placeholder="Select BO Agent"
                                                                    className="mt-1"
                                                                    options={boStatusData}
                                                                    showCheckbox
                                                                    onRemove={onRemoveAgent}
                                                                    onSelect={handleBoAgentData}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row">
                                                        {permissions && permissions.can_filter_on_borrower_cin_number && (
                                                            <div className="col-md-4 mt-4">
                                                                <div className="form-group">
                                                                    <label htmlForfor="last">CIN No</label>
                                                                    <input
                                                                        type="text"
                                                                        id="panNO"
                                                                        name="panNo"
                                                                        className="form-control"
                                                                        placeholder="Enter CIN No"
                                                                        onChange={(e) => hanldeCinNo(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="col-md-4">
                                                            <button
                                                                type="button"
                                                                className="btn mt-5 select-filter-btn d-block"
                                                                onClick={() => handleSubmitFilter()}
                                                            >
                                                                Apply Filter
                                                            </button>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <button
                                                                type="button"
                                                                className="btn mt-5 select-filter-btn d-block"
                                                                onClick={() => handleSubmitClearFilter()}
                                                            >
                                                                Clear Filter
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12 deal-mgt-btn">
                                {permissions && permissions.add_deal && (
                                    <button
                                        type="button"
                                        value="Open"
                                        className="btn mt-2"
                                        onClick={() => openModal()}
                                    >
                                        Create New Deal
                                    </button>
                                )}
                                {permissions && permissions.add_borrower && (
                                    <button
                                        type="button"
                                        value="Open"
                                        className="btn mt-2"
                                        onClick={() => openBorrower()}
                                    >
                                        Create New Borrower
                                    </button>
                                )}
                                {permissions && permissions.add_lender && (
                                    <button
                                        type="button"
                                        value="Open"
                                        className="btn mt-2"
                                        onClick={() => openLender()}
                                    >
                                        Create New Lender
                                    </button>
                                )}
                                {permissions && permissions.add_product && (
                                    <button
                                        type="button"
                                        value="Open"
                                        className="btn mt-2"
                                        onClick={() => openNewProductModal()}
                                    >
                                        Create New Product Type
                                    </button>
                                )}
                                {permissions && permissions.change_deal_status && (
                                    <button className="btn mt-2" onClick={() => openDealStatus()}>
                                        Change Deal Status
                                    </button>
                                )}
                            </div>
                        </div>
                        {permissions && permissions.view_deal &&
                            <div className="row" style={{marginTop: "40px"}}>
                                <div className="col-md-12">
                                    <Box>
                                        <DataGrid
                                            autoHeight
                                            rows={pageState && pageState.data ? pageState.data : []}
                                            rowCount={pageState.total}
                                            loading={pageState.isLoading}
                                            rowsPerPageOptions={[10, 30, 50, 70, 100]}
                                            pagination
                                            page={pageState.page - 1}
                                            pageSize={pageState.pageSize}
                                            paginationMode="server"
                                            disableSelectionOnClick
                                            checkboxSelection
                                            onSelectionModelChange={handleSelectionChange}
                                            selectionModel={selectionModel}
                                            onPageChange={(newPage) => {
                                                setPageState((old) => ({...old, page: newPage + 1}));
                                            }}
                                            onPageSizeChange={(newPageSize) =>
                                                setPageState((old) => ({
                                                    ...old,
                                                    pageSize: newPageSize,
                                                }))
                                            }
                                            columns={columns}
                                        />
                                    </Box>
                                </div>
                            </div>
                        }
                    </section>
                    {/*// }*/}

                </main>
                {permissions && permissions.add_deal && (
                    <Modal
                        visible={visible}
                        width="1200"
                        height="800"
                        // style={{height:'auto'}}
                        className="maint"
                        effect="fadeInUp"
                        onClickAway={() => closeModal()}
                    >
                        <div style={{textAlign: "right"}} className="p-3">
                            <a
                                href="javascript:void(0);"
                                onClick={() => closeModal()}
                                style={{color: "#145DA0"}}
                            >
                                Close
                            </a>
                        </div>

                        <div class="container p-3">
                            <h2 className="text-center">Create New Deal </h2>
                            <div style={{textAlign: "right"}} className="create-deal-btn">
                                <button className="btn" onClick={() => openBorrower()}>
                                    Create a New Borrower
                                </button>
                            </div>

                            <Formik
                                initialValues={initialCreateNewDealValues}
                                validationSchema={validationCreateNewDealSchema}
                                onSubmit={handleCreateNewDealSubmit}
                                className="row needs-validation"
                            >
                                {() => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-6 mt-4">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Borrower Name :{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="borrowerName"
                                                            as="select"
                                                            class="form-control"
                                                            onClick={(e) =>
                                                                handleBorrowerChange(e.target.value)
                                                            }
                                                        >
                                                            <option value="">Select Borrower Name</option>
                                                            {borrowerList &&
                                                                borrowerList.map((data) => {
                                                                    return (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.borrower_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="borrowerName"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                        {/* } */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Credit Requirements :{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            type="number"
                                                            id="creditRequirements"
                                                            name="creditRequirements"
                                                            className="form-control"
                                                            placeholder="Enter Credit Requirements"
                                                        />
                                                        <ErrorMessage
                                                            name="creditRequirements"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Preferred Product Type:{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="productTypes"
                                                            as="select"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Select Preferred Product Type
                                                            </option>
                                                            {productTypeList &&
                                                                productTypeList.map((data) => {
                                                                    return (
                                                                        <option value={data.id}>{data.name}</option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="productTypes"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Deal Status :{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="cDealStatus"
                                                            as="select"
                                                            class="form-control"
                                                        >
                                                            <option value="">Select Deal Status</option>
                                                            {mainDealStatus &&
                                                                mainDealStatus.map((data) => {
                                                                    return (
                                                                        <option value={data.status}>
                                                                            {data.status}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="cDealStatus"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Lender :{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="lenders"
                                                            as="select"
                                                            class="form-control"
                                                            onClick={(e) =>
                                                                handleLenderChange(e.target.value)
                                                            }
                                                        >
                                                            <option value="">Select Lender</option>
                                                            {lenderListData &&
                                                                lenderListData.map((data) => {
                                                                    return (
                                                                        <option value={data.id}>
                                                                            {data.lender_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="lenders"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Relationship Manager:{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="rmanager"
                                                            as="select"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Select Relationship Manager
                                                            </option>
                                                            {rmStatusData &&
                                                                rmStatusData.map((data) => {
                                                                    return (
                                                                        <option value={data.id}>
                                                                            {data.first_name + " " + data.last_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="rmanager"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        BO Agent:{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="boAgents"
                                                            as="select"
                                                            class="form-control"
                                                        >
                                                            <option value="">Select BO Agent</option>
                                                            {boStatusData &&
                                                                boStatusData.map((data) => {
                                                                    return (
                                                                        <option value={data.id}>
                                                                            {data.first_name + " " + data.last_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="boAgents"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Primay User for Borrower:{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="primaryUserForBorrower"
                                                            as="select"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Select Primary User for Borrower
                                                            </option>
                                                            {primaryData &&
                                                                primaryData.map((data) => {
                                                                    return (
                                                                        <option value={data.id}>
                                                                            {data.first_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="primaryUserForBorrower"
                                                            component="div"
                                                            className="error mt-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Primay User for Lender:{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <Field
                                                            name="primaryUserForLender"
                                                            as="select"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Select Primary User for Lender{" "}
                                                            </option>
                                                            {primaryLenderData &&
                                                                primaryLenderData.map((data) => {
                                                                    return (
                                                                        <option value={data.id}>
                                                                            {data.first_name + " " + data.last_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{textAlign: "center"}}
                                                className="mt-4 create-deal-btn"
                                            >
                                                <button
                                                    type="button"
                                                    className="me-3 btn"
                                                    onClick={() => handleDealSubmitWithAction("proceed")}
                                                >
                                                    Proceed
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={() =>
                                                        handleDealSubmitWithAction("quickCreate")
                                                    }
                                                >
                                                    Quick Create
                                                </button>
                                                <button
                                                    type="submit"
                                                    id="submitFormDealCreate"
                                                    style={{display: "none"}}
                                                ></button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal>
                )}
                {permissions && permissions.add_product && (
                    <Modal
                        visible={productVisible}
                        width="800"
                        height="320"
                        effect="fadeInUp"
                        onClickAway={() => closeNewProductModal()}
                    >
                        <div style={{textAlign: "right"}} className="p-3">
                            <a
                                href="javascript:void(0);"
                                onClick={() => closeNewProductModal()}
                                style={{color: "#145DA0"}}
                            >
                                Close
                            </a>
                        </div>
                        <div className="container p-3">
                            <h2 className="text-center">Create New Product </h2>
                            <Formik
                                initialValues={initialProductValues}
                                validationSchema={validationProductSchema}
                                onSubmit={handleProductSubmit}
                                className="row needs-validation"
                            >
                                {() => (
                                    <Form>
                                        <div className="form-group row mt-5">
                                            <label
                                                htmlFor="inputEmail"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Product Name :
                                            </label>
                                            <div className="col-sm-9">
                                                <Field
                                                    type="text"
                                                    id="newProduct"
                                                    name="newProduct"
                                                    className="form-control"
                                                    placeholder="Enter Product Name"
                                                />
                                                <ErrorMessage
                                                    name="newProduct"
                                                    component="div"
                                                    className="error mt-1"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{textAlign: "center"}}
                                            className="mt-4 create-deal-btn"
                                        >
                                            <button type="submit" className="btn">
                                                Create
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal>
                )}
                {permissions && permissions.add_lender && (
                    <Modal
                        visible={lenderVisible}
                        width="800"
                        height="320"
                        effect="fadeInUp"
                        onClickAway={() => closeLender()}
                    >
                        <div style={{textAlign: "right"}} className="p-3">
                            <a
                                href="javascript:void(0);"
                                onClick={() => closeLender()}
                                style={{color: "#145DA0"}}
                            >
                                Close
                            </a>
                        </div>

                        <div className="container p-3">
                            <h2 className="text-center">Create New Lender </h2>
                            <Formik
                                initialValues={initialLenderValues}
                                validationSchema={validationLenderSchema}
                                onSubmit={handleLenderSubmit}
                                className="row needs-validation"
                            >
                                {() => (
                                    <Form>
                                        <div className="form-group row mt-5">
                                            <label
                                                htmlFor="inputEmail"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Lender Name :
                                            </label>
                                            <div className="col-sm-9">
                                                <Field
                                                    type="text"
                                                    id="createLender"
                                                    name="createLender"
                                                    className="form-control"
                                                    placeholder="Enter Lender Name"
                                                />
                                                <ErrorMessage
                                                    name="createLender"
                                                    component="div"
                                                    className="error mt-1"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{textAlign: "center"}}
                                            className="mt-4 create-deal-btn"
                                        >
                                            <button
                                                type="button"
                                                className="me-3 btn"
                                                onClick={() => handleLenderSubmitWithAction("proceed")}
                                            >
                                                Proceed
                                            </button>
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                    handleLenderSubmitWithAction("quickCreate")
                                                }
                                            >
                                                Quick Create
                                            </button>
                                            <button
                                                type="submit"
                                                id="submitFormLenderCreate"
                                                style={{display: "none"}}
                                            ></button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal>
                )}
                {permissions && permissions.add_borrower && (
                    <Modal
                        visible={borrowerVisibile}
                        width="1000"
                        height="640"
                        effect="fadeInUp"
                        onClickAway={() => closeBorrower()}
                    >
                        <div style={{textAlign: "right"}} className="p-3">
                            <a
                                href="javascript:void(0);"
                                onClick={() => closeBorrower()}
                                style={{color: "#145DA0"}}
                            >
                                Close
                            </a>
                        </div>
                        <div className="container p-3">
                            <p>{error && error.response.data.non_field_errors[0]}</p>
                            <h2 className="text-center">Create New Borrower</h2>
                            <AvForm onValidSubmit={handleValidSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label
                                                htmlFor="staticEmail"
                                                className="col-sm-4 col-form-label"
                                            >
                                                Borrower Name :{" "}
                                            </label>
                                            <div className="col-sm-8">
                                                <AvField
                                                    type="text"
                                                    id="borrowerName"
                                                    name="borrowerName"
                                                    className="form-control"
                                                    placeholder="Enter Borrower Name"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Borrower Name Mandatory",
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z\s]*$/,
                                                            errorMessage: 'Please Enter Alphabets Only'
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label
                                                htmlFor="staticEmail"
                                                className="col-sm-4 col-form-label"
                                            >
                                                PAN Number :{" "}
                                            </label>
                                            <div className="col-sm-8">
                                                <AvField
                                                    type="text"
                                                    id="borrowerPanNo"
                                                    name="borrowerPanNo"
                                                    className="form-control"
                                                    placeholder="Enter PAN Number"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "PAN Number Mandatory",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label
                                                htmlFor="staticEmail"
                                                className="col-sm-4 col-form-label"
                                            >
                                                Name :{" "}
                                            </label>
                                            <div className="col-sm-8">
                                                <AvField
                                                    type="text"
                                                    id="bName"
                                                    name="bName"
                                                    className="form-control"
                                                    placeholder="Enter Name"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Name is Mandatory",
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z\s]*$/,
                                                            errorMessage: 'Please Enter Alphabets Only'
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label
                                                htmlFor="staticEmail"
                                                className="col-sm-4 col-form-label"
                                            >
                                                Contact Number :{" "}
                                            </label>
                                            <div className="col-sm-8">
                                                <AvField
                                                    type="number"
                                                    id="contactNumber"
                                                    name="contactNumber"
                                                    className="form-control"
                                                    placeholder="Enter Contact Number"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Contact Number Mandatory",
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            errorMessage: "Please Enter a Valid Contact Number",
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            errorMessage: "Please Enter a Valid Contact Number",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label
                                                htmlFor="staticEmail"
                                                className="col-sm-4 col-form-label"
                                            >
                                                Email ID :{" "}
                                            </label>
                                            <div className="col-sm-8">
                                                <AvField
                                                    type="text"
                                                    id="emailId"
                                                    name="emailId"
                                                    className="form-control"
                                                    placeholder="Enter Email ID"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Email ID Mandatory",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label
                                                htmlFor="staticEmail"
                                                className="col-sm-4 col-form-label"
                                            >
                                                Designation :{" "}
                                            </label>
                                            <div className="col-sm-8">
                                                <AvField
                                                    type="text"
                                                    id="designation"
                                                    name="designation"
                                                    className="form-control"
                                                    placeholder="Enter Designation"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required.",
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z]+$/,
                                                            errorMessage: `Please Enter Alphabets Only`,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            {permissions && permissions.list_borrower_constitution &&
                                                <>
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-sm-4 col-form-label"
                                                    >
                                                        Constitution :{" "}
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <AvField
                                                            type="select"
                                                            name="constitution"
                                                            id="Category"
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: "This field is required.",
                                                                },
                                                            }}
                                                            className="select-input-field"
                                                        >
                                                            {constitution &&
                                                                constitution.map((data) => {
                                                                    return (
                                                                        <option
                                                                            key={data.constitution}
                                                                            value={data.constitution}
                                                                            label={data.constitution}
                                                                        />
                                                                    );
                                                                })}
                                                        </AvField>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="staticEmail"
                                                className="col-sm-5 col-form-label"
                                            >
                                                All Communication :{" "}
                                            </label>
                                            <div className="col-sm-7">
                                                <AvRadioGroup
                                                    name="all_communication"
                                                    required
                                                    inline
                                                    value={String(mainStatus)}
                                                    onChange={handleRadioChange}
                                                >
                                                    <AvRadio label="Yes" value="true"/>
                                                    <AvRadio label="No" value="false"/>
                                                </AvRadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <FormGroup className="text-center mt-2 create-deal-btn">
                                        <div
                                            style={{textAlign: "center"}}
                                            className="mt-4 create-deal-btn"
                                        >
                                            <button
                                                type="button"
                                                className="me-3 btn"
                                                onClick={() =>
                                                    handleBorrowerSubmitWithAction("proceed")
                                                }
                                            >
                                                Create and Complete Profile
                                            </button>
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                    handleBorrowerSubmitWithAction("quickCreate")
                                                }
                                            >
                                                Create New Deal for Borrower
                                            </button>
                                            <button
                                                type="submit"
                                                id="submitFormBorrowerCreate"
                                                style={{display: "none"}}
                                            ></button>
                                        </div>
                                    </FormGroup>
                                </div>
                            </AvForm>
                        </div>
                    </Modal>
                )}
                {permissions && permissions.change_deal_status && (
                    <Modal
                        visible={dealStatusVisible}
                        width="800"
                        height="320"
                        effect="fadeInUp"
                        onClickAway={() => closeDealStatus()}
                    >
                        <div style={{textAlign: "right"}} className="p-3">
                            <a
                                href="javascript:void(0);"
                                onClick={() => closeDealStatus()}
                                style={{color: "#145DA0"}}
                            >
                                Close
                            </a>
                        </div>

                        <div className="container p-3">
                            <h2 className="text-center">Change Deal Status</h2>
                            <Formik
                                initialValues={initialDealStatuslValues}
                                validationSchema={validationDealStatusSchema}
                                onSubmit={handleDealSubmit}
                                className="row needs-validation"
                            >
                                {() => (
                                    <Form>
                                        <div className="form-group row mt-5">
                                            <label
                                                htmlFor="inputEmail"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Deal Status :
                                            </label>
                                            <div className="col-sm-9">
                                                <Field
                                                    name="dealStatus"
                                                    as="select"
                                                    class="form-control"
                                                >
                                                    {mainDealStatus &&
                                                        mainDealStatus.map((data) => {
                                                            return (
                                                                <option value={data.status}>
                                                                    {data.status}
                                                                </option>
                                                            );
                                                        })}
                                                </Field>
                                                <ErrorMessage
                                                    name="dealStatus"
                                                    component="div"
                                                    className="error mt-1"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{textAlign: "center"}}
                                            className="mt-4 create-deal-btn"
                                        >
                                            <button className="me-3 btn">Update</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal>
                )}
                {permissions && permissions.duplicate_deal && (
                    <Modal
                        visible={showDuplicate}
                        width="500"
                        height="200"
                        effect="fadeInDown"
                        onClickAway={() => closeDuplicateModal()}
                    >
                        <div className="text-center" style={{padding: "5px"}}>
                            <h3 style={{paddingTop: "15px"}}>Duplicate</h3>
                            <p>Are you sure you want to make a duplicate?</p>
                            <button
                                className="btn admin-sales-queue"
                                style={{
                                    marginTop: "15px",
                                    backgroundColor: "#4154f1",
                                    color: "white",
                                    borderRadius: "0px",
                                }}
                                onClick={() => submitDuplicate()}
                            >
                                Yes
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                className="btn admin-sales-queue"
                                style={{
                                    marginTop: "15px",
                                    backgroundColor: "#4154f1",
                                    color: "white",
                                    borderRadius: "0px",
                                }}
                                onClick={() => closeDuplicateModal()}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal>
                )}
                {permissions && permissions.deactivate_deal && (
                    <Modal
                        visible={showDeactivate}
                        width="500"
                        height="200"
                        effect="fadeInDown"
                        onClickAway={() => closeDuplicateModal()}
                    >
                        <div className="text-center" style={{padding: "5px"}}>
                            <h3 style={{paddingTop: "15px"}}>Deactivate</h3>
                            <p>Are you sure want to deactivate?</p>
                            <button
                                className="btn admin-sales-queue"
                                style={{
                                    marginTop: "15px",
                                    backgroundColor: "#4154f1",
                                    color: "white",
                                    borderRadius: "0px",
                                }}
                                onClick={() => submitDeactivate()}
                            >
                                Yes
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                className="btn admin-sales-queue"
                                style={{
                                    marginTop: "15px",
                                    backgroundColor: "#4154f1",
                                    color: "white",
                                    borderRadius: "0px",
                                }}
                                onClick={() => closeDuplicateModal()}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal>
                )}
            </Layout>
        </>
    );
};
export default DealManagement;
