import React, {useEffect, useState, useContext} from "react";
import Layout from "../../page-layouts/Layout/Layout";
import Select from 'react-select';

import Modal from "react-awesome-modal";
import "../../global.css";
import {AvField, AvForm,} from "availity-reactstrap-validation";
import * as Yup from "yup";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {APP_CONFIGURATION} from "../../../app-config/configuration";
import {PermissionsContext} from "../../../context";
import api from '../../../app-config/axiosConfig'
import axios from "axios";

import {Button, FormGroup} from "reactstrap";

const BorrowerSetup = (props) => {

    const [editDealData, setEditMainData] = useState()
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [dealStatus, setDealStatus] = useState()
    const [productDetails, setProductDetails] = useState();
    const [userData, setUserData] = useState()
    const [lenderListData, setLenderListData] = useState()
    const [dealStatusData, setMainDealStatus] = useState()
    const [showDocumentData, setShowDocumentData] = useState(false)
    const [urlId, setUrlId] = useState()
    const [documentUploadId, setDocumentUploadId] = useState("")
    const [showUploadFile, setShowUploadFile] = useState(false)
    const [showUploadDocument, setShowUploadDocument] = useState(false)
    const [rmStatusData, setRmStatus] = useState();
    const [documentList, setDocumentList] = useState()
    const [boStatusData, setBoStatus] = useState()
    const [borrowerDetails, setBorrowerDetails] = useState();
    const [editData, setEditData] = useState();
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showUploadTemplate, setShowUploadTemplate] = useState(false)
    const [mainFile, setFileData] = useState();
    const [file, setFile] = useState(null);
    const [mainListData, setMainListData] = useState()
    const [dealMainStatus, setDealMainStatus] = useState()
    const [options, setOptions] = useState([]);
    const [uploadTemplateId, setUploadTemplateId] = useState("")
    const [documentTypeId, setDocumentType] = useState('')
    const [removeId, setRemoveId] = useState('')
    const [documentTemplateType, setUploadTemplateType] = useState()
    const {permissions} = useContext(PermissionsContext);
    const borrowerIdData = localStorage.getItem("user_id");
    const userRole = localStorage.getItem('user_type')
    const [visible, setVisible] = useState(false)
    const [disable, setDisable] = useState(true)
    const [visibleDocument, setVisibleDocument] = useState(false)
    const [disableAdmin, setDisableAdmin] = useState(true)
    const [disableBorrower, setDisableBorrower] = useState(true)
    const [disableLender, setDisableLender] = useState(true)
    const [accordionOpen, setAccordionOpen] = useState(visibleDocument);
    const [selectAll, setSelectAll] = useState(false);
    console.log('fffffffff ',mainFile)
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSelectAllChange = (e) => {
        const {checked} = e.target;
        setSelectAll(checked);

        const allCheckboxIds = Object.values(groupedData).flatMap(category =>
            Object.values(category).flatMap(items =>
                items.map(item => `${item.id}|${item.document_type}`)
            )
        );

        setSelectedCheckboxes(checked ? allCheckboxIds : []);
    };


    useEffect(() => {
        if (permissions && (permissions.change_deal === true && permissions.view_deal_document === true)) {
            setVisible(true)
            setDisable(false)
        } else if (permissions && (permissions.change_deal === true && permissions.view_deal_document === false)) {
            setVisible(true)
            setDisable(false)
        } else if (permissions && (permissions.change_deal === false && permissions.view_deal_document === true)) {
            setVisible(true)
            setDisable(true)
        }
    }, [permissions]);

    useEffect(() => {
        if (permissions && (userRole === 'admin' && permissions.change_deal === true && permissions.view_deal_document === true)) {
            setVisibleDocument(true)
            setDisableAdmin(true)
            setDisableBorrower(false)
            setDisableLender(false)
        } else if (permissions && (userRole === 'admin' && permissions.change_deal === true && permissions.view_deal_document === false)) {
            setVisibleDocument(false)
            setDisableAdmin(true)
            setDisableBorrower(false)
            setDisableLender(false)
        } else if (permissions && (userRole === 'admin' && permissions.change_deal === false && permissions.view_deal_document === false)) {
            setVisibleDocument(false)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(false)
        } else if (permissions && (userRole === 'admin' && permissions.change_deal === false && permissions.view_deal_document === true)) {
            setVisibleDocument(true)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(false)
        } else if (permissions && (userRole === 'borrower' && permissions.change_deal === true && permissions.view_deal_document === true)) {
            setVisibleDocument(true)
            setDisableAdmin(false)
            setDisableBorrower(true)
            setDisableLender(false)
        } else if (permissions && (userRole === 'borrower' && permissions.change_deal === true && permissions.view_deal_document === false)) {
            setVisibleDocument(false)
            setDisableAdmin(false)
            setDisableBorrower(true)
            setDisableLender(false)
        } else if (permissions && (userRole === 'borrower' && permissions.change_deal === false && permissions.view_deal_document === false)) {
            setVisibleDocument(false)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(false)
        } else if (permissions && (userRole === 'borrower' && permissions.change_deal === false && permissions.view_deal_document === true)) {
            setVisibleDocument(true)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(false)
        } else if (permissions && (userRole === 'lender' && permissions.change_deal === true && permissions.view_deal_document === true)) {
            setVisibleDocument(true)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(true)
        } else if (permissions && (userRole === 'lender' && permissions.change_deal === true && permissions.view_deal_document === false)) {
            setVisibleDocument(false)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(true)
        } else if (permissions && (userRole === 'lender' && permissions.change_deal === false && permissions.view_deal_document === false)) {
            setVisibleDocument(false)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(false)
        } else if (permissions && (userRole === 'lender' && permissions.change_deal === false && permissions.view_deal_document === true)) {
            setVisibleDocument(true)
            setDisableAdmin(false)
            setDisableBorrower(false)
            setDisableLender(false)
        }
    }, [permissions]);


    const handleCheckboxChange = (event) => {
        // const checkboxId = event.target.id;
        // setRemoveId(event.target.id)


        // // If checkbox is checked, add to the array; otherwise, remove it
        // setSelectedCheckboxes((prevSelected) => {
        //     if (event.target.checked) {
        //         return [...prevSelected, checkboxId];
        //     } else {
        //         return prevSelected.filter((id) => id !== checkboxId);
        //     }
        // });
        const checkboxId = event.target.id;
        setRemoveId(event.target.id)
        const checkedData = ((prevSelected) => {
            if (event.target.checked) {
                return [...prevSelected, checkboxId];
            } else {
                return prevSelected.filter((id) => id !== checkboxId);
            }
        })

        setSelectedCheckboxes(checkedData)
    };

    // const loadOptions = (inputValue) => {
    //     setDocumentInput(inputValue);

    //     try {
    //         let response;

    //         if (inputValue.trim() === '') {
    //             // If input is empty, fetch all documents
    //             response = axios.get('https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/', {
    //                 headers: {
    //                     'Accept': '*/*',
    //                     'Content-Type': 'application/json',
    //                     'Authorization': "Bearer " + localStorage.getItem('access')
    //                 }
    //             });
    //         } else {
    //             // If input is not empty, fetch filtered documents
    //             response = axios.get(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/?search=${inputValue}`, {
    //                 headers: {
    //                     'Accept': '*/*',
    //                     'Content-Type': 'application/json',
    //                     'Authorization': "Bearer " + localStorage.getItem('access')
    //                 }
    //             });
    //         }

    //         // Modify the data to match react-select's expected format
    //         const modifiedData = response.data.map((document) => ({
    //             value: document.id, label: document.name,
    //         }));

    //         return modifiedData;
    //     } catch (error) {
    //         console.error('Error fetching documents:', error);
    //         // Handle error as needed
    //     }
    // };


    // const fetchDocuments = async (searchQuery = "") => {
    //     const response = await api.get('https://mkdev.kreditserve.com/marketkredit/api/v1/document/master_list_document/', {
    //         params: {
    //             search: searchQuery,
    //             master_page_number: 1,
    //             master_page_size: 20,
    //             page_name: 'Deal'
    //         },
    //         headers: {
    //             Authorization: "Bearer " + localStorage.getItem('access'),
    //             'Content-Type': 'application/json'
    //         }
    //     });
    //     const documents = response.data.data.map((doc) => ({
    //         value: `${doc.id}|${doc.document_type}`,
    //         label: doc.name,
    //     }));
    //     const userInputOption = searchQuery.trim()
    //         ? [{value: '', label: `${searchQuery}`}]
    //         : [];
    //
    //
    //     // Combine the user input option with the API response
    //     setOptions([...documents, ...userInputOption]);
    // };
    const fetchDocuments = async (searchQuery = "") => {
    try {
        const response = await api.get('https://mkdev.kreditserve.com/marketkredit/api/v1/document/master_list_document/', {
            params: {
                search: searchQuery,
                master_page_number: 1,
                master_page_size: 20,
                page_name: 'Deal'
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            const documents = response.data.data.map((doc) => ({
                value: `${doc.id}|${doc.document_type}`,
                label: doc.name,
            }));
            const userInputOption = searchQuery.trim()
                ? [{value: '', label: `${searchQuery}`}]
                : [];

            // Combine the user input option with the API response
            setOptions([...documents, ...userInputOption]);
        } else {
            console.error("Error fetching documents:", response.statusText);
            // Handle error appropriately, e.g., show an error message to the user
        }
    } catch (error) {
        console.error("Error fetching documents:", error.message);
        // Handle error appropriately, e.g., show an error message to the user
    }
};


    const handleInputChange = (newValue) => {
        fetchDocuments(newValue);
    };

    const [documentInput, setDocumentInput] = useState('');

    // Function to handle document input change and make API call
    const handleDocumentInputChange = async (inputValue) => {
        setDocumentInput(inputValue);

        try {
            if (inputValue.trim() === '') {
                // If input is empty, fetch all documents
                const response = await api.get('https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/?page_name=Deal', {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem('access')
                    }
                });
                const modifiedData = [...response.data.data, // Add your custom object here
                    {id: '', name: inputValue, category: {id: 'categoryId', name: 'categoryName'}}];

                const mappedData = response.data.map((document) => ({
                    value: document.id, label: document.name,
                }));

                console.log('API Response:', mappedData, Array.isArray(mappedData), inputValue);
                setDocumentList(Array.isArray(mappedData) ? mappedData : []);
            } else {
                // If input is not empty, fetch filtered documents
                const response = await api.get(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/?search=${inputValue}`, {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem('access')
                    }
                });
                const modifiedData = [...response.data.data, // Add your custom object here
                    {id: '', name: inputValue, category: {id: 'categoryId', name: 'categoryName'}}];

                const mappedData = response.data.map((document) => ({
                    value: document.id, label: document.name,
                }));

                console.log('API Response:', mappedData, Array.isArray(mappedData), inputValue);
                setDocumentList(Array.isArray(mappedData) ? mappedData : []);
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
            // Handle error as needed
        }
    };

    const handleDocumentSelect = (selectedOption) => {
        setSelectedDocument(selectedOption);
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    useEffect(() => {
        api
            .get("https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/?page_name=Deal", {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                let dealStatusData = res.data.data;
                dealStatusData = dealStatusData.map((data) => {
                    return {...data, key: `${data.name}`, cat: `${data.name}`};
                });
                setMainListData(dealStatusData)
            }, (err) => {
                // alert('fail')
            });
    }, [])


    useEffect(() => {
        const {location} = props;
        const url = location.pathname;
        const documentId = url.substring(url.lastIndexOf("/") + 1);
        setUrlId(documentId)
        const validate = documentId && url.split("/").length === 3;
        if (documentId && url.split("/").length === 3) {
            api.get(`https://mkdev.kreditserve.com/marketkredit/api/v1/deal/${documentId}/`, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem('access')
                }
            }).then((res) => {
                setEditMainData(res.data.data);
                const doc = res && res.data.data.deals_documents.map((item) => {
                    return {
                        id: item.id,
                        borrower_comments: item.borrower_comments,
                        kreditserve_comments: item.kreditserve_comments,
                        lender_comments: item.lender_comments,
                        document: item.document.file,
                        title: item.document.title,
                        category: item.document.type.category,
                        categoryId: item.document.type.category,
                        template: item.document.template,
                        document_type: item.document.type.document_type,
                        status: item.status

                    };
                });
                setData(doc)

                // alert('success')
            }, (err) => {
                // alert('fail')
            });
        }
    }, [])
    const [data, setData] = useState([]);
    const handleEdit = (id, field, value) => {
        const newData = data.map((item) => item.id === id ? {...item, [field]: value} : item);
        setData(newData);
    };


    useEffect(() => {
        getAllUserDataType()
        getAllLenderData()
        getAllDealStatus()
    }, [])

    const getAllLenderData = () => {
        api
            .get("https://mkdev.kreditserve.com/marketkredit/api/v1/lender/", {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {

                setLenderListData(res.data.data);
            }, (err) => {
                // alert('fail')
            });
    };

    const getAllUserDataType = () => {
        api
            .get("https://mkdev.kreditserve.com/marketkredit/api/v1/user/", {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                setUserData(res.data.data);
            }, (err) => {
                // alert('fail')
            });
    };

    const handleValidSubmit = (e, values) => {
        const payloadData = {
            borrower: values.borrowerName,
            credit_requirement: values.creditRequirements,
            product: values.productTypes,
            lender: values.lenders,
            relationship_manager: values.rmanager,
            bo_agent: values.boAgents,
            deal_status: values.cDealStatus,
            documents: data,
            "is_active": true
        }
        console.log('zzz ', payloadData)
        api
            .patch(`https://mkdev.kreditserve.com/marketkredit/api/v1/deal/${urlId}/`, payloadData, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Deal Edit Successfully");
                    setTimeout(() => {
                        window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }
    const handleDocument = () => {
        setShowDocumentData(true)
    }
    const closeDocument = () => {
        setShowDocumentData(false)
    }
    const initialDocumentValues = {
        createDocument: "",
    };
    const validationDocumentSchema = Yup.object().shape({
        createDocument: Yup.string().required("Document is required"),
    });


    const handledocumentSubmit = (values) => {
        const data = selectedDocument && selectedDocument.value.split('|')
        let value = data[0]; // '8cf16178-2d38-4368-ada5-7cb13b548710'
        let docType = data[1];
        console.log('docType ', docType)
        const payload = {
            entity_id: urlId,
            entity_type: 'Deal',
            document: {
                id: value,
                name: selectedDocument.label,
                document_type: docType === undefined ? 'Deal' : docType
            }
        }
        api
            .post("https://mkdev.kreditserve.com/marketkredit/api/v1/document/create_document/", payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Document Created Successfully");
                    setTimeout(() => {
                        closeDocument();
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                // toast.error(err.response.data.detail);
            });

    }
    const handleUploadDocument = (id) => {
        setDocumentUploadId(id)
        setShowUploadFile(true)
    }

    const closeUploadFile = () => {
        setShowUploadFile(false)
    }
    const initialUploadValues = {
        file: null
    }
    const validationSchema = Yup.object().shape({
        file: Yup.mixed().required('File is required'),
    });
    console.log('mai ', mainFile)
    const handleDocumentSubmit = (values) => {

        // Handle file upload logic here
        console.log('Form submitted with values:', file);
        //  const payload = {
        //   file: file,
        //   document_type: "Borrower"
        //  }
        const formData = new FormData();
        formData.append('file', file && file);
        formData.append("document_type", "Deal")

        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_template/${documentUploadId}/`, formData, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Upload Document Successfully");
                    setTimeout(() => {
                        closeDocument();
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                // toast.error(err.response.data.detail);
            });
    };

    const handleUnShare = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });

        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/unshare_borrower/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Borrower UnShare Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    useEffect(() => {
        getAllBoAgent()
        getAllRmStatus()
        getAllDealStatus()
    }, [])

    const getAllBoAgent = () => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/user/list_agent/bo_agent/`, {headers: headersParam}
        ).then(
            (res) => {
                let boStatus = res.data.data;
                console.log('dog ', boStatus)
                boStatus = boStatus.map((data) => {
                    return {...data, key: `${data.first_name + " " + data.last_name}`, cat: `${data.id}`};
                });
                setBoStatus(boStatus);
            },
            (err) => {
                // alert('fail')
            }
        );
    };

    const handleShare = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });

        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/share_borrower/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Borrower Share Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleShareWithLender = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });

        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/share_lender/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Lender Share Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleUnShareWithLender = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });

        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/unshare_lender/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Lender UnShare Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleMarkAsVerified = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });

        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/mark_verified/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Verified Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }
    console.log("checkbox", data);
    const handleId = (id) => {
        console.log('d d ', id)
    }
    const handleSame = (e) => {
        console.log('tool ', e)
    }
    const [selectedItems, setSelectedItems] = useState([]);
    const handleSelect = (selectedList) => {
        setSelectedItems(selectedList);
    };

    const handleRemove = (selectedList) => {
        setSelectedItems(selectedList);
    };

    const [selectedOption, setSelectedOption] = useState(null);

    const handleSubmit = () => {
        // Handle the submission with the selected option
        // Perform any additional actions, e.g., closing the modal or making another API call.
    };
    const groupedData = data.reduce((acc, item) => {
        const {category, categoryId} = item;
        if (!acc[category]) {
            acc[category] = {};
        }
        if (!acc[category][categoryId]) {
            acc[category][categoryId] = [];
        }
        acc[category][categoryId].push(item);
        return acc;
    }, {});

    const handleUploadData = (id, documentType) => {
        setDocumentUploadId(id)
        setDocumentType(documentType)
        setShowUploadDocument(true)
    }
    const handleDelte = (id, doctype) => {
        const payload = {
            "document_type": doctype
        }
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.post(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/remove_document/${id}/`, payload, {headers: headersParam}).then((res) => {
            if (res.status) {
                toast("Document Deleted Successfully");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    const handleUploadTemplate = (id, document_type) => {
        setShowUploadTemplate(true)
        setUploadTemplateType(document_type)
        setUploadTemplateId(id)
    };

    useEffect(() => {
        getAllborrowerData()
        getAllProductTypeData()
    }, [])


    const getAllborrowerData = () => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/borrower/`, {headers: headersParam}
        ).then(
            (res) => {
                // setBorrowerList(res.data.data);
                // let borrowerDropdownData = res.data.data;
                // borrowerDropdownData = borrowerDropdownData.map((data) => {
                //     return { ...data, key: `${data.borrower_name}`, cat: `${data.borrower_name}` };
                // });
                setBorrowerDetails(res.data.data)
            },
            (err) => {
                // alert('fail')
            }
        );
    };
    const getAllProductTypeData = () => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/product/`, {headers: headersParam}
        ).then(
            (res) => {

                setProductDetails(res.data.data)
            },
            (err) => {
                // alert('fail')
            }
        );
    };
    const getAllRmStatus = () => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/user/list_agent/relationship_manager/`, {headers: headersParam}
        ).then(
            (res) => {

                let rmStatus = res.data.data;
                console.log('d ', rmStatus)
                rmStatus = rmStatus.map((data) => {
                    return {...data, key: `${data.first_name + " " + data.last_name}`, cat: `${data.id}`};
                });
                setRmStatus(rmStatus);
            },
            (err) => {
                // alert('fail')
            }
        );
    };
    const closeUploadData = () => {
        setShowUploadDocument(false)
    }
    const closeUploadTemplate = () => {
        setShowUploadTemplate(false)
    }
    const handleFileUploadTemplate = () => {
        const formData = new FormData();
        formData.append('file', mainFile);
        formData.append("document_type", documentTemplateType);
        axios
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_template/${uploadTemplateId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data", Authorization: "Bearer " + localStorage.getItem("access"),
                    Accept: "*/*",
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Upload Template Successfully");
                    setTimeout(() => {
                        closeUploadTemplate()
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    }
    const handleFileUpload = () => {
        const formData = new FormData();
        formData.append('file', mainFile);
        formData.append("document_type", documentTypeId);
        axios
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_document/${documentUploadId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data", Authorization: "Bearer " + localStorage.getItem("access"),
                    Accept: "*/*",
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Upload Document Successfully");
                    setTimeout(() => {
                        closeUploadData()
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    };
    const handleTemplate = (id, doctype) => {
        const payload = {
            document_type: doctype,
        };
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .post(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/remove_template/${id}/`,
                payload,
                {headers: headersParam}
            )
            .then((res) => {
                if (res.status) {
                    toast("Document Deleted Successfully");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const getAllDealStatus = () => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/deal/list_deal_status/`, {headers: headersParam}
        ).then(
            (res) => {
                setMainDealStatus(res.data.status_types);
                setDealMainStatus(res.data.status_types);
                let dealStatusData = res.data.status_types;
                dealStatusData = dealStatusData.map((data) => {
                    return {...data, key: `${data.status}`, cat: `${data.status}`};
                });
                setDealStatus(dealStatusData);
            },
            (err) => {
                // alert('fail')
            }
        );
    };
    console.log('selected ', selectedCheckboxes)
    const handleDownloadData = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });
        api({
            url: "https://mkdev.kreditserve.com/marketkredit/api/v1/document/download_documents_bulk/",
            method: "POST",
            responseType: "blob",
            data: {documents: arrayOfObjects},
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
            },
        })
            .then((response) => {
                const borrowerName =
                    editData && editData.borrower_name
                        ? editData.borrower_name
                        : "Borrower";
                const timestamp = new Date()
                    .toISOString()
                    .replace(/[\-\:\.T]/g, "")
                    .slice(0, 14); // Format: yyyyMMddHHmmss

                let filename = `${borrowerName.replace(/\s+/g, "_")}-${timestamp}.zip`; // Constructed filename

                console.log("Response Headers:", response.headers); // Log the headers for diagnostic purposes

                const contentDisposition = response.headers["content-disposition"];
                if (contentDisposition) {
                    const filenameMatch = /filename="([^"]+)"/.exec(contentDisposition);
                    if (filenameMatch && filenameMatch[1]) {
                        filename = filenameMatch[1];
                        console.log("Extracted filename:", filename); // Log the extracted filename
                    } else {
                        console.log(
                            "Filename extraction failed. Content-Disposition:",
                            contentDisposition
                        );
                    }
                } else {
                    console.log("No Content-Disposition header found.");
                }

                // Create a Blob from the response
                const file = new Blob([response.data], {type: "application/zip"});
                // Create an anchor element and set the URL
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.setAttribute("download", filename);
                // Append to the DOM and trigger click
                document.body.appendChild(link);
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
                URL.revokeObjectURL(fileURL);
            })
            .catch((error) => {
                console.error("Download error:", error);
            });
    };
    const handleRemoveDocument = () => {
        const resultArray = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });

        const payload = {
            documents: resultArray
        }

        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/remove_document/deactivate/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Delete Document Successfully");
                    setTimeout(() => {
                        // closeUploadTemplate()
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    }
    return (<Layout>
        <main id="main" className="main">
            <ToastContainer/>
            <div className="pagetitle mt-3">
                <h1>Deal Documents</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            Home
                        </li>
                        <li className="breadcrumb-item active"> Deal Documents</li>
                    </ol>
                </nav>
            </div>
            <section className="section dashboard">
                <div className="container-fluid mt-5">
                    <AvForm onValidSubmit={handleValidSubmit}>
                        <div className="row">
                            <div className="col-md-12 mt-3">
                                {/* First */}
                                {visibleDocument ?
                                    <div
                                        className="accordion mt-4"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingFour"
                                            >
                                                {/*<button*/}
                                                {/*    className="accordion-button collapsed"*/}
                                                {/*    type="button"*/}
                                                {/*    data-bs-toggle="collapse"*/}
                                                {/*    data-bs-target="#panelsStayOpen-collapseFour"*/}
                                                {/*    aria-expanded="false"*/}
                                                {/*    aria-controls="panelsStayOpen-collapseFour"*/}
                                                {/*>*/}
                                                <button
                                                    className={`accordion-button ${accordionOpen ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => setAccordionOpen(!accordionOpen)}
                                                    aria-expanded={accordionOpen ? 'true' : 'false'}
                                                    aria-controls="panelsStayOpen-collapseFour"
                                                >
                                                    <p className="fw-bold m-0">Deal Summary</p>
                                                </button>
                                            </h2>
                                            <div
                                                // id="panelsStayOpen-collapseFour"
                                                // className="accordion-collapse collapse"
                                                // aria-labelledby="panelsStayOpen-headingFour"
                                                id="panelsStayOpen-collapseFour"
                                                className={`accordion-collapse collapse ${accordionOpen ? 'show' : ''}`}
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    Borrower Name :{" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="select"
                                                                        name="borrowerName"
                                                                        id="borrowerName"
                                                                        disabled={visible}
                                                                        // disabled={(userRole === 'borrower' || userRole === 'lender') ? true: false}
                                                                        value={(editDealData && editDealData.borrower.id) || ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required.",
                                                                            },
                                                                        }}
                                                                        className="select-input-field"
                                                                    >
                                                                        <option
                                                                            value={editDealData ? editDealData.borrower.id : ""}>
                                                                            {(editDealData && editDealData.borrower.borrower_name) || "Select Borrower Name"}
                                                                        </option>

                                                                        {borrowerDetails && borrowerDetails.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.borrower_name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    Credit Requirement :{" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="number"
                                                                        name="creditRequirements"
                                                                        required
                                                                        disabled={visible}
                                                                        placeholder="Enter Credit Requirement"
                                                                        value={(editDealData && editDealData.credit_requirement) || "0"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    Preferred Product Type:{" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="select"
                                                                        name="productTypes"
                                                                        id="productTypes"
                                                                        disabled={visible}
                                                                        value={editDealData ? editDealData.product.id : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required.",
                                                                            },
                                                                        }}
                                                                        className="select-input-field"
                                                                    >
                                                                        {productDetails && productDetails.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    Deal Status :{" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="select"
                                                                        name="cDealStatus"
                                                                        id="cDealStatus"
                                                                        disabled={visible}
                                                                        value={editDealData ? editDealData.deal_status : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required.",
                                                                            },
                                                                        }}
                                                                        className="select-input-field"
                                                                    >
                                                                        {dealMainStatus && dealMainStatus.map((data) => {
                                                                            return (<option
                                                                                key={data.status}
                                                                                value={data.status}
                                                                                label={data.status}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    Lender :{" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="select"
                                                                        name="lenders"
                                                                        id="lenders"
                                                                        disabled={visible}
                                                                        value={editDealData ? editDealData.lender.id : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required.",
                                                                            },
                                                                        }}
                                                                        className="select-input-field"
                                                                    >
                                                                        {lenderListData && lenderListData.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.lender_name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    Relationship Manager: {" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="select"
                                                                        name="rmanager"
                                                                        id="rmanager"
                                                                        disabled={visible}
                                                                        value={editDealData && editDealData.relationship_manager ? editDealData.relationship_manager.id : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required.",
                                                                            },
                                                                        }}
                                                                        className="select-input-field"
                                                                    >
                                                                        {rmStatusData && rmStatusData.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.first_name + ' ' + data.last_name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    BO Agent: {" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="select"
                                                                        name="boAgents"
                                                                        id="boAgents"
                                                                        disabled={visible}
                                                                        value={editDealData && editDealData.bo_agent ? editDealData.bo_agent.id : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required.",
                                                                            },
                                                                        }}
                                                                        className="select-input-field"
                                                                    >
                                                                        {boStatusData && boStatusData.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.first_name + " " + data.last_name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-4 col-form-label"
                                                                >
                                                                    Primary User for Borrower:{" "}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <AvField
                                                                        type="select"
                                                                        name="boAgents"
                                                                        id="boAgents"
                                                                        disabled={visible}
                                                                        value={editDealData && editDealData.bo_agent ? editDealData.bo_agent.id : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required.",
                                                                            },
                                                                        }}
                                                                        className="select-input-field"
                                                                    >
                                                                        {boStatusData && boStatusData.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.first_name + " " + data.last_name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}
                                {/* Four */}
                                {visibleDocument ?
                                    <div
                                        className="accordion mt-4"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingFive"
                                            >
                                                <button
                                                    className={`accordion-button ${accordionOpen ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => setAccordionOpen(!accordionOpen)}
                                                    aria-expanded={accordionOpen ? 'true' : 'false'}
                                                    aria-controls="panelsStayOpen-collapseFour"
                                                >
                                                    <p className="fw-bold m-0">Documents</p>
                                                </button>
                                            </h2>
                                            <div
                                                // id="panelsStayOpen-collapseFive"
                                                // className="accordion-collapse collapse"
                                                // aria-labelledby="panelsStayOpen-headingFive"
                                                id="panelsStayOpen-collapseFour"
                                                className={`accordion-collapse collapse ${accordionOpen ? 'show' : ''}`}
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-12 create-deal-btn">
                                                            {permissions && permissions.add_dealdocument &&
                                                                <button
                                                                    className="mr-3 btn"
                                                                    type="button"
                                                                    style={{marginRight: "15px"}}
                                                                    onClick={() => handleDocument()}
                                                                >
                                                                    <i className="fa fa-plus mr-2"></i>

                                                                    Document
                                                                </button>
                                                            }
                                                            {permissions && permissions.download_bulk_deal_document &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    style={{marginRight: "15px"}}
                                                                    disabled={selectedCheckboxes.length > 0 ? false : true}
                                                                    onClick={() => handleDownloadData()}
                                                                >
                                                                    Download Selected Documents
                                                                </button>
                                                            }
                                                            {/* <button
                                                            type="button"
                                                            className="btn"
                                                            style={{marginRight: "15px"}}
                                                            // onClick={() => openModalRecurrence()}
                                                            disabled={selectedCheckboxes.length > 0 ? false : true}
                                                        >
                                                            Edit Recurrence
                                                        </button> */}
                                                            {permissions && permissions.delete_dealdocument &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    style={{marginRight: "15px"}}
                                                                    onClick={() => handleRemoveDocument()}
                                                                    disabled={selectedCheckboxes.length > 0 ? false : true}
                                                                >
                                                                    Remove
                                                                </button>
                                                            }
                                                            {permissions && permissions.share_lender_document &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    style={{marginRight: "15px"}}
                                                                    onClick={() => handleShareWithLender()}
                                                                    disabled={selectedCheckboxes.length > 0 ? false : true}
                                                                >
                                                                    Visible to Lender
                                                                </button>
                                                            }
                                                            {permissions && permissions.unshare_lender_document &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    style={{marginRight: "15px"}}
                                                                    onClick={() => handleUnShareWithLender()}
                                                                    disabled={selectedCheckboxes.length > 0 ? false : true}
                                                                >
                                                                    Hide from Lender
                                                                </button>
                                                            }
                                                            {permissions && permissions.share_borrower_document &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    style={{marginRight: "15px"}}
                                                                    onClick={() => handleShare()}
                                                                    disabled={selectedCheckboxes.length > 0 ? false : true}
                                                                >
                                                                    Visible to Borrower
                                                                </button>
                                                            }
                                                            {permissions && permissions.unshare_borrower_document &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    style={{marginRight: "15px"}}
                                                                    onClick={() => handleUnShare()}
                                                                    disabled={selectedCheckboxes.length > 0 ? false : true}
                                                                >
                                                                    Hide from Borrower
                                                                </button>
                                                            }
                                                            {permissions && permissions.mark_verified_deal_document &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    style={{marginRight: "15px"}}
                                                                    onClick={() => handleMarkAsVerified()}
                                                                    disabled={selectedCheckboxes.length > 0 ? false : true}
                                                                >
                                                                    Mark As Verified
                                                                </button>
                                                            }
                                                        </div>
                                                        <div className='mt-3 mr-2'>
                                                            <input
                                                                type="checkbox"
                                                                id="selectAllCheckbox"
                                                                checked={selectAll}
                                                                onChange={handleSelectAllChange}
                                                            /> <span style={{marginLeft:'10px'}}>Select All</span>
                                                        </div>
                                                        {Object.keys(groupedData && groupedData).map((category, index) => {
                                                            return (<div key={category}>
                                                                    <div className="row">
                                                                        <div className="col-md-12 mt-5">
                                                                            <div className="mb-3 row">
                                                                                <label
                                                                                    htmlFor="staticEmail"
                                                                                    className="col-sm-1 col-form-label"
                                                                                >
                                                                                    Category :{" "}
                                                                                </label>
                                                                                <div className="col-sm-11">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="staticEmail"
                                                                                        placeholder={category}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {Object.keys(groupedData[category]).map((categoryId,index) => {
                                                                        return (<div>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <table
                                                                                        className="table mt-5 main-deal-table">
                                                                                        <thead className="mb-3">
                                                                                        <tr className="text-center">
                                                                                            {index === 0 && (
                                                                                                <th
                                                                                                    scope="col"
                                                                                                    className="data-check-input"
                                                                                                >


                                                                                                </th>
                                                                                            )}
                                                                                            <th scope="col">Document
                                                                                                Name
                                                                                            </th>
                                                                                            <th scope="col">Status</th>
                                                                                            <th scope="col">Borrower
                                                                                                Comments
                                                                                            </th>
                                                                                            <th scope="col">Lender
                                                                                                Comments
                                                                                            </th>
                                                                                            <th scope="col">Kreditserve
                                                                                                Comments
                                                                                            </th>
                                                                                            <th scope="col">Actions</th>
                                                                                        </tr>
                                                                                        </thead>

                                                                                        <tbody>
                                                                                        {groupedData[category][categoryId].map((item) => {
                                                                                            return (<tr
                                                                                                key={item.id}
                                                                                            >
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id={`${item.id}|${item.document_type}`}
                                                                                                        checked={selectedCheckboxes.includes(`${item.id}|${item.document_type}`)}
                                                                                                        onChange={handleCheckboxChange}
                                                                                                    />

                                                                                                </td>
                                                                                                <td>
                                                                                                    {item.title}
                                                                                                </td>
                                                                                                <td>{item.status}</td>
                                                                                                <td>
                                                                                                <textarea
                                                                                                    type="text"
                                                                                                    id="borrowerComment"
                                                                                                    name="borrowerComment"
                                                                                                    className="form-control"
                                                                                                    disabled={disableBorrower}
                                                                                                    placeholder="Enter Borrower Comment"
                                                                                                    value={item ? item.borrower_comments : ""}
                                                                                                    onChange={(e) => handleEdit(item.id, "borrower_comments", e.target.value)}
                                                                                                />
                                                                                                </td>
                                                                                                <td>
                                                                                                <textarea
                                                                                                    type="text"
                                                                                                    id="lenderComment"
                                                                                                    name="lenderComment"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Lender Comment"
                                                                                                    disabled={disableLender}
                                                                                                    value={item ? item.lender_comments : ""}
                                                                                                    onChange={(e) => handleEdit(item.id, "lender_comments", e.target.value)}
                                                                                                />
                                                                                                </td>
                                                                                                <td>
                                                                                                <textarea
                                                                                                    type="text"
                                                                                                    disabled={disableAdmin}
                                                                                                    id="kreditserveComment"
                                                                                                    name="kreditserveComment"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter KreditServe Comment"
                                                                                                    value={item ? item.kreditserve_comments : ""}
                                                                                                    onChange={(e) => handleEdit(item.id, "kreditserve_comments", e.target.value)}
                                                                                                />
                                                                                                </td>

                                                                                                <td className="create-deal-btn">
                                                                                                    {item.document && (permissions && permissions.view_deal_document) ?
                                                                                                        <a
                                                                                                            type="button"
                                                                                                            href={item.document}
                                                                                                            target="_blank"
                                                                                                            title="View Document"
                                                                                                            className="btn"
                                                                                                            style={{marginRight: "10px"}}><i
                                                                                                            className="fa fa-eye"></i>
                                                                                                        </a> :
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn"
                                                                                                            style={{marginRight: "10px"}}
                                                                                                            title="Upload Document"
                                                                                                            onClick={() => handleUploadData(item.id, item.document_type)}

                                                                                                        >
                                                                                                            <i className="fa fa-upload"></i>

                                                                                                        </button>

                                                                                                    }

                                                                                                    {item.document &&
                                                                                                        <i className="fa fa-trash"
                                                                                                           style={{marginRight: "10px"}}
                                                                                                           onClick={() => handleDelte(item.id, item.document_type)}></i>}


                                                                                                    {item.template && permissions && permissions.upload_template_deal_document !== undefined ?
                                                                                                        <a
                                                                                                            type="button"
                                                                                                            href={item.template}
                                                                                                            target="_blank"
                                                                                                            title="View Document"
                                                                                                            className="btn"
                                                                                                            style={{marginRight: "10px"}}><i
                                                                                                            className="fa fa-eye"></i>
                                                                                                        </a> :
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn"
                                                                                                            style={{marginRight: "10px"}}
                                                                                                            title="Upload Template"
                                                                                                            onClick={() => handleUploadTemplate(item.id, item.document_type)}
                                                                                                        >
                                                                                                            <i className="fa fa-upload"></i>
                                                                                                        </button>
                                                                                                    }
                                                                                                    {item.template &&
                                                                                                        <i className="fa fa-trash"
                                                                                                           style={{marginRight: "10px"}}
                                                                                                           onClick={() =>
                                                                                                               handleTemplate(
                                                                                                                   item.id, item.document_type
                                                                                                               )
                                                                                                           }></i>}

                                                                                                </td>
                                                                                            </tr>)
                                                                                        })}


                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>

                                                                        </div>)
                                                                    })}
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }

                            </div>
                            <div className="mt-4 text-center create-deal-btn">
                                <FormGroup>
                                    <Button>Submit</Button>
                                </FormGroup>
                            </div>

                        </div>
                    </AvForm>
                </div>
            </section>

        </main>
        <Modal
            visible={showDocumentData}
            width="800"
            height="420"
            effect="fadeInUp"
            onClickAway={() => closeDocument()}
        >
            <div style={{textAlign: "right"}} className="p-3">
                <a href="javascript:void(0);" onClick={() => closeDocument()}>
                    Close
                </a>
            </div>

            <div className="container p-3">
                <h2 className="text-center">Create New Document </h2>
                <Select
                    options={options}
                    onInputChange={handleInputChange}
                    onChange={setSelectedDocument}
                    placeholder="Search and select a document..."
                    className="mt-5"
                />
                <div className="text-center mt-4 create-deal-btn">
                    <button onClick={handledocumentSubmit} className="btn">Submit</button>
                </div>
            </div>
        </Modal>
        <Modal
            visible={showUploadDocument}
            width="800"
            height="320"
            effect="fadeInUp"
            onClickAway={() => closeUploadData()}
        >
            <div style={{textAlign: "right"}} className="p-3">
                <a href="javascript:void(0);" onClick={() => closeUploadData()}>
                    Close
                </a>
            </div>

            <div className="container p-3">
                <h2 className="text-center">Upload Document </h2>
                <input
                    type="file"
                    name="name"
                    className="form-control"
                    onChange={(e) => {
                        setFileData(e.target.files[0]);
                    }}
                />
                <div className="text-center create-deal-btn">
                    <button type="submit" className="mt-4 btn" onClick={() => handleFileUpload()}>
                        Upload
                    </button>
                </div>
            </div>
        </Modal>
        <Modal
            visible={showUploadFile}
            width="800"
            height="320"
            effect="fadeInUp"
            onClickAway={() => closeUploadFile()}
        >
            <div style={{textAlign: "right"}} className="p-3">
                <a href="javascript:void(0);" onClick={() => closeUploadFile()}>
                    Close
                </a>
            </div>

            <div className="container p-3">
                <h2 className="text-center">Upload Document </h2>
                <div>
                    <input type="file" onChange={handleFileChange}/>
                    <button onClick={handleDocumentSubmit}>Upload</button>
                </div>
            </div>
        </Modal>
        <Modal
            visible={showUploadTemplate}
            width="800"
            height="320"
            effect="fadeInUp"
            onClickAway={() => closeUploadTemplate()}
        >
            <div style={{textAlign: "right"}} className="p-3">
                <a href="javascript:void(0);" onClick={() => closeUploadTemplate()}>
                    Close
                </a>
            </div>

            <div className="container p-3">
                <h2 className="text-center">Upload Template</h2>
                <input
                    type="file"
                    name="name"
                    className="form-control"
                    onChange={(e) => {
                        setFileData(e.target.files[0]);
                    }}
                />
                <div style={{textAlign: "center"}} className="p-3 create-deal-btn">
                    <button type="submit" className="mt-4 btn" onClick={handleFileUploadTemplate}>
                        Upload
                    </button>
                </div>
            </div>
        </Modal>
    </Layout>);
};
export default BorrowerSetup;
