import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import DealManagement from './component/core-pages/Deal-Management/deal-management';
import BorrowerDetails from './component/core-pages/Borrower-Details/borrower-details';
import NewDocument from './component/core-pages/New-Documents/new-documents';
import LenderSetupData from './component/core-pages/Lender-Setup/lender-setup';
import BorrowerSetup from './component/core-pages/Borrower-Setup/borrower-setup';
import DocumentManagement from './component/core-pages/document-management/document-management';
import Login from './component/core-pages/Login/Login';
import LenderProfile from './component/core-pages/lender-profile/lender-profile';
import BorrowerProfile from './component/core-pages/borrower-profile/borrower-profile';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

function App() {
    return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Login}/>
                    <Route exact path='/dashboard' component={DealManagement}/>
                    <Route exact path='/new-document' component={NewDocument}/>
                    <Route path="/new-document/edit-document/:id" component={NewDocument} exact/>
                    <Route exact path='/document-management' component={DocumentManagement}/>
                    <Route exact path='/borrower-setup' component={BorrowerDetails}/>
                    <Route exact path='/borrower-setup/:id' component={BorrowerDetails}/>
                    <Route exact path='/lender-setup/:id' component={LenderSetupData}/>
                    <Route exact path='/deal-setup/:id' component={BorrowerSetup}/>
                    {/*<Route exact path='/lender-deal-management' component={LenderDealManagement}/>*/}
                    <Route exact path='/lender-profile' component={LenderProfile}/>
                    {/*<Route exact path='/borrower-deal-management' component={BorrowerDealManagement}/>*/}
                    <Route exact path='/borrower-profile' component={BorrowerProfile}/>
                    {/*<Route exact path='/borrower-deal-setup/:id' component={BorrowerDealSetup} />*/}
                    {/*<Route exact path='/lender-deal-setup/:id' component={LenderEditDealSetup}/>*/}
                    {/*<Route exact path='/borrower-deal-setup/:id' component={BorrowerEditDealSetup}/>*/}
                </Switch>
            </Router>
    );
}

export default App;
