export const APP_CONFIGURATION = {
    APP_CONSTANT: {
        API_URL: "https://mkdev.kreditserve.com/",
    },
    HEADER_PARAMETERS: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access")
    },
};
  