import React, {useEffect, useState, useContext} from "react";
import Layout from "../../page-layouts/Layout/Layout";
import Modal from "react-awesome-modal";
import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import "../../global.css";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {APP_CONFIGURATION} from "../../../app-config/configuration";
import {PermissionsContext} from "../../../context";
import api from '../../../app-config/axiosConfig';

const DocumentManagement = () => {
    const [dev, setDev] = useState(false);
    const [docData, setDocData] = useState();
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [showDeactivate, setShowDeactivate] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [deactivateId, setDeactivateId] = useState("");
    const [duplicateId, setDuplicateId] = useState("");
    const [tableData, setTableData] = useState();
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
    });
    const {permissions} = useContext(PermissionsContext);
    const columns = [
        {
            field: "category",
            headerName: "Document Category",
            width: 180,
            renderCell: (params) => <div>{params.row.category.name}</div>,
        },
        {
            field: "name",
            headerName: "Document Name",
            width: 180,
            renderCell: (params) => <div>{params.row.name}</div>,
        },
        {
            field: "document_required_on_deal_status",
            headerName: "Required Deal Status",
            width: 200,
            renderCell: (params) => <div>{params.row.deal_status}</div>,
        },
        {
            field: "block_visible_to",
            headerName: "Block Visible",
            width: 200,
            renderCell: (params) => (
                <div>{params.row.block_visible_to.join(", ") ? params.row.block_visible_to.join(", ") : '--'}</div>
            ),
        },
        {
            field: "allow_upload_for",
            headerName: "Allow Upload",
            width: 200,
            renderCell: (params) => (
                <div>{params.row.allow_upload_for.join(", ") ? params.row.allow_upload_for.join(", ") : '--'}</div>
            ),
        },
        {
            field: "acceptable_file_format",
            headerName: "File Format",
            width: 178,
            renderCell: (params) => (
                <div>{params.row.acceptable_file_formats.join(", ") ? params.row.acceptable_file_formats.join(", ") : '--'}</div>
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 300,
            renderCell: (params) => (
                <div>
                    {permissions && permissions.update_document_master && (
                        <Link
                            className="btn edit-btn"
                            to={`/new-document/edit-document/${params.id}`}
                        >
                            <span>Edit</span>
                        </Link>
                    )}
                    {permissions && permissions.duplicate_document_master && (
                        <button
                            className="btn duplicate-btn"
                            style={{marginLeft: "10px", marginRight: "10px"}}
                            onClick={() => handleDuplicate(params.id)}
                        >
                            Duplicate
                        </button>
                    )}
                    {permissions && permissions.deactivate_document_master && (
                        <button
                            className="btn deactivate-btn"
                            onClick={() => handleDeactive(params.id)}
                        >
                            Deactivate
                        </button>
                    )}
                </div>
            ),
        },
    ];

    useEffect(() => {
        setPageState((old) => ({...old, isLoading: true}));
        api.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/master_list_document/?search=&master_page_number=${pageState.page}&master_page_size=${pageState.pageSize}`)
            .then(
                (res) => {
                    if (res) {
                        setTableData(res.data.data);
                        setPageState((old) => ({
                            ...old,
                            isLoading: false,
                            data: res.data.data,
                            total: res.data.count,
                        }));
                    }
                },
                (err) => {
                }
            );
    }, [pageState.page, pageState.pageSize]);

    useEffect(() => {
        if (dev) {
        } else {
            const documentData = {
                success: true,
                error: "error response",
                data: [
                    {
                        id: "1",
                        created_at: "datetime",
                        modified_at: "datetime",
                        name: "Tal",
                        description: "",
                        category: "Buyer",
                        block_visible_to: ["Borrower", "Lender"],
                        allow_upload_for: ["Lender", "Borrower"],
                        document_type: "string",
                        document_required_on_deal_status: " ",
                        acceptable_file_format: ["xls", "doc", "ppt", "txt", "pdf"],
                        Is_active: true,
                    },
                    {
                        id: "2",
                        created_at: "datetime",
                        modified_at: "datetime",
                        name: "Dejr",
                        description: "",
                        category: "",
                        block_visible_to: ["Borrower", "Lender"],
                        allow_upload_for: ["Lender", "Borrower"],
                        document_type: "string",
                        document_required_on_deal_status: " ",
                        acceptable_file_format: ["xls", "doc", "ppt", "txt", "pdf"],
                        Is_active: true,
                    },
                ],
            };
            setDocData(documentData);
        }
    }, []);

    const handleSelectionChange = (newSelection) => {
        setSelectionModel(newSelection);
    };

    const handleDuplicate = (id) => {
        setDuplicateId(id);
        setShowDuplicate(true);
    };

    const handleDeactive = (id) => {
        setShowDeactivate(true);
        setDeactivateId(id);
    };

    const closeModal = () => {
        setShowDeactivate(false);
        setShowDuplicate(false);
    };

    const submitDuplicate = () => {
        api.get(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/duplicate_master_document/${duplicateId}/ `)
            .then(
                (res) => {
                    toast("Duplicate Successfully");
                    closeModal();
                    window.location.reload()
                },
                (err) => {
                }
            );
    };

    const submitDeactivate = () => {
        const payload = {
            document_ids: [deactivateId],
        };
        api.post(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/deactivate_master_document/`, payload)
            .then(
                (res) => {
                    toast("Deactivate Successfully");
                    closeModal();
                    window.location.reload()
                },
                (err) => {
                    toast.error(err.message);
                }
            );
    };

    return (
        <>
            <Layout>
                <main id="main" className="main">
                    <ToastContainer/>
                    <div className="pagetitle mt-3">
                        <h1>Document Management</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    Home
                                </li>
                                <li className="breadcrumb-item active">Document Management</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section dashboard">
                        {permissions && permissions.create_document_master && (
                            <div className="row mt-5">
                                <div className="col-md-10"></div>
                                <div className="col-md-2 document-management-btn">
                                    <Link className="nav-link collapsed" to="/new-document">
                                        <button className="btn">New Document</button>
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div className="row" style={{marginTop: "40px"}}>
                            <div className="col-md-12">
                                {permissions && permissions.view_document_master && (
                                    <Box>
                                        <DataGrid
                                            autoHeight
                                            rows={pageState && pageState.data ? pageState.data : []}
                                            rowCount={pageState.total}
                                            loading={pageState.isLoading}
                                            rowsPerPageOptions={[10, 30, 50, 70, 100]}
                                            pagination
                                            page={pageState.page - 1}
                                            pageSize={pageState.pageSize}
                                            paginationMode="server"
                                            disableSelectionOnClick
                                            // checkboxSelection
                                            onSelectionModelChange={handleSelectionChange}
                                            selectionModel={selectionModel}
                                            onPageChange={(newPage) => {
                                                setPageState((old) => ({...old, page: newPage + 1}));
                                            }}
                                            onPageSizeChange={(newPageSize) =>
                                                setPageState((old) => ({...old, pageSize: newPageSize}))
                                            }
                                            columns={columns}
                                        />
                                    </Box>
                                )}

                            </div>
                        </div>
                    </section>
                </main>
                <Modal
                    visible={showDuplicate}
                    width="500"
                    height="200"
                    effect="fadeInDown"
                    onClickAway={() => closeModal()}
                >
                    <div className="text-center" style={{padding: "5px"}}>
                        <h3 style={{paddingTop: "15px"}}>Duplicate</h3>
                        <p>Are you sure you want to make a duplicate?</p>
                        <button
                            className="btn admin-sales-queue"
                            style={{
                                marginTop: "15px",
                                backgroundColor: "#4154f1",
                                color: "white",
                                borderRadius: "0px",
                            }}
                            onClick={() => submitDuplicate()}
                        >
                            Yes
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn admin-sales-queue"
                            style={{
                                marginTop: "15px",
                                backgroundColor: "#4154f1",
                                color: "white",
                                borderRadius: "0px",
                            }}
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
                <Modal
                    visible={showDeactivate}
                    width="500"
                    height="200"
                    effect="fadeInDown"
                    onClickAway={() => closeModal()}
                >
                    <div className="text-center" style={{padding: "5px"}}>
                        <h3 style={{paddingTop: "15px"}}>Deactivate</h3>
                        <p>Are you sure want to deactivate?</p>
                        <button
                            className="btn admin-sales-queue"
                            style={{
                                marginTop: "15px",
                                backgroundColor: "#4154f1",
                                color: "white",
                                borderRadius: "0px",
                            }}
                            onClick={() => submitDeactivate()}
                        >
                            Yes
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn admin-sales-queue"
                            style={{
                                marginTop: "15px",
                                backgroundColor: "#4154f1",
                                color: "white",
                                borderRadius: "0px",
                            }}
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
            </Layout>
        </>
    );
};
export default DocumentManagement;
