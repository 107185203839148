import React, {useEffect, useState} from "react";
import Layout from "../../page-layouts/Layout/Layout";
import Modal from "react-awesome-modal";
import "../../global.css";
import * as Yup from "yup";
import {
    AvField,
    AvForm,
    AvRadio,
    AvRadioGroup,
} from "availity-reactstrap-validation";
import {Button, FormGroup} from "reactstrap";

import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import Select from "react-select";
import {APP_CONFIGURATION} from "../../../app-config/configuration";
import api from '../../../app-config/axiosConfig'

const LenderProfile = (props) => {
    const [editLenderData, setEditLenderData] = useState();
    const [tableData, setTableData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [editShowStatus, setEditShowStatus] = useState(false);
    const [mainStatus, setMainStatus] = useState();
    const [editUserData, setEditUserData] = useState();
    const [editUserId, setEditUserId] = useState("");
    const [documentList, setDocumentList] = useState();
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showUploadTemplate, setShowUploadTemplate] = useState(false);
    const [options, setOptions] = useState([]);
    const [userTable, setUserTable] = useState();
    const [uploadTemplateId, setUploadTemplateId] = useState("");
    const [documentTypeId, setDocumentType] = useState('')
    const [documentInput, setDocumentInput] = useState("");
    const [urlId, setUrlId] = useState();
    const [showDocumentData, setShowDocumentData] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [documentUploadId, setDocumentUploadId] = useState("");
    const [editData, setEditData] = useState();
    const [showUploadDocument, setShowUploadDocument] = useState(false);
    const [handleAddressStatus, setHandleAddressStatus] = useState(false)
    const [uploadTemplateFile, setUploadTemplate] = useState();
    const [mainFile, setFile] = useState(null);
    const [removeId, setRemoveId] = useState("");
    const [allState, setStateData] = useState();
    const [cityId, setCityId] = useState();
    const [cityId2, setCityId2] = useState();
    const [allCity2, setCityData2] = useState();
    const [allCity, setCityData] = useState();
    const [userData, setUserData] = useState();
    const lenderId = localStorage.getItem('user_id')
    const [data, setData] = useState([]);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
    });

    const handleCheckboxChange = (event) => {
        const checkboxId = event.target.id;
        setRemoveId(event.target.id)
        const checkedData = ((prevSelected) => {
            if (event.target.checked) {
                return [...prevSelected, checkboxId];
            } else {
                return prevSelected.filter((id) => id !== checkboxId);
            }
        })
        setSelectedCheckboxes(checkedData)
    };
    const fetchDocuments = async (searchQuery = "") => {
        // const response = await api.get(
        //     "https://mkdev.kreditserve.com/marketkredit/api/v1/document/master_list_document/",
        //     {
        //         params: {
        //             search: searchQuery,
        //             master_page_number: 1,
        //             master_page_size: 5,
        //         },
        //         headers: {
        //             Authorization: "Bearer " + localStorage.getItem("access"),
        //             "Content-Type": "application/json",
        //         },
        //     }
        // );
        //
        // const documents = response.data.data.map((doc) => ({
        //     value: doc.id,
        //     label: doc.name,
        // }));
        //
        // const userInputOption = searchQuery.trim()
        //     ? [{value: searchQuery, label: `${searchQuery}`}]
        //     : [];
        // setOptions([...documents, ...userInputOption]);
        try {
            const response = await api.get(
                "https://mkdev.kreditserve.com/marketkredit/api/v1/document/master_list_document/",
                {
                    params: {
                        search: searchQuery,
                        master_page_number: 1,
                        master_page_size: 5,
                    },
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("access"),
                        "Content-Type": "application/json",
                    },
                }
            );

            const documents = response.data.data.map((doc) => ({
                value: doc.id,
                label: doc.name,
            }));

            const userInputOption = searchQuery.trim()
                ? [{value: searchQuery, label: `${searchQuery}`}]
                : [];
            setOptions([...documents, ...userInputOption]);
        } catch (error) {
            console.error("Error:", error.response.data); // Log the specific error message
            // Handle error accordingly, e.g., show a user-friendly message
        }
    };
    const handleInputChange = (newValue) => {
        fetchDocuments(newValue);
    };

    const handleRadioChange = (event) => {
        const newValue = event.target.value === "true";
        setMainStatus(newValue);
    };

    useEffect(() => {
        const status = editUserData ? editUserData.communications_to_send : false;
        setMainStatus(status);
    }, []);

    const handleEditValidUserSubmit = (e, values) => {
        const payload = {
            first_name: values.name,
            phone_number: values.cNumber instanceof Array ? values.cNumber[0] : values.cNumber,
            email_address: values.emailId instanceof Array ? values.emailId[0] : values.emailId,
            designation: values.designation,
            communications_to_send: mainStatus,
        };
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .patch(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/user/${editUserId}/`,
                payload,
                {
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then(
                (res) => {
                    toast("User Edit Successfully");
                    closeModal();
                    window.location.reload();
                },
                (err) => {
                    // alert('fail')
                }
            );
    };

    const handleDocumentInputChange = async (inputValue) => {
        setDocumentInput(inputValue);

        try {
            if (inputValue.trim() === "") {
                // If input is empty, fetch all documents
                const response = await api.get(
                    "https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/",
                    {
                        headers: {
                            Accept: "*/*",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("access"),
                        },
                    }
                );
                const modifiedData = [
                    ...response.data.data, // Add your custom object here
                    {
                        id: "",
                        name: inputValue,
                        category: {id: "categoryId", name: "categoryName"},
                    },
                ];

                const mappedData = response.data.map((document) => ({
                    value: document.id,
                    label: document.name,
                }));
                setDocumentList(Array.isArray(mappedData) ? mappedData : []);
            } else {
                const response = await api.get(
                    `https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/?search=${inputValue}`,
                    {
                        headers: {
                            Accept: "*/*",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("access"),
                        },
                    }
                );
                const modifiedData = [
                    ...response.data.data,
                    {
                        id: "",
                        name: inputValue,
                        category: {id: "categoryId", name: "categoryName"},
                    },
                ];

                const mappedData = response.data.map((document) => ({
                    value: document.id,
                    label: document.name,
                }));
                setDocumentList(Array.isArray(mappedData) ? mappedData : []);
            }
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };

    useEffect(() => {
        fetchDocuments();
        getAllState();
    }, []);

    const closeUploadTemplate = () => {
        setShowUploadTemplate(false);
    };

    useEffect(() => {
        setUrlId(lenderId);
        api
            .get(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/lender/${lenderId && lenderId}/`,
                {
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then(
                (res) => {
                    if (res) {
                        if (res.data.data.register_address != null) {
                            setCityId(res.data.data.register_address.state);
                        }
                        if (res.data.data.communication_address != null) {
                            setCityId2(res.data.data.communication_address.state);
                        }
                        setEditLenderData(res.data.data)
                        setHandleAddressStatus(res.data.data.communication_address_same_as_register_address)
                        setEditData(res.data.data);
                        const doc =
                            res &&
                            res.data.data.lender_documents.map((item) => {
                                return {
                                    id: item.id,
                                    borrower_comments: item.borrower_comments,
                                    kreditserve_comments: item.kreditserve_comments,
                                    lender_comments: item.lender_comments,
                                    document: item.document.file,
                                    title: item.document.title,
                                    category: item.document.type.category,
                                    categoryId: item.document.type.category,
                                    template: item.document.template,
                                    document_type: item.document.type.document_type,
                                    status: item.status
                                };
                            });
                        setData(doc);
                    }
                },
                (err) => {
                    // toast.error(err.message);
                }
            );
        api
            .get(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/user/list_agent/lender/?object_id=${lenderId & lenderId}`,
                {
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then(
                (res) => {
                    setUserData(res.data.data);
                },
                (err) => {
                    // alert('fail')
                }
            );

    }, []);

    useEffect(() => {
        if (cityId) {
            api
                .get(
                    `https://mkdev.kreditserve.com/marketkredit/api/v1/city/?state_id=${cityId}`,
                    {
                        headers: {
                            Accept: "*/*",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("access"),
                        },
                    }
                )
                .then(
                    (res) => {
                        setCityData(res.data.data);
                    },
                    (err) => {
                        // alert('fail')
                    }
                );
        }
        if (cityId2) {
            api
                .get(
                    `https://mkdev.kreditserve.com/marketkredit/api/v1/city/?state_id=${cityId2}`,
                    {
                        headers: {
                            Accept: "*/*",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("access"),
                        },
                    }
                )
                .then(
                    (res) => {
                        setCityData2(res.data.data);
                    },
                    (err) => {
                        // alert('fail')
                    }
                );
        }
    }, [cityId, cityId2]);

    const groupedData = data.reduce((acc, item) => {
        const {category, categoryId} = item;
        if (!acc[category]) {
            acc[category] = {};
        }
        if (!acc[category][categoryId]) {
            acc[category][categoryId] = [];
        }
        acc[category][categoryId].push(item);
        return acc;
    }, {});

    const columns = [
        {
            field: "first_name",
            headerName: "Name",
            width: 325,
            renderCell: (params) => <div>{params.row.first_name}</div>,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 325,
            renderCell: (params) => (
                <div>
                    {params.row.phones.map((data) => {
                        return data.number;
                    })}
                </div>
            ),
        },
        {
            field: "EmailId",
            headerName: "emailId",
            width: 320,
            renderCell: (params) => (
                <div>
                    {params.row.emails.map((data) => {
                        return data.email_id;
                    })}
                </div>
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 350,
            renderCell: (params) => (
                <div>
                    <button
                        className="btn edit-btn"
                        type="button"
                        onClick={() => handleEditUser(params.row.id)}
                        style={{marginRight: "10px"}}
                    >
                        Edit
                    </button>

                    {/* <button className="btn btn-warning text-white ml-3" onClick={() => hanldeDeactivate()}>Deactivate</button> */}
                </div>
            ),
        },
    ];

    const handleEditUser = (id) => {
        setEditShowStatus(true);
        setEditUserId(id);
        api
            .get(`https://mkdev.kreditserve.com/marketkredit/api/v1/user/${id}/`, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then(
                (res) => {
                    if (res) {
                        setMainStatus(res.data.data.communications_to_send)
                        setEditUserData(res.data.data);
                        // setEditBorrowerData(res.data.data)
                        // setUserTable(res.data.data)
                    }
                },
                (err) => {
                    // toast.error(err.message);
                }
            );
    };

    // const initialValues = {
    //     landerName: "",
    // };
    // const initialProfileValues = {
    //     panNo: "",
    //     gstNo: "",
    //     cinNo: "",
    //     email: "",
    //     city: "",
    //     state: "",
    //     pinNo: "",
    //     communicationAddress: "",
    //     caddress: "",
    //     cstate: "",
    //     cpin: "",
    // };
    // const initialAddUserValues = {
    //     name: "",
    //     contactNumber: "",
    //     emailId: "",
    //     designation: "",
    //     sent: "",
    // };
    // const validationSchema = Yup.object().shape({
    //     landerName: Yup.string().required("Lander Name is required"),
    // });
    //
    // const validationAddUserSchema = Yup.object().shape({
    //     name: Yup.string().required("Name is Required"),
    //     contactNumber: Yup.string().required("Phone Number is Required"),
    //     emailId: Yup.string().required("email is Required"),
    //     designation: Yup.string().required("Designation is Required"),
    //     sent: Yup.string().required("Sent is Required"),
    // });
    //
    // const initialProfileDetailsValues = {
    //     panNo: "",
    //     gstNo: "",
    //     cinNo: "",
    //     registeredAddress: "",
    //     city: "",
    //     state: "",
    //     pin: "",
    //     communicationAddress: "",
    //     mCity: "",
    //     mState: "",
    //     mPin: "",
    // };
    const columns8 = [{
        field: "city", headerName: "Deal Name", width: 400, renderCell: (params) => (<div>
            <Link className="nav-link " to="/deal-setup">
                {params.row.deal_name}
            </Link>
        </div>),
    }, {
        field: "borrower", headerName: "Borrower", width: 200, renderCell: (params) => (<div>
            {" "}
            <Link className="nav-link " to={`/borrower-setup/${params.row.borrower["id"]}`}>
                {params.row.borrower ? params.row.borrower["borrower_name"] : ""}
            </Link>
        </div>),
    },
        {
            field: "dealValue",
            headerName: "Deal Value",
            width: 200,
            renderCell: (params) => (<div>{params.row && params.row.credit_requirement}</div>),
        }, {
            field: "prefferedProductType",
            headerName: "Preffered Product Type(s)",
            width: 180,
            renderCell: (params) => <div>{params.row.product.name}</div>
        }, {
            field: "lender", headerName: "Lender", width: 100, renderCell: (params) => (<div>
                {" "}
                <Link className="nav-link " to={`/lender-setup/${params.row.lender.id}}`}>
                    {params.row.lender.lender_name}
                </Link>
            </div>),
        }, {
            field: "dealStatus",
            headerName: "Deal Status",
            width: 100,
            renderCell: (params) => <div>{params.row.deal_status}</div>
        }, {
            field: "rm",
            headerName: "RM",
            width: 200,
            renderCell: (params) =>
                <div>{params.row.relationship_manager ? params.row.relationship_manager.first_name : ''}</div>
        }, {
            field: "boAgent",
            headerName: "BO Agent",
            width: 200,
            renderCell: (params) => <div>{params.row.bo_agent.first_name}</div>
        },

        {
            field: "actions", headerName: "Actions", width: 300, renderCell: (params) => (<div>
                <button className="btn edit-btn">
                    <Link className="nav-link " to={`/lender-deal-setup/${params.id}`}>
                        View
                    </Link>
                </button>
                {/* <button
                className="btn duplicate-btn text-white"
                style={{marginLeft: "10px", marginRight: "10px"}}
                // onClick={() => handleDuplicate(params.id)}
            >
                Duplicate
            </button>
            <button
                className="btn deactivate-btn"
                // onClick={() => handleDeactive(params.id)}
            >
                Deactivate
            </button> */}
            </div>),
        },];

    const validationProfileDetailsSchema = Yup.object().shape({
        panNo: Yup.string().required("PAN No is required"),
        gstNo: Yup.string().required("GST No is required"),
        cinNo: Yup.string().required("PCIN No is Required"),
        registeredAddress: Yup.string().required("Registered Address is Required"),
        city: Yup.string().required("City is Required"),
        state: Yup.string().required("State is Required"),
        pin: Yup.string().required("PIN is Required"),
        communicationAddress: Yup.string().required(
            "Communication Address is Required"
        ),
        mCity: Yup.string().required("City is Required"),
        mState: Yup.string().required("State is Required"),
        mPin: Yup.string().required("PIN is Required"),
    });

    useEffect(() => {
        const {location} = props;
        const url = location.pathname;
        const documentId = url.substring(url.lastIndexOf("/") + 1);
        const validate = documentId && url.split("/").length === 3;
        if (documentId && url.split("/").length === 3) {
            api
                .get(
                    `https://mkdev.kreditserve.com/marketkredit/api/v1/lender/${documentId}/`,
                    {
                        headers: {
                            Accept: "*/*",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("access"),
                        },
                    }
                )
                .then(
                    (res) => {
                        if (res) {
                            // setEditBorrowerData(res.data.data)
                            // setUserTable(res.data.data)

                            setEditLenderData(res.data.data);
                            setUserTable(res.data.data);
                            const doc =
                                res &&
                                res.data.data.lender_documents.map((item) => {
                                    return {
                                        id: item.id,
                                        borrower_comments: item.borrower_comments,
                                        kreditserve_comments: item.kreditserve_comments,
                                        lender_comments: "",
                                        document: item.document.file,
                                        title: item.document.title,
                                        category: item.document.type.category,
                                        categoryId: item.document.type.category,
                                        template: item.document.template,
                                    };
                                });
                            setData(doc);
                        }
                    },
                    (err) => {
                        // toast.error(err.message);
                    }
                );
        } else {
        }
    }, []);

    const handleSubmit = (values) => {
        // Handle form submission here (e.g., API request or authentication)
        console.log("Add User:", values);
    };
    const [visible, setVisible] = useState(false);
    const openModal = () => {
        setVisible(true);
    };
    const closeModal = () => {
        setVisible(false);
        setEditShowStatus(false);
    };
    const handleValidSubmit = (e, values) => {
        const payloadData = handleAddressStatus ? {
            borrower_name: values.borrowerName,
            pan_number: values.lPanNo,
            cin_number: values.lCinNo,
            gst_number: values.lGstNo,
            register_address: {
                line1: values.laddress,
                line2: values.laddress2,
                city: values.lCity,
                state: values.lState,
                pincode: values.lPin,
            },
            communication_address_same_as_register_address: handleAddressStatus,
            documents: data,
            primary_user: 'bd15043a-85ef-4b33-96bd-8a9c56e0e3fc'
        } : {
            borrower_name: values.borrowerName,
            pan_number: values.lPanNo,
            cin_number: values.lCinNo,
            gst_number: values.lGstNo,
            register_address: {
                line1: values.laddress,
                line2: values.laddress2,
                city: values.lCity,
                state: values.lState,
                pincode: values.lPin,
            },
            communication_address: {
                line1: values.lCommunicationAddress,
                line2: values.lCommunicationAddress2,
                city: values.lcommunicationCity,
                state: values.lcommunicationState,
                pincode: values.lcommunicationPin,
            },
            communication_address_same_as_register_address: handleAddressStatus,
            documents: data,
            primary_user: 'bd15043a-85ef-4b33-96bd-8a9c56e0e3fc'
        };
        api
            .patch(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/lender/${urlId}/`,
                payloadData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Lender Edit Successfully");
                    setTimeout(() => {
                        // window.location.replace("/lender-deal-management");
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleEdit = (id, field, value) => {
        const newData = data.map((item) =>
            item.id === id ? {...item, [field]: value} : item
        );
        setData(newData);
    };

    const handleDocument = () => {
        setShowDocumentData(true);
    };
    const closeDocument = () => {
        setShowDocumentData(false);
    };
    const initialDocumentValues = {
        createDocument: "",
    };
    const validationDocumentSchema = Yup.object().shape({
        createDocument: Yup.string().required("Document is required"),
    });
    const handledocumentSubmit = (values) => {
        const payload = {
            entity_id: urlId,
            document: {
                id: selectedDocument.value,
                name: selectedDocument.label,
                document_type: "Lender",
            },
        };
        api
            .post(
                "https://mkdev.kreditserve.com/marketkredit/api/v1/document/create_document/",
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Document Created Successfully");
                    setTimeout(() => {
                        closeDocument();
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                // toast.error(err.response.data.detail);
            });
    };
    const handleUnShare = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Lender"};
        });
        const payload = {
            documents: arrayOfObjects,
        };
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/document/unshare_borrower/`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Borrower UnShare Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleShare = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Lender"};
        });
        const payload = {
            documents: arrayOfObjects,
        };
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/document/share_borrower/`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Borrower Share Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleShareWithLender = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Lender"};
        });
        const payload = {
            documents: arrayOfObjects,
        };
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/document/share_lender/`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Lender Share Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleUnShareWithLender = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Lender"};
        });
        const payload = {
            documents: arrayOfObjects,
        };
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/document/unshare_lender/`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Lender UnShare Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleId = (id) => {
        console.log("d d ", id);
    };
    const handleRemoveDocument = () => {
        const payload = {
            document_type: "Lender",
        };
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/document/remove_document/${removeId}/`,
                payload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Delete Document Successfully");
                    setTimeout(() => {
                        // closeUploadTemplate()
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    };
    const handleValidUserSubmit = (e, values) => {
        const payload = {
            first_name: values.name,
            phone_number: values.cNumber,
            email_address: values.emailId,
            designation: values.designation,
            communications_to_send: mainStatus,
        };
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/user/create_user/lender/${urlId}/`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("User Created Successfully");
                    setTimeout(() => {
                        closeModal();
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleUploadData = (id, documentType) => {
        setDocumentUploadId(id);
        setDocumentType(documentType)
        setShowUploadDocument(true);
    };
    const closeUploadData = () => {
        setShowUploadDocument(false);
    };
    const handleDelte = (id, docType) => {
        const payload = {
            document_type: docType,
        };
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .post(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/remove_document/${id}/`,
                payload,
                {headers: headersParam}
            )
            .then((res) => {
                if (res.status) {
                    toast("Document Deleted Successfully");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleTemplate = (id) => {
        const payload = {
            document_type: "Lender",
        };
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .post(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/remove_template/${id}/`,
                payload,
                {headers: headersParam}
            )
            .then((res) => {
                if (res.status) {
                    toast("Document Deleted Successfully");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleUploadTemplate = (id) => {
        setShowUploadTemplate(true);
        setUploadTemplateId(id);
    };

    const handleFileUpload = () => {
        const formData = new FormData();
        formData.append("file", mainFile);
        formData.append("document_type", documentTypeId);
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_document/${documentUploadId}/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Upload Document Successfully");
                    setTimeout(() => {
                        closeUploadData();
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    };

    const handleDownloadData = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });
        api({
            url: "https://mkdev.kreditserve.com/marketkredit/api/v1/document/download_documents_bulk/",
            method: "POST",
            responseType: "blob",
            data: {documents: arrayOfObjects},
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
            },
        })
            .then((response) => {
                const borrowerName =
                    editData && editData.borrower_name
                        ? editData.borrower_name
                        : "Borrower";
                const timestamp = new Date()
                    .toISOString()
                    .replace(/[\-\:\.T]/g, "")
                    .slice(0, 14); // Format: yyyyMMddHHmmss

                let filename = `${borrowerName.replace(/\s+/g, "_")}-${timestamp}.zip`; // Constructed filename

                console.log("Response Headers:", response.headers); // Log the headers for diagnostic purposes

                const contentDisposition = response.headers["content-disposition"];
                if (contentDisposition) {
                    const filenameMatch = /filename="([^"]+)"/.exec(contentDisposition);
                    if (filenameMatch && filenameMatch[1]) {
                        filename = filenameMatch[1];
                        console.log("Extracted filename:", filename); // Log the extracted filename
                    } else {
                        console.log(
                            "Filename extraction failed. Content-Disposition:",
                            contentDisposition
                        );
                    }
                } else {
                    console.log("No Content-Disposition header found.");
                }

                // Create a Blob from the response
                const file = new Blob([response.data], {type: "application/zip"});
                // Create an anchor element and set the URL
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.setAttribute("download", filename);
                // Append to the DOM and trigger click
                document.body.appendChild(link);
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
                URL.revokeObjectURL(fileURL);
            })
            .catch((error) => {
                console.error("Download error:", error);
            });
    };

    const handleFileUploadTemplate = () => {
        const formData = new FormData();
        formData.append("file", uploadTemplateFile);
        formData.append("document_type", "Lender");
        api
            .post(
                `https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_template/${uploadTemplateId}/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                }
            )
            .then((res) => {
                if (res.status) {
                    toast("Upload Template Successfully");
                    setTimeout(() => {
                        closeUploadTemplate();
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    };
    const getAllState = () => {
        api
            .get("https://mkdev.kreditserve.com/marketkredit/api/v1/state/", {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then(
                (res) => {
                    setStateData(res.data.data);
                },
                (err) => {
                    // alert('fail')
                }
            );
    };
    const handleSelectChange = (e) => {
        setCityId(e.target.value);
    };
    const handleSelectChange2 = (e) => {
        setCityId2(e.target.value);
    };
    useEffect(() => {
        const url = `https://mkdev.kreditserve.com/marketkredit/api/v1/deal/list_deals/lender/${urlId}/`
        if (urlId) {
            setPageState((old) => ({...old, isLoading: true}));
            api.get(url, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
                .then((res) => {
                    if (res) {
                        setTableData(res.data.data);
                        setPageState((old) => ({
                            ...old, isLoading: false, data: res.data.data, total: 20,
                        }));
                    }
                }, (err) => {
                });
        }
    }, [pageState.page, pageState.pageSize, urlId]);

    const hanldeAddress = () => {
        setHandleAddressStatus(!handleAddressStatus)
    }
    return (
        <Layout>
            <main id="main" className="main">
                <ToastContainer/>
                <div className="pagetitle mt-3">
                    <h1>Lender Setup</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Home</li>
                            <li className="breadcrumb-item active">Lender Setup</li>
                        </ol>
                    </nav>
                </div>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <section className="section dashboard">
                        <div className="container-fluid mt-5">
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingTwo"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    <p className="fw-bold m-0">Lender</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    {/* third */}
                                                    <div className="row">
                                                        <div className="row">
                                                            <div className="col-md-12 mt-3">
                                                                <div className="mb-3 row">
                                                                    <label
                                                                        htmlFor="staticEmail"
                                                                        className="col-sm-2 col-form-label"
                                                                    >
                                                                        Lender Name :{" "}
                                                                    </label>
                                                                    <div className="col-sm-10">
                                                                        <AvField
                                                                            type="text"
                                                                            id="landerName"
                                                                            name="landerName"
                                                                            placeholder="Enter Lander Name"
                                                                            className="form-control"
                                                                            value={
                                                                                (editLenderData &&
                                                                                    editLenderData.lender_name) ||
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Box>
                                                                <div
                                                                    style={{
                                                                        height: 500,
                                                                        width: "100%",
                                                                        transition: "all 1.5s linear",
                                                                    }}
                                                                >
                                                                    <DataGrid
                                                                        rows={tableData ? tableData : []}
                                                                        columns={columns8}
                                                                        selectionModel={selectionModel}
                                                                        // onSelectionModelChange={handleRowSelection}
                                                                    />
                                                                </div>
                                                            </Box>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="accordion mt-4"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingTwo"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    <p className="fw-bold m-0">Profile</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    PAN NO :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter Pan No"
                                                                        name="lPanNo"
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.pan_number) ||
                                                                            ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    GST NO :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter GST No"
                                                                        required
                                                                        name="lGstNo"
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.gst_number) ||
                                                                            ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    CIN NO :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        required
                                                                        placeholder="Enter CIN No"
                                                                        name="lCinNo"
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.cin_number) ||
                                                                            ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label
                                                                htmlFor="exampleFormControlInput1"
                                                                className="col-form-label"
                                                            >
                                                                Registered Address
                                                            </label>
                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleFormControlInput1"
                                                                required
                                                                placeholder="Enter Line 1"
                                                                name="laddress"
                                                                value={
                                                                    (editLenderData &&
                                                                        editLenderData.register_address &&
                                                                        editLenderData.register_address.line1) ||
                                                                    ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleFormControlInput1"
                                                                placeholder="Enter Line 2"
                                                                required
                                                                name="laddress2"
                                                                value={
                                                                    (editLenderData &&
                                                                        editLenderData.register_address &&
                                                                        editLenderData.register_address.line2) ||
                                                                    ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    State :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter State"
                                                                        required
                                                                        name="lState"
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.register_address &&
                                                                                editLenderData.register_address
                                                                                    .state) ||
                                                                            ""
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleSelectChange(event)
                                                                        }
                                                                    >
                                                                        {allState &&
                                                                            allState.map((data) => {
                                                                                return (
                                                                                    <option
                                                                                        key={data.id}
                                                                                        value={data.id}
                                                                                        label={data.name}
                                                                                    />
                                                                                );
                                                                            })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    City :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter City"
                                                                        required
                                                                        name="lCity"
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.register_address &&
                                                                                editLenderData.register_address.city) ||
                                                                            ""
                                                                        }
                                                                    >
                                                                        {allCity &&
                                                                            allCity.map((data) => {
                                                                                return (
                                                                                    <option
                                                                                        key={data.id}
                                                                                        value={data.id}
                                                                                        label={data.name}
                                                                                    />
                                                                                );
                                                                            })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    Pincode :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter PIN"
                                                                        required
                                                                        name="lPin"
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: 'This field is required.'
                                                                            },
                                                                            minLength: {
                                                                                value: 6,
                                                                                errorMessage: 'Minimum 6 character required.'
                                                                            }
                                                                        }}
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.register_address &&
                                                                                editLenderData.register_address
                                                                                    .pincode) ||
                                                                            ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            Communication Address
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label
                                                                htmlFor="exampleFormControlInput1"
                                                                className="col-form-label"
                                                            >
                                                                <input type="checkbox" name='sameAddress'
                                                                       className="ml-4"
                                                                       onClick={hanldeAddress}
                                                                       checked={handleAddressStatus}/> &nbsp;<span>Same as Registered Address</span>
                                                            </label>

                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleFormControlInput1"
                                                                placeholder="Enter Address"
                                                                required={handleAddressStatus ? false : true}
                                                                disabled={handleAddressStatus ? true : false}
                                                                name="lCommunicationAddress"
                                                                value={
                                                                    (editLenderData &&
                                                                        editLenderData.communication_address &&
                                                                        editLenderData.communication_address
                                                                            .line1) ||
                                                                    ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleFormControlInput1"
                                                                placeholder="Enter Address"
                                                                required={handleAddressStatus ? false : true}
                                                                disabled={handleAddressStatus ? true : false}
                                                                name="lCommunicationAddress2"
                                                                value={
                                                                    (editLenderData &&
                                                                        editLenderData.communication_address &&
                                                                        editLenderData.communication_address
                                                                            .line2) ||
                                                                    ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    State :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter State"
                                                                        name="lcommunicationState"
                                                                        required={handleAddressStatus ? false : true}
                                                                        disabled={handleAddressStatus ? true : false}
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.communication_address &&
                                                                                editLenderData.communication_address
                                                                                    .state) ||
                                                                            ""
                                                                        }
                                                                        onChange={(event) =>
                                                                            handleSelectChange2(event)
                                                                        }
                                                                    >
                                                                        {allState &&
                                                                            allState.map((data) => {
                                                                                return (
                                                                                    <option
                                                                                        key={data.id}
                                                                                        value={data.id}
                                                                                        label={data.name}
                                                                                    />
                                                                                );
                                                                            })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    City :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter City"
                                                                        name="lcommunicationCity"
                                                                        required={handleAddressStatus ? false : true}
                                                                        disabled={handleAddressStatus ? true : false}
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.communication_address &&
                                                                                editLenderData.communication_address
                                                                                    .city) ||
                                                                            ""
                                                                        }
                                                                    >
                                                                        {allCity2 &&
                                                                            allCity2.map((data) => {
                                                                                return (
                                                                                    <option
                                                                                        key={data.id}
                                                                                        value={data.id}
                                                                                        label={data.name}
                                                                                    />
                                                                                );
                                                                            })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    Pincode :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="staticEmail"
                                                                        placeholder="Enter PIN"
                                                                        name="lcommunicationPin"
                                                                        required={handleAddressStatus ? false : true}
                                                                        disabled={handleAddressStatus ? true : false}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: 'This field is required.'
                                                                            },
                                                                            minLength: {
                                                                                value: 6,
                                                                                errorMessage: 'Minimum 6 character required.'
                                                                            }
                                                                        }}
                                                                        value={
                                                                            (editLenderData &&
                                                                                editLenderData.communication_address &&
                                                                                editLenderData.communication_address
                                                                                    .pincode) ||
                                                                            ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Third */}
                                    <div
                                        className="accordion mt-4"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingFour"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFour"
                                                >
                                                    <p className="fw-bold m-0">Contact Details</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div
                                                                className="create-deal-btn"
                                                                style={{textAlign: "right"}}
                                                            >
                                                                <button
                                                                    className="mb-4 btn"
                                                                    type="button"
                                                                    value="Open"
                                                                    onClick={() => openModal()}
                                                                >
                                                                    Add User
                                                                </button>
                                                            </div>

                                                            <Box>
                                                                <div
                                                                    style={{
                                                                        height: 500,
                                                                        width: "100%",
                                                                        transition: "all 1.5s linear",
                                                                    }}
                                                                >
                                                                    <DataGrid
                                                                        rows={userData ? userData : []}
                                                                        columns={columns}
                                                                        selectionModel={selectionModel}
                                                                        // onSelectionModelChange={handleRowSelection}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Four */}
                                    <div
                                        className="accordion mt-4"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingFour"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFive"
                                                >
                                                    <p className="fw-bold m-0">Documents</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-12 create-deal-btn">
                                                            <button
                                                                className="btn"
                                                                style={{marginRight: "20px"}}
                                                                type="button"
                                                                onClick={() => handleDownloadData()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}
                                                            >
                                                                Download Selected Documents
                                                            </button>
                                                        </div>
                                                        {Object.keys(groupedData && groupedData).map(
                                                            (category) => {
                                                                return (
                                                                    <div key={category}>
                                                                        <div className="row">
                                                                            <div className="col-md-12 mt-5">
                                                                                <div className="mb-3 row">
                                                                                    <label
                                                                                        htmlFor="staticEmail"
                                                                                        className="col-sm-1 col-form-label"
                                                                                    >
                                                                                        Category :{" "}
                                                                                    </label>
                                                                                    <div className="col-sm-11">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="staticEmail"
                                                                                            placeholder={category}
                                                                                            disabled
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {Object.keys(groupedData[category]).map(
                                                                            (categoryId) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <table
                                                                                                    className="table mt-5 main-deal-table">
                                                                                                    <thead
                                                                                                        className="mb-3">
                                                                                                    <tr className="text-center">
                                                                                                        <th
                                                                                                            scope="col"
                                                                                                            className="data-check-input"
                                                                                                        ></th>
                                                                                                        <th scope="col">
                                                                                                            Document
                                                                                                            Name
                                                                                                        </th>
                                                                                                        <th scope="col">
                                                                                                            Status
                                                                                                        </th>
                                                                                                        <th scope="col">
                                                                                                            Lender
                                                                                                            Comments
                                                                                                        </th>
                                                                                                        <th scope="col">
                                                                                                            Kreditserve
                                                                                                            Comments
                                                                                                        </th>
                                                                                                        <th scope="col">
                                                                                                            Actions
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                    </thead>

                                                                                                    <tbody>
                                                                                                    {groupedData[category][
                                                                                                        categoryId
                                                                                                        ].map((item) => {
                                                                                                        return (
                                                                                                            <tr key={item.id}>
                                                                                                                <td>
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        id={`${item.id}|${item.document_type}`}
                                                                                                                        checked={selectedCheckboxes.includes(`${item.id}|${item.document_type}`)}
                                                                                                                        onChange={
                                                                                                                            handleCheckboxChange
                                                                                                                        }
                                                                                                                    />
                                                                                                                </td>
                                                                                                                <td>{item.title}</td>
                                                                                                                <td>{item.status}</td>
                                                                                                                <td>
                                                            <textarea
                                                                type="text"
                                                                id="lenderComment"
                                                                name="lenderComment"
                                                                className="form-control"
                                                                placeholder="Enter Lender Comment"
                                                                value={
                                                                    item
                                                                        ? item.lender_comments
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleEdit(
                                                                        item.id,
                                                                        "lender_comments",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                                                                                </td>
                                                                                                                <td>
                                                            <textarea
                                                                type="text"
                                                                id="kreditserveComment"
                                                                name="kreditserveComment"
                                                                className="form-control"
                                                                placeholder="Enter KreditServe Comment"
                                                                disabled={true}
                                                                value={
                                                                    item
                                                                        ? item.kreditserve_comments
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleEdit(
                                                                        item.id,
                                                                        "kreditserve_comments",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                                                                                </td>
                                                                                                                <td className="create-deal-btn">
                                                                                                                    {item.document ? (
                                                                                                                        <a
                                                                                                                            type="button"
                                                                                                                            href={
                                                                                                                                item.document
                                                                                                                            }
                                                                                                                            target="_blank"
                                                                                                                            title="View Document"
                                                                                                                            className="btn"
                                                                                                                            style={{
                                                                                                                                marginRight:
                                                                                                                                    "10px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye"></i>
                                                                                                                        </a>
                                                                                                                    ) : (
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn"
                                                                                                                            style={{marginRight: "10px"}}
                                                                                                                            title="Upload Document"
                                                                                                                            onClick={() =>
                                                                                                                                handleUploadData(
                                                                                                                                    item.id, item.document_type
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <i className="fa fa-upload"></i>
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                    {item.document && (
                                                                                                                        <i
                                                                                                                            className="fa fa-trash"
                                                                                                                            style={{
                                                                                                                                marginRight:
                                                                                                                                    "10px",
                                                                                                                            }}
                                                                                                                            onClick={() =>
                                                                                                                                handleDelte(
                                                                                                                                    item.id, item.document_type
                                                                                                                                )
                                                                                                                            }
                                                                                                                        ></i>
                                                                                                                    )}

                                                                                                                    {item.template && (
                                                                                                                        <a
                                                                                                                            type="button"
                                                                                                                            href={
                                                                                                                                item.template
                                                                                                                            }
                                                                                                                            target="_blank"
                                                                                                                            title="View Template"
                                                                                                                            className="btn"
                                                                                                                            style={{
                                                                                                                                marginRight:
                                                                                                                                    "10px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye"></i>
                                                                                                                        </a>
                                                                                                                    )}

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        );
                                                                                                    })}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button type="button">Submit</button> */}
                                    <div className="text-center create-deal-btn">
                                        <FormGroup className="mt-5">
                                            <Button>Submit</Button>
                                        </FormGroup>
                                    </div>
                                    {/* Fifth */}
                                </div>
                            </div>
                        </div>
                    </section>
                </AvForm>
            </main>
            <Modal
                visible={visible}
                width="1000"
                height="590"
                effect="fadeInUp"
                onClickAway={() => closeModal()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeModal()}>
                        Close
                    </a>
                </div>
                <div className="container p-3">
                    <h2 className="text-center">Add User</h2>
                    <div className="container mt-2">
                        <AvForm onValidSubmit={handleValidUserSubmit}>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Name :{" "}
                                        </label>
                                        <div className="col-sm-10">
                                            <AvField
                                                type="text"
                                                id="name"
                                                name="name"
                                                required
                                                className="form-control"
                                                placeholder="Enter Full Name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Contact Number :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                id="cNumber"
                                                name="cNumber"
                                                required
                                                className="form-control"
                                                placeholder="Enter Contact Number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Email Id :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="email"
                                                id="emailId"
                                                required
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Enter Email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Designation :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                required
                                                id="designation"
                                                name="designation"
                                                className="form-control"
                                                placeholder="Enter Designation"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-7 col-form-label"
                                        >
                                            All communications to be sent :{" "}
                                        </label>
                                        <div className="col-sm-5 d-flex">
                                            <AvRadioGroup
                                                name="all_communication_sent"
                                                required
                                                value={String(mainStatus)}
                                                onChange={handleRadioChange}
                                            >
                                                <AvRadio label="Yes" value="true"/>
                                                <AvRadio label="No" value="false"/>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4 create-deal-btn">
                                    <FormGroup>
                                        <Button>Submit</Button>
                                    </FormGroup>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showDocumentData}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeDocument()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeDocument()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">
                    <div className="container p-3">
                        <h2 className="text-center">Create New Document </h2>
                        <Select
                            options={options}
                            onInputChange={handleInputChange}
                            onChange={setSelectedDocument}
                            placeholder="Search and select a document..."
                            className="mt-5"
                        />
                        <div className="text-center mt-4 create-deal-btn">
                            <button
                                onClick={handledocumentSubmit}
                                className="btn"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showUploadDocument}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeUploadData()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeUploadData()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">
                    <h2 className="text-center">Create Upload Document </h2>
                    <input
                        type="file"
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    />
                    <div className="text-center create-deal-btn">
                        <button
                            type="submit"
                            className="mt-4 btn"
                            onClick={handleFileUpload}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={editShowStatus}
                width="1000"
                height="590"
                effect="fadeInUp"
                onClickAway={() => closeModal()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeModal()}>
                        Close
                    </a>
                </div>
                <div className="container p-3">
                    <h2 className="text-center">Edit User</h2>
                    <div className="container mt-2">
                        <AvForm onValidSubmit={handleEditValidUserSubmit}>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Name :{" "}
                                        </label>
                                        <div className="col-sm-10">
                                            <AvField
                                                type="text"
                                                id="name"
                                                name="name"
                                                required
                                                className="form-control"
                                                placeholder="Enter Full Name"
                                                value={editUserData ? editUserData.first_name : ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Contact Number :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                id="cNumber"
                                                name="cNumber"
                                                required
                                                className="form-control"
                                                placeholder="Enter Contact Number"
                                                value={
                                                    editUserData &&
                                                    editUserData.phones &&
                                                    Array.isArray(editUserData.phones)
                                                        ? editUserData.phones.map((data) => data.number)
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Email Id :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="email"
                                                id="emailId"
                                                required
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Enter Email"
                                                value={
                                                    editUserData &&
                                                    editUserData.emails &&
                                                    Array.isArray(editUserData.emails)
                                                        ? editUserData.emails.map((data) => data.email_id)
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Designation :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                required
                                                id="designation"
                                                name="designation"
                                                className="form-control"
                                                placeholder="Enter Designation"
                                                value={editUserData ? editUserData.designation : ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-7 col-form-label"
                                        >
                                            All communications to be sent :{" "}
                                        </label>
                                        <div className="col-sm-5">
                                            <AvRadioGroup
                                                name="communications_to_send"
                                                required
                                                value={String(mainStatus)}
                                                onChange={handleRadioChange}
                                            >
                                                <AvRadio label="Yes" value="true"/>
                                                <AvRadio label="No" value="false"/>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4 create-deal-btn">
                                    <FormGroup>
                                        <Button>Submit</Button>
                                    </FormGroup>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showUploadTemplate}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeUploadTemplate()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeUploadTemplate()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">
                    <h2 className="text-center">Create Upload Template </h2>
                    <input
                        type="file"
                        name="uploadTemplate"
                        className="form-control"
                        onChange={(e) => {
                            setUploadTemplate(e.target.files[0]);
                        }}
                    />
                    <div className="text-center">
                        <button
                            type="submit"
                            className="mt-4 btn btn-success"
                            onClick={handleFileUploadTemplate}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </Modal>
        </Layout>
    );
};
export default LenderProfile;
