import api from "../app-config/axiosConfig";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// 1. Create Duplicate Deal
const createDuplicate = (duplicateId) => {
    api.get(`marketkredit/api/v1/deal/${duplicateId}/duplicate/`)
      .then(
        (res) => {
          toast("Duplicate Successfully");
          window.location.reload();
        },
        (err) => {
          toast.error(err.message);
        }
      );
  };

// 2. Deactivate Deal
const deactivateDeal = (deactivateId) => {
    api.get(`marketkredit/api/v1/deal/${deactivateId}/deactivate/`)
      .then(
        (res) => {
          toast("Deactivate Successfully");
          window.location.reload();
        },
        (err) => {
          toast.error(err.message);
        }
      );
  };  

// 3. Add Product
const createProduct = (values) => {
    const payload = {
      name: values.newProduct,
    };
    api.post(`marketkredit/api/v1/product/`, payload)
      .then((res) => {
        if (res.status) {
          toast("Product Created Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.detail);
      });
  };
  

  export {createDuplicate, deactivateDeal,createProduct}
  