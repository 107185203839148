import axios from 'axios';

// Utility functions for token management
const getToken = () => localStorage.getItem('access');
const setToken = token => localStorage.setItem('access', token);
const getRefreshToken = () => localStorage.getItem('refresh');

// Create an Axios instance
const api = axios.create({ baseURL: 'https://mkdev.kreditserve.com/' });

// Request interceptor to attach the token
api.interceptors.request.use(request => {
  const token = getToken();
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
    request.headers['Content-Type'] = 'application/json';
  }
  return request;
});

// Response interceptor for token refresh logic
api.interceptors.response.use(response => response, async error => {
  const originalRequest = error.config;

  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = getRefreshToken();

    try {
      // Send request to the refresh token endpoint
      const response = await axios.post('https://mkdev.kreditserve.com/authenticator/api/token/refresh/', { refresh: refreshToken });
      const newAccessToken = response.data.access; // Assuming the new token is returned under the key 'access'

      setToken(newAccessToken);

      // Update the header with the new token and retry the original request
      originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      return api(originalRequest);
    } catch (refreshError) {
      // Handle failed refresh (e.g., redirect to login)
    }
  }

  return Promise.reject(error);
});

export default api;
