import React from 'react';

const LogoutOverlay = () =>{
    const handleLogout = () =>{
        localStorage.clear()
        window.location.reload()
        window.location.replace('/')
    }
    return(
        <>
            <div className="empty-data-overlay">
                <div className="empty-data-message">
                    <h2>Access Denied</h2>
                    <p>You do not have permission to access this resource.<br/>
                     Please contact the administrator for assistance.
                    </p>
                    <button onClick={handleLogout} className='btn edit-btn'>Logout</button>
                </div>
            </div>
        </>
    )
}
export default LogoutOverlay