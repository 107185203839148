import React, {useState, useEffect} from "react";
import '../../global.css';
import Logo from '../../assets/img/logo.png';
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from '../../assets/img/profileuser.png';
import {Link} from "react-router-dom";


const Navbar = (props) => {
    const [isToggled, setToggled] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState()
    const name = localStorage.getItem("name")
    const userType = localStorage.getItem('user_type')

    const toggle = () => {
        const newValue = !isToggled;
        setToggled(newValue);
        props.onToggle(newValue);
    };
    useEffect(() => {
        let redirectUrl = '';
        switch (userType) {
            case 'admin':
                redirectUrl = '/dashboard';
                break;
            case 'borrower':
                redirectUrl = '/borrower-deal-management';
                break;
            default:
                redirectUrl = '/lender-deal-management';
        }
    
        setRedirectUrl(redirectUrl);
    }, [userType]);
    const logout = () => {
        localStorage.clear()
        toast("Logout Successfully");
        setTimeout(() => {
            window.location.replace("/");
        }, 1000);
    }

    return (
        <header
            id="header"
            className="header fixed-top d-flex align-items-center"
        >
            <ToastContainer/>
            <div className="d-flex align-items-center justify-content-between">
                <Link
                    to={`${redirectUrl}`}
                    className="logo d-flex align-items-center text-decoration-none"
                >
                    <img src={Logo} alt=""/>
                    <span className="d-none d-lg-block">MarketKredit</span>
                </Link>
                <i className="bi bi-list toggle-sidebar-btn" onClick={toggle}/>
            </div>
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <li className="nav-item dropdown pe-3">
                        <button
                            className="nav-link nav-profile d-flex align-items-center pe-0"
                            data-bs-toggle="dropdown"
                        >
                            <img src={Profile} alt="Profile" className="rounded-circle"/>
                            <span className="d-none d-md-block dropdown-toggle ps-2">
                  {name}
                </span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{name}</h6>
                                <span>{userType}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li>
                                <button
                                    className="dropdown-item d-flex align-items-center"
                                    onClick={() => logout()} style={{cursor: 'pointer'}}
                                >
                                    <i className="bi bi-box-arrow-right"/>
                                    <span>Sign Out</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
    );
};
export default Navbar;