import React, {useEffect} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {APP_CONFIGURATION} from "../../../app-config/configuration";
import {useHistory} from 'react-router-dom';
import api from '../../../app-config/axiosConfig';

const Login = () => {
    const history = useHistory();
    const initialValues = {
        username: "", password: "",
    };

    useEffect(() => {
        const user = localStorage.getItem('user_type')
        if (user === 'admin') {
            history.push('/dashboard')
        }
    }, [])

    const validationSchema = Yup.object().shape({
        username: Yup.string().required("Username is required"), password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
    });

    const handleSubmit = async ({username, password}) => {
        try {
            const payload = {username, password};
            const res = await api.post(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/user/login/`, payload);
            if (res.status) {
                localStorage.setItem("access", res.data.access);
                localStorage.setItem("refresh", res.data.refresh);
                localStorage.setItem("name", res.data.user.first_name);
                localStorage.setItem("user_type", res.data.user.user_type);
                localStorage.setItem("user_id", res.data.user.object_id);
                toast("Login Successfully");
                window.location.replace('/dashboard')
            }
        } catch (err) {
            toast(err.response.data.error[0].error);
        }
    };

    return (<>
        <main>
            <ToastContainer/>
            <div className="container">
                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-6 d-flex flex-column justify-content-center">
                                <div className="d-flex justify-content-center py-4">
                                    <a
                                        href="javascript:void(0);"
                                        className="logo d-flex align-items-center w-auto"
                                    >
                                        <span className="d-none d-lg-block">MarketKredit</span>
                                    </a>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">
                                                Login to Your Account
                                            </h5>
                                            <p className="text-center small">
                                                Enter your username &amp; password to login
                                            </p>
                                        </div>
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSubmit}
                                            className="row needs-validation"
                                        >
                                            {() => (<Form>
                                                <div className="col-12">
                                                    <label
                                                        htmlFor="yourUsername"
                                                        className="form-label mt-2"
                                                    >
                                                        Username
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        id="username"
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Enter Username"
                                                    />
                                                    <ErrorMessage
                                                        name="username"
                                                        component="div"
                                                        className="error mt-2"
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <label
                                                        htmlFor="yourPassword"
                                                        className="form-label mt-3"
                                                    >
                                                        Password
                                                    </label>
                                                    <Field
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                    />
                                                    <ErrorMessage
                                                        name="password"
                                                        component="div"
                                                        className="error mt-2"
                                                    />
                                                </div>
                                                <div className="col-12 create-deal-btn">
                                                    <button type="submit" className="mt-4 w-100 btn">
                                                        Login
                                                    </button>
                                                </div>
                                            </Form>)}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </>);
};
export default Login;
