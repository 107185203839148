import React from "react";
import "../../global.css";
import {Link, useLocation} from 'react-router-dom';
import {useContext} from "react";
import {PermissionsContext} from "../../../context";

const Sidebar = (props) => {
    const {permissions} = useContext(PermissionsContext)
    const UserType = localStorage.getItem('user_type')
    const location = useLocation()
    const url = location.pathname
    const userType = localStorage.getItem('user_type')
    const sideBarData = []


    if ((userType === 'admin') && (permissions && permissions.view_document_master)) {
        const newItem = {
            "label": "Document Management",
            "icon": "bi bi-dash-circle",
            "link": "/document-management",
            "role": [
                "admin"
            ]
        }
        sideBarData.push(newItem)
    }
    if (userType === 'admin' && (permissions && permissions.view_deal)) {
        const newItem = {
            "label": "Deal Management",
            "icon": "bi bi-grid",
            "link": "/dashboard",
            "role": [
                "admin"
            ]
        }
        sideBarData.push(newItem)
    }
    if (userType === 'borrower') {
        const newItem = {
            "label": "Deal Management",
            "icon": "bi bi-grid",
            "link": "/dashboard",
            "role": [
                "borrower"
            ]
        }
        sideBarData.push(newItem)
    }
    if (userType === 'lender') {
        const newItem = {
            "label": "Deal Management",
            "icon": "bi bi-grid",
            "link": "/dashboard",
            "role": [
                "lender"
            ]
        }
        sideBarData.push(newItem)
    }
    if (userType === 'borrower') {
        const newItem = {
            "label": "My Profile",
            "icon": "bi bi-dash-circle",
            "link": "/borrower-profile",
            "role": [
                "borrower"
            ]
        }
        sideBarData.push(newItem)
    }
    if (userType === 'lender') {
        const newItem = {
            "label": "My Profile",
            "icon": "bi bi-dash-circle",
            "link": "/lender-profile",
            "role": [
                "lender"
            ]
        }
        sideBarData.push(newItem)
    }

    const menuData = sideBarData.filter(data => data.role.find(res => res === userType)).map(res => res);
    // console.log('mendu ', menuData)

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-heading">Navigation Options</li>
                {menuData && menuData.map((item) => {
                    return (
                        <li className="nav-item">
                            <Link className={`nav-link ${url === item.link ? '' : 'collapsed'}`} to={item.link}>
                                <i className={item.icon}/>
                                <span>{item.label}</span>
                            </Link>
                        </li>
                    )
                })}
                {/* <li className="nav-item">
            <Link className={`nav-link ${url === '/document-management'? '':'collapsed'}`} to='/document-management'>
              <i className="bi bi-dash-circle" />
              <span>Document Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${url === '/dashboard'? '':'collapsed'}`} to='/dashboard'>
              <i className="bi bi-grid" />
              <span>Deal Management</span>
            </Link>
          </li> */}
            </ul>
        </aside>
    );
};
export default Sidebar;