import React, {useEffect, useRef, useState} from "react";
import Layout from "../../page-layouts/Layout/Layout";
import Modal from "react-awesome-modal";
import "../../global.css";
import Select from 'react-select';
import * as Yup from "yup";

import {AvCheckbox, AvCheckboxGroup, AvField, AvForm, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {Button, FormGroup} from "reactstrap";
import {toast, ToastContainer} from "react-toastify";
import {APP_CONFIGURATION} from "../../../app-config/configuration";
import "react-toastify/dist/ReactToastify.css";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";
import {DataGrid} from '@mui/x-data-grid';
import api from '../../../app-config/axiosConfig'


const BorrowerProfile = (props) => {
    const [visible, setVisible] = useState(false);
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [documentUploadId, setDocumentUploadId] = useState("")
    const [duplicateId, setDuplicateId] = useState("");
    const [selectionModel, setSelectionModel] = useState([]);
    const [showDeactivate, setShowDeactivate] = useState(false);
    const [documentTypeId, setDocumentType] = useState('')
    const [deactivateId, setDeactivateId] = useState("");
    const [options, setOptions] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [mainData, setMainData] = useState();
    const [editData, setEditData] = useState();
    const [mainStatus, setMainStatus] = useState()
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [showDocumentData, setShowDocumentData] = useState(false);
    const [mainFile, setFile] = useState(null);
    const formEl = useRef(null);
    const [documentList, setDocumentList] = useState()
    const [showUploadDocument, setShowUploadDocument] = useState(false)
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editReurrenceShow, setEditRecurrenceShow] = useState(false)
    const [editRecurrenceId, setEditRecurrenceId] = useState()
    const [urlId, setUrlId] = useState();
    const [showUploadTemplate, setShowUploadTemplate] = useState(false)
    const [editUserId, setEditUserId] = useState('');
    const [uploadTemplateId, setUploadTemplateId] = useState("")
    const [editShowStatus, setEditShowStatus] = useState(false)
    const [editUserData, setEditUserData] = useState()
    const [removeId, setRemoveId] = useState('')
    const [mainRecurrence, setRecurrence] = useState()
    const [allCity, setCityData] = useState()
    const [primaryUser, setPrimaryUser] = useState()
    const [allState, setStateData] = useState()
    const [cityId, setCityId] = useState()
    const [cityId2, setCityId2] = useState()
    const [allCity2, setCityData2] = useState()
    const [handleAddressStatus, setHandleAddressStatus] = useState(false)
    const [checked, setChecked] = useState(false)
    const borrowerId = localStorage.getItem('user_id')

    const handleEditUser = (id) => {
        setEditShowStatus(true)
        setEditUserId(id)
        api
            .get(`https://mkdev.kreditserve.com/marketkredit/api/v1/user/${id}/`, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res) {
                    setMainStatus(res.data.data.communications_to_send)
                    setEditUserData(res.data.data)
                }
            }, (err) => {
                // toast.error(err.message);
            });
    }

    const handleCheckboxChange = (event) => {
        const checkboxId = event.target.id;
        setRemoveId(event.target.id)
        const checkedData = ((prevSelected) => {
            if (event.target.checked) {
                return [...prevSelected, checkboxId];
            } else {
                return prevSelected.filter((id) => id !== checkboxId);
            }
        })

        setSelectedCheckboxes(checkedData)
    };


    const [userData, setUserData] = useState()
    const [showDocument, setShowDocument] = useState()
    const [editDocument, setEditDocument] = useState([{
        document_id: "", borrower_comments: "", kreditserve_comments: "", lender_comments: "",
    },]);
    const [pageState, setPageState] = useState({
        isLoading: false, data: [], total: 0, page: 1, pageSize: 10,
    });
    const openModal = () => {
        setVisible(true);
    };
    const closeModal = () => {
        setVisible(false);
        setEditShowStatus(false)
    };

    const columns = [{
        field: "first_name",
        headerName: "Name",
        width: 325,
        renderCell: (params) => <div>{params.row.first_name}</div>
    }, {
        field: "phoneNumber",
        headerName: "Phone Number",
        width: 325,
        renderCell: (params) => <div>{params.row.phones.map((data) => {
            return data.number
        })}
        </div>
    }, {
        field: "EmailId",
        headerName: "emailId",
        width: 320,
        renderCell: (params) => <div>{params.row.emails.map((data) => {
            return data.email_id
        })}
        </div>
    }, {
        field: "actions", headerName: "Actions", width: 350, renderCell: (params) => (<div>
            <button className="btn edit-btn" type="button" onClick={() => handleEditUser(params.row.id)}
                    style={{marginRight: '10px'}}>
                Edit
            </button>

            {/* <button className="btn btn-warning text-white ml-3">Deactivate</button> */}
        </div>),
    },];
    // const fetchDocuments = async (searchQuery = "") => {
    //     const response = await api.get(
    //         "https://mkdev.kreditserve.com/marketkredit/api/v1/document/master_list_document/",
    //         {
    //             params: {
    //                 search: searchQuery,
    //                 master_page_number: 1,
    //                 master_page_size: 5,
    //             },
    //             headers: {
    //                 Authorization: "Bearer " + localStorage.getItem("access"),
    //                 "Content-Type": "application/json",
    //             },
    //         }
    //     );
    //     const handleEditValidUserSubmit = (e, values) => {
    //         const payload = {
    //             first_name: values.name,
    //             contactNumber: values.cNumber,
    //             email: values.emailId,
    //             designation: values.designation,
    //             communicationsToBeSent: mainStatus,
    //         };
    //         api
    //             .patch(
    //                 `https://mkdev.kreditserve.com/marketkredit/api/v1/user/${editUserId}/`,
    //                 payload,
    //                 {
    //                     headers: {
    //                         Accept: "*/*",
    //                         "Content-Type": "application/json",
    //                         Authorization: "Bearer " + localStorage.getItem("access"),
    //                     },
    //                 }
    //             )
    //             .then(
    //                 (res) => {
    //                     toast("User Edit Successfully");
    //                     closeModal();
    //                     window.location.reload();
    //                 },
    //                 (err) => {
    //                     // alert('fail')
    //                 }
    //             );
    //     };
    //     const documents = response.data.data.map((doc) => ({
    //         value: doc.id,
    //         label: doc.name,
    //     }));
    //     const userInputOption = searchQuery.trim()
    //         ? [{value: '', label: `${searchQuery}`}]
    //         : [];
    //     setOptions([...documents, ...userInputOption]);
    // };
    const fetchDocuments = async (searchQuery = "") => {
    try {
        const response = await api.get(
            "https://mkdev.kreditserve.com/marketkredit/api/v1/document/master_list_document/",
            {
                params: {
                    search: searchQuery,
                    master_page_number: 1,
                    master_page_size: 5,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json",
                },
            }
        );

        const documents = response.data.data.map((doc) => ({
            value: doc.id,
            label: doc.name,
        }));
        const userInputOption = searchQuery.trim()
            ? [{value: '', label: `${searchQuery}`}]
            : [];
        setOptions([...documents, ...userInputOption]);
    } catch (error) {
        console.error("Error fetching documents:", error);
        // Handle error here, e.g., show a message to the user
    }
};

    const handleInputChange = (newValue) => {
        fetchDocuments(newValue);
    };
    const [documentInput, setDocumentInput] = useState('');


// Function to handle document input change and make API call
    const handleDocumentInputChange = async (inputValue) => {
        setDocumentInput(inputValue);

        try {
            if (inputValue.trim() === '') {
                // If input is empty, fetch all documents
                const response = await api.get('https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/', {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem('access')
                    }
                });
                const modifiedData = [...response.data.data, // Add your custom object here
                    {id: '', name: inputValue, category: {id: 'categoryId', name: 'categoryName'}}];

                const mappedData = response.data.map((document) => ({
                    value: document.id, label: document.name,
                }));

                setDocumentList(Array.isArray(mappedData) ? mappedData : []);
            } else {
                // If input is not empty, fetch filtered documents
                const response = await api.get(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/list_document/?search=${inputValue}`, {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem('access')
                    }
                });
                const modifiedData = [...response.data.data, // Add your custom object here
                    {id: '', name: inputValue, category: {id: 'categoryId', name: 'categoryName'}}];

                const mappedData = response.data.map((document) => ({
                    value: document.id, label: document.name,
                }));

                setDocumentList(Array.isArray(mappedData) ? mappedData : []);
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
            // Handle error as needed
        }
    };

    const handleDocumentSelect = (selectedOption) => {
        setSelectedDocument(selectedOption);
        if (selectedOption) {
            // Your API call using selectedOption.value and selectedOption.label
            console.log('Selected Document:', selectedOption);
        }
    };

    useEffect(() => {
        fetchDocuments();
        getAllPrimaryUser()
        // getAllDealBorrowerData(urlId)
    }, [urlId]);

    useEffect(() => {
        setUrlId(borrowerId);
        api
            .get(`https://mkdev.kreditserve.com/marketkredit/api/v1/borrower/${borrowerId}/`, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res) {
                    // setEditBorrowerData(res.data.data)
                    // setUserTable(res.data.data)
                    if (res.data.data.register_address != null) {
                        setCityId(res.data.data.register_address.state)
                    }
                    if (res.data.data.communication_address != null) {
                        setCityId2(res.data.data.communication_address.state)
                    }


                    setEditData(res.data.data);
                    setHandleAddressStatus(res.data.data.communication_address_same_as_register_address)
                    const doc = res && res.data.data.borrower_documents.map((item) => {
                        return {
                            id: item.id,
                            borrower_comments: item.borrower_comments,
                            kreditserve_comments: item.kreditserve_comments,
                            lender_comments: "",
                            document: item.document.file,
                            title: item.document.title,
                            category: item.document.type.category,
                            categoryId: item.document.type.category,
                            template: item.document.template,
                            document_type: item.document.type.document_type,
                            status: item.status

                        };
                    });
                    setData(doc);
                    const recurrence = res && res.data.data.borrower_documents.map((item) => {
                        return {
                            id: item.id,
                            is_recurring: item.is_recurring,
                            recurring_days: item.recurring_days,
                            title: item.document.title
                        };
                    });
                    setRecurrence(recurrence)
                }
            }, (err) => {
                // toast.error(err.message);
            });
        api.get(`https://mkdev.kreditserve.com/marketkredit/api/v1/user/list_agent/borrower/?object_id=${borrowerId}`, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('access')
            }
        }).then((res) => {

            setUserData(res.data.data)

        }, (err) => {
            // alert('fail')
        });

    }, []);
    const closeDuplicateModal = () => {
        setShowDuplicate(false);

    };

    // Borrower Details
    const initialBorrowerDetailValues = {
        borrowerName: "", primaryUser: "", panNo: "",
    };
    useEffect(() => {
        getAllState()
    }, [])

    const validationBorrowerDetailSchema = Yup.object().shape({
        borrowerName: Yup.string().required("Borrower Name is required"),
        primaryUser: Yup.string().required("Primary User is required"),
        panNo: Yup.string().required("PAN No is Required"),
    });

    const handleBorrowerDetailSubmit = (values) => {
        console.log("Form data submitted:", values);
    };

    // const initialProfileDetailsValues = {
    //     panNo: "",
    //     gstNo: "",
    //     cinNo: "",
    //     registeredAddress: "",
    //     city: "",
    //     state: "",
    //     pin: "",
    //     communicationAddress: "",
    //     mCity: "",
    //     mState: "",
    //     mPin: "",
    // };

    // const validationProfileDetailsSchema = Yup.object().shape({
    //     panNo: Yup.string().required("PAN No is required"),
    //     gstNo: Yup.string().required("GST No is required"),
    //     cinNo: Yup.string().required("PCIN No is Required"),
    //     registeredAddress: Yup.string().required("Registered Address is Required"),
    //     city: Yup.string().required("City is Required"),
    //     state: Yup.string().required("State is Required"),
    //     pin: Yup.string().required("PIN is Required"),
    //     communicationAddress: Yup.string().required("Communication Address is Required"),
    //     mCity: Yup.string().required("City is Required"),
    //     mState: Yup.string().required("State is Required"),
    //     mPin: Yup.string().required("PIN is Required"),
    // });

    // const initialAddUserValues = {
    //     name: "", cNumber: "", emailId: "", designation: "", communicationSent: [],
    // };

    // const validationAddUserSchema = Yup.object().shape({
    //     name: Yup.string().required("Name is required"),
    //     cNumber: Yup.string().required("Contact Number is required"),
    //     emailId: Yup.string().required("Email is required"),
    //     designation: Yup.string().required("Designation is required"),
    //     communicationSent: Yup.array().min(1, "At least one select one File Format checkbox must be selected"),
    // });
    const handleAddUserSubmit = (values) => {
        const payload = {
            first_name: values.name,
            phone_number: values.cNumber,
            email_address: values.emailId,
            designation: values.designation,
        };
        let url = "marketkredit/api/v1/user/create_user/<str:user_type>/<uuid:content_object_id>";
        api
            .post(url, payload, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            .then((res) => {
                if (res) {
                } else {
                }
            }, (err) => {
                // this.setState({ loading: false });
                // toast['error']((err.response.data.message !== '' ? err.response.data.message : 'Something went wrong'))
            });

        console.log("Form data submitted:", values);
    };
    const columns8 = [{
        field: "city", headerName: "Deal Name", width: 400, renderCell: (params) => (<div>

            {params.row.deal_name}

        </div>),
    }, {
        field: "borrower", headerName: "Borrower", width: 200, renderCell: (params) => (<div>
            {" "}

            {params.row.borrower ? params.row.borrower["borrower_name"] : ""}

        </div>),
    },

        {
            field: "dealValue",
            headerName: "Deal Value",
            width: 200,
            renderCell: (params) => (<div>{params.row && params.row.credit_requirement}</div>),
        }, {
            field: "prefferedProductType",
            headerName: "Preffered Product Type(s)",
            width: 180,
            renderCell: (params) => <div>{params.row.product.name}</div>
        }, {
            field: "lender", headerName: "Lender", width: 100, renderCell: (params) => (<div>
                {" "}

                {params.row.lender.lender_name}

            </div>),
        }, {
            field: "dealStatus",
            headerName: "Deal Status",
            width: 100,
            renderCell: (params) => <div>{params.row.deal_status}</div>
        }, {
            field: "rm",
            headerName: "RM",
            width: 200,
            renderCell: (params) =>
                <div>{params.row.relationship_manager ? params.row.relationship_manager.first_name : ''}</div>
        }, {
            field: "boAgent",
            headerName: "BO Agent",
            width: 200,
            renderCell: (params) => <div>{params.row.bo_agent.first_name}</div>
        },
        // {
        //     field: "panNo", headerName: "Pan No", width: 200, renderCell: (params) => (<div>
        //         {params.row.borrower ? params.row.borrower["pan_number"] : ""}
        //     </div>),
        // },

        {
            field: "actions", headerName: "Actions", width: 300, renderCell: (params) => (<div>
                <button className="btn edit-btn">
                    <Link className="nav-link " to={`/borrower-deal-setup/${params.id}`}>
                        View
                    </Link>
                </button>
                {/* <button
                    className="btn duplicate-btn"
                    style={{marginLeft: "10px", marginRight: "10px"}}
                    onClick={() => handleDuplicate(params.id)}
                >
                    Duplicate
                </button> */}
                {/* <button
                    className="btn deactivate-btn"
                    onClick={() => handleDeactive(params.id)}
                >
                    Deactivate
                </button> */}
            </div>),
        },];
    const handleDuplicate = (id) => {
        setDuplicateId(id);
        setShowDuplicate(true);
    };
    const handleDeactive = (id) => {
        setShowDeactivate(true);
        setDeactivateId(id);
    };

    const handleValidSubmit = (e, values) => {
        const payloadData = handleAddressStatus ? {
            borrower_name: values.borrowerName,
            pan_number: values.panNom,
            cin_number: values.cinNo,
            gst_number: values.gstNo,
            register_address: {
                line1: values.registeredAddress,
                line2: values.registeredAddress2,
                city: values.city,
                state: values.state,
                pincode: values.pin,
            },
            communication_address_same_as_register_address: handleAddressStatus,
            primary_user: values.primaryUser,
            documents: data,
        } : {
            borrower_name: values.borrowerName,
            pan_number: values.panNom,
            cin_number: values.cinNo,
            gst_number: values.gstNo,
            register_address: {
                line1: values.registeredAddress,
                line2: values.registeredAddress2,
                city: values.city,
                state: values.state,
                pincode: values.pin,
            },
            communication_address: {
                line1: values.communicationAddress,
                line2: values.communicationAddress,
                city: values.mCity,
                state: values.mState,
                pincode: values.mPin,
            },
            communication_address_same_as_register_address: handleAddressStatus,
            primary_user: values.primaryUser,
            documents: data,
        };
        api
            .patch(`https://mkdev.kreditserve.com/marketkredit/api/v1/borrower/${urlId}/`, payloadData, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Borrower Edit Successfully");
                    setTimeout(() => {
                        window.location.reload()
                        // window.location.replace("/borrower-deal-management");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.error);
            });
    };
    useEffect(() => {

        const url = `https://mkdev.kreditserve.com/marketkredit/api/v1/deal/list_deals/borrower/${urlId}/`
        console.log('list deals url', url)
        if (urlId) {
            setPageState((old) => ({...old, isLoading: true}));
            api.get(url, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
                .then((res) => {
                    if (res) {
                        // setTableData(res.data.data);
                        setPageState((old) => ({
                            ...old, isLoading: false, data: res.data.data, total: 20,
                        }));
                    }
                }, (err) => {
                });
        }
    }, [pageState.page, pageState.pageSize, urlId]);

    const handleValidUserSubmit = (e, values) => {
        const payload = {
            first_name: values.name,
            phone_number: values.cNumber,
            email_address: values.emailId,
            designation: values.designation,
            communications_to_send: mainStatus,
        };
        api.post(`https://mkdev.kreditserve.com/marketkredit/api/v1/user/create_user/borrower/${urlId}/`, payload, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem("access"),
            },
        }).then((res) => {
            if (res.status) {
                toast("User Created Successfully");
                setTimeout(() => {
                    closeModal()
                    window.location.reload()
                }, 1000);
            }
        })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    useEffect(() => {
        const status = editUserData ? editUserData.communications_to_send : false;
        setMainStatus(status)
    }, [])

    const [data, setData] = useState([]);
    const handleEdit = (id, field, value) => {

        const newData = data.map((item) => item.id === id ? {...item, [field]: value} : item);
        setData(newData);
    };

    const handleUnShare = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Borrower"};
        });
        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/unshare_borrower/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Borrower UnShare Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleShare = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Borrower"};
        });
        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/share_borrower/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Borrower Share Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleShareWithLender = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Borrower"};
        });
        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/share_lender/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Lender UnShare Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };
    const handleUnShareWithLender = () => {
        let arrayOfObjects = selectedCheckboxes.map(function (str) {
            return {id: str, document_type: "Borrower"};
        });
        const payload = {
            documents: arrayOfObjects
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/unshare_lender/`, payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Lender UnShare Successfully");
                    setTimeout(() => {
                        // window.location.replace("/dashboard");
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const submitDuplicate = () => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .get(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/deal/${duplicateId}/duplicate/`, {headers: headersParam}
            ).then(
            (res) => {
                toast("Duplicate Successfully");
                closeDuplicateModal();
                window.location.reload();
            },
            (err) => {
                toast.error(err.message);
            }
        );
    };

    const handleDocument = () => {
        setShowDocument(true)
        setShowDocumentData(true);
    }
    const closeDocument = () => {
        setShowDocument(false)
        setShowDocumentData(false);
    }

    const handledocumentSubmit = (values) => {
        const payload = {
            entity_id: urlId,
            document: {
                id: selectedDocument.value,
                name: selectedDocument.label,
                document_type: "Borrower"
            }
        }
        console.log('payload ', payload)
        api
            .post("https://mkdev.kreditserve.com/marketkredit/api/v1/document/create_document/", payload, {
                headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Document Created Successfully");
                    setTimeout(() => {
                        closeDocument();
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                // toast.error(err.response.data.detail);
            });

    }

    const getAllPrimaryUser = () => {
        api
            .get(`https://mkdev.kreditserve.com/marketkredit/api/v1/user/list_agent/borrower/?object_id=${borrowerId}`, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                setPrimaryUser(res.data.data)
            }, (err) => {
                // alert('fail')
            });
    }
    useEffect(() => {
        if (cityId) {
            api
                .get(`https://mkdev.kreditserve.com/marketkredit/api/v1/city/?state_id=${cityId}`, {
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                })
                .then((res) => {
                    // let cityData = res.data.data;
                    // cityData = cityData.map((data) => {
                    //     return {...data, key: `${data.borrower_name}`, cat: `${data.borrower_name}`};
                    // });
                    setCityData(res.data.data)
                }, (err) => {
                    // alert('fail')
                });
        }
        if (cityId2) {
            api
                .get(`https://mkdev.kreditserve.com/marketkredit/api/v1/city/?state_id=${cityId2}`, {
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                })
                .then((res) => {
                    setCityData2(res.data.data)
                }, (err) => {
                    // alert('fail')
                });
        }

    }, [cityId, cityId2])
    const getAllState = () => {
        api
            .get("https://mkdev.kreditserve.com/marketkredit/api/v1/state/", {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                setStateData(res.data.data)
            }, (err) => {
                // alert('fail')
            });
    }


    const handleUploadData = (id, documentType) => {
        setDocumentUploadId(id)
        setDocumentType(documentType)
        setShowUploadDocument(true)
    }
    const closeUploadData = () => {
        setShowUploadDocument(false)
    }

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };
    const handleUpload = () => {
        if (selectedFile) {
            let formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('document_type', "Borrower");
            api
                .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_template/${documentUploadId}/`, formData, {
                    headers: {
                        "Content-Type": "application/json", Authorization: "Bearer " + localStorage.getItem("access"),
                    },
                })
                .then((res) => {
                    if (res.status) {
                        toast("Upload Document Successfully");
                        setTimeout(() => {
                            closeDocument();
                            window.location.reload()
                        }, 1000);
                    }
                })
                .catch((err) => {
                    // toast.error(err.response.data.detail);
                });
        }
    };
    const handleFileUpload = () => {
        const formData = new FormData();
        formData.append('file', mainFile);
        formData.append("document_type", documentTypeId);
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_document/${documentUploadId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Upload Document Successfully");
                    setTimeout(() => {
                        closeUploadData()
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    };
    const handleUploadTemplate = (id) => {
        setShowUploadTemplate(true)
        setUploadTemplateId(id)
    };
    const closeUploadTemplate = () => {
        setShowUploadTemplate(false)
    }
    const handleFileUploadTemplate = () => {
        const formData = new FormData();
        formData.append('file', mainFile);
        formData.append("document_type", "Borrower");
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/upload_template/${uploadTemplateId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Upload Template Successfully");
                    setTimeout(() => {
                        closeUploadTemplate()
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.error);
            });
    }

    const handleRemoveDocument = () => {
        const payload = {
            document_type: "Borrower"
        }
        api
            .post(`https://mkdev.kreditserve.com/marketkredit/api/v1/document/remove_document/${removeId}/`, payload, {
                headers: {
                    "Content-Type": "multipart/form-data", Authorization: "Bearer " + localStorage.getItem("access"),
                },
            })
            .then((res) => {
                if (res.status) {
                    toast("Delete Document Successfully");
                    setTimeout(() => {
                        // closeUploadTemplate()
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.response);
            });
    }
    const handleDownloadData = () => {
        const arrayOfObjects = selectedCheckboxes.map(item => {
            const [id, document_type] = item.split('|');
            return {id, document_type};
        });
        api({
            url: 'https://mkdev.kreditserve.com/marketkredit/api/v1/document/download_documents_bulk/',
            method: 'POST',
            responseType: 'blob',
            data: {documents: arrayOfObjects},
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access")
            },
        })
            .then(response => {
                const borrowerName = editData && editData.borrower_name ? editData.borrower_name : "Borrower";
                const timestamp = new Date().toISOString().replace(/[\-\:\.T]/g, '').slice(0, 14); // Format: yyyyMMddHHmmss

                let filename = `${borrowerName.replace(/\s+/g, '_')}-${timestamp}.zip`; // Constructed filename

                console.log("Response Headers:", response.headers); // Log the headers for diagnostic purposes

                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const filenameMatch = /filename="([^"]+)"/.exec(contentDisposition);
                    if (filenameMatch && filenameMatch[1]) {
                        filename = filenameMatch[1];
                        console.log("Extracted filename:", filename); // Log the extracted filename
                    } else {
                        console.log("Filename extraction failed. Content-Disposition:", contentDisposition);
                    }
                } else {
                    console.log("No Content-Disposition header found.");
                }

                // Create a Blob from the response
                const file = new Blob([response.data], {type: 'application/zip'});
                // Create an anchor element and set the URL
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', filename);
                // Append to the DOM and trigger click
                document.body.appendChild(link);
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
                URL.revokeObjectURL(fileURL);
            })
            .catch(error => {
                console.error('Download error:', error);
            });
    }


    const handleEditValidUserSubmit = (e, values) => {
        const payload = {
            first_name: values.name,
            phone_number: values.cNumber instanceof Array ? values.cNumber[0] : values.cNumber,
            email_address: values.emailId instanceof Array ? values.emailId[0] : values.emailId,
            designation: values.designation,
            communications_to_send: mainStatus,
        };
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.patch(`https://mkdev.kreditserve.com/marketkredit/api/v1/user/${editUserId}/`, payload, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                }
            }
        ).then(
            (res) => {
                toast("User Edit Successfully");
                closeModal()
                window.location.reload()
            },
            (err) => {
                // alert('fail')
            }
        );
    }
    const [editUserDataMain, setEditUserDataMain] = useState(editUserData && editUserData.communications_to_send);

    const [selectedValue, setSelectedValue] = useState(mainStatus);

    const handleRadioChange = (event) => {
        const newValue = event.target.value === "true";
        setMainStatus(newValue);
    };
    const documentDam = editData && editData.borrower_documents.map((sa) => {
        return sa.document.file
    })
    const [selectedOption, setSelectedOption] = useState(null);

    const groupedData = data.reduce((acc, item) => {
        const {category, categoryId} = item;
        if (!acc[category]) {
            acc[category] = {};
        }
        if (!acc[category][categoryId]) {
            acc[category][categoryId] = [];
        }
        acc[category][categoryId].push(item);
        return acc;
    }, {});

    const handleDelte = (id, docType) => {
        const payload = {
            "document_type": docType
        }
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api.post(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/remove_document/${id}/`, payload, {headers: headersParam}).then((res) => {
            if (res.status) {
                toast("Document Deleted Successfully");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    const closeEditRecurrence = () => {
        setEditRecurrenceShow(false)
    }

    const editRecurrence = (id) => {
        setEditRecurrenceShow(true)
        setEditRecurrenceId(id)
    }
    const handleCheckboxChangeMain = () => {
        setIsChecked(!isChecked);
    };
    const submitDeactivate = () => {
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/deal/${deactivateId}/deactivate/`, {headers: headersParam}
            ).then(
            (res) => {
                toast("Deactivate Successfully");
                closeDeactiveModal();
                window.location.reload();
            },
            (err) => {
                toast.error(err.message);
            }
        );
    };

    const handleTemplate = (id) => {
        const payload = {
            document_type: "Borrower",
        };
        const headersParam = APP_CONFIGURATION.HEADER_PARAMETERS;
        api
            .post(
                `${APP_CONFIGURATION.APP_CONSTANT.API_URL}marketkredit/api/v1/document/remove_template/${id}/`,
                payload,
                {headers: headersParam}
            )
            .then((res) => {
                if (res.status) {
                    toast("Document Deleted Successfully");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const closeDeactiveModal = () => {
        setShowDeactivate(false)
    }
    const handleSelectChange = (e) => {
        console.log('ed ', e.target.value)
        setCityId(e.target.value)
    }
    const handleSelectChange2 = (e) => {
        setCityId2(e.target.value)
    }
    const hanldeAddress = () => {
        setHandleAddressStatus(!handleAddressStatus)
    }


    return (<Layout>
            <main id="main" className="main">
                <ToastContainer/>
                <div className="pagetitle mt-3">
                    <h1>My Profile</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                Home
                            </li>
                            <li className="breadcrumb-item active">My Profile</li>
                        </ol>
                    </nav>
                </div>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <section className="section dashboard">
                        <div className="container-fluid mt-5">
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingTwo"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    <p className="fw-bold m-0">Borrower Details</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    {/* third */}
                                                    <div className="row">
                                                        <div className="row">
                                                            <div className="col-md-12 mt-3">
                                                                <div className="mb-3 row">
                                                                    <label
                                                                        htmlFor="staticEmail"
                                                                        className="col-sm-2 col-form-label"
                                                                    >
                                                                        Borrower Name :{" "}
                                                                    </label>
                                                                    <div className="col-sm-10">
                                                                        <AvField
                                                                            type="text"
                                                                            id="borrowerName"
                                                                            required
                                                                            name="borrowerName"
                                                                            className="form-control"
                                                                            placeholder="Enter Borrower Name"
                                                                            value={editData ? editData.borrower_name : ""}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mt-3">
                                                                <div className="mb-3 row">
                                                                    <label
                                                                        htmlFor="staticEmail"
                                                                        className="col-sm-2 col-form-label"
                                                                    >
                                                                        Primary User :{" "}
                                                                    </label>
                                                                    <div className="col-sm-10">
                                                                        <AvField
                                                                            type="select"
                                                                            id="primaryUser"
                                                                            required
                                                                            name="primaryUser"
                                                                            className="form-control"
                                                                            placeholder="Enter Primary User"
                                                                            value={editData && editData.primary_user ? editData.primary_user.id : ""}

                                                                        >
                                                                            {primaryUser && primaryUser.map((data) => {
                                                                                return (<option
                                                                                    key={data.id}
                                                                                    value={data.id}
                                                                                    label={data.first_name + ' ' + data.last_name}
                                                                                />);
                                                                            })}
                                                                        </AvField>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-12 mt-3">
                                                                <div className="mb-3 row">
                                                                    <label
                                                                        htmlFor="staticEmail"
                                                                        className="col-sm-2 col-form-label"
                                                                    >
                                                                        PAN No :{" "}
                                                                    </label>
                                                                    <div className="col-sm-10">
                                                                        <AvField
                                                                            type="text"
                                                                            id="panNom"
                                                                            name="panNom"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder="Enter PAN No"
                                                                            value={editData ? editData.pan_number : ""}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <Box>
                                                                    <DataGrid
                                                                        autoHeight
                                                                        rows={pageState && pageState.data ? pageState.data : []}
                                                                        rowCount={pageState.total}
                                                                        loading={pageState.isLoading}
                                                                        rowsPerPageOptions={[10, 30, 50, 70, 100]}
                                                                        pagination
                                                                        page={pageState.page - 1}
                                                                        pageSize={pageState.pageSize}
                                                                        paginationMode="server"
                                                                        disableSelectionOnClick
                                                                        // checkboxSelection
                                                                        // onSelectionModelChange={handleSelectionChange}
                                                                        selectionModel={selectionModel}
                                                                        onPageChange={(newPage) => {
                                                                            setPageState((old) => ({
                                                                                ...old, page: newPage + 1
                                                                            }));
                                                                        }}
                                                                        onPageSizeChange={(newPageSize) => setPageState((old) => ({
                                                                            ...old, pageSize: newPageSize
                                                                        }))}
                                                                        columns={columns8}
                                                                    />
                                                                </Box>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="accordion mt-4"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingTwo"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    <p className="fw-bold m-0">Profile</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    PAN NO :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="text"
                                                                        id="panNom"
                                                                        name="panNom"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder="Enter PAN No"
                                                                        value={editData ? editData.pan_number : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    GST NO :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="text"
                                                                        id="gstNo"
                                                                        name="gstNo"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder="Enter GST No"
                                                                        value={editData ? editData.gst_number : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    CIN NO :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="text"
                                                                        id="cinNo"
                                                                        name="cinNo"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder="Enter CIN No"
                                                                        value={editData ? editData.cin_number : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label
                                                                htmlFor="exampleFormControlInput1"
                                                                className="col-form-label"
                                                            >
                                                                Registered Address
                                                            </label>
                                                            <AvField
                                                                type="text"
                                                                id="registeredAddress"
                                                                name="registeredAddress"
                                                                className="form-control"
                                                                required
                                                                placeholder="Enter Line 1"
                                                                value={editData && editData.register_address ? editData.register_address.line1 : ""}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <AvField
                                                                type="text"
                                                                id="registeredAddress2"
                                                                name="registeredAddress2"
                                                                required
                                                                className="form-control"
                                                                placeholder="Enter Line 2"
                                                                value={editData && editData.register_address ? editData.register_address.line2 : ""}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    State :{" "}
                                                                </label>

                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        id="state"
                                                                        name="state"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder="Enter State"
                                                                        value={editData && editData.register_address ? editData.register_address.state : ""}
                                                                        onChange={(event) => handleSelectChange(event)}
                                                                    >
                                                                        <option value=''>Enter State</option>
                                                                        {allState && allState.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.name}
                                                                            />);
                                                                        })}

                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    City :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        id="rcity"
                                                                        name="city"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder="Enter City"
                                                                        value={editData && editData.register_address ? editData.register_address.city : ""}
                                                                    >
                                                                        {/* <option
                                                                        value={editData ? editData.register_address.city : ""}>
                                                                        {(editData && editData.register_address.city ?editData.register_address.city :'') || "Select City Name"}
                                                                    </option> */}
                                                                        <option value=''>Enter City</option>
                                                                        {allCity && allCity.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                    {/* <Multiselect
                                                                        displayValue="key"
                                                                        onKeyPressFn={function noRefCheck(){}}
                                                                        onRemove={function noRefCheck(){}}
                                                                        onSearch={function noRefCheck(){}}
                                                                        onSelect={function noRefCheck(){}}
                                                                        optionValueDecorator={function noRefCheck(){}}
                                                                        options={}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    Pincode :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="number"
                                                                        id="pin"
                                                                        name="pin"
                                                                        className="form-control"
                                                                        placeholder="Enter PIN"
                                                                        value={editData && editData.register_address ? editData.register_address.pincode : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: 'This field is required.'
                                                                            },
                                                                            minLength: {
                                                                                value: 6,
                                                                                errorMessage: 'Minimum 6 character required.'
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <input type="checkbox" name='sameAddress' className="ml-4"
                                                                   onClick={hanldeAddress}
                                                                   checked={handleAddressStatus}/> &nbsp;<span>Same as Registered Address</span>
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label
                                                                htmlFor="exampleFormControlInput1"
                                                                className="col-form-label"
                                                            >
                                                                Communication Address
                                                            </label>
                                                            <AvField
                                                                type="text"
                                                                id="communicationAddress"
                                                                name="communicationAddress"
                                                                className="form-control"
                                                                placeholder="Enter Line 1"
                                                                disabled={handleAddressStatus ? true : false}
                                                                required={handleAddressStatus ? false : true}
                                                                value={editData && editData.communication_address ? editData.communication_address.line1 : ""}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <AvField
                                                                type="text"
                                                                id="communicationAddress"
                                                                name="communicationAddress2"
                                                                className="form-control"
                                                                placeholder="Enter Line 2"
                                                                disabled={handleAddressStatus ? true : false}
                                                                required={handleAddressStatus ? false : true}
                                                                value={editData && editData.communication_address ? editData.communication_address.line2 : ""}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    State :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        id="mState"
                                                                        name="mState"
                                                                        className="form-control"
                                                                        required={handleAddressStatus ? false : true}
                                                                        disabled={handleAddressStatus ? true : false}
                                                                        placeholder="Enter State"
                                                                        value={editData && editData.communication_address ? editData.communication_address.state : ""}
                                                                        onChange={(event) => handleSelectChange2(event)}
                                                                    >
                                                                        <option value=''>Enter State</option>
                                                                        {allState && allState.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    City :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="select"
                                                                        id="mCity"
                                                                        name="mCity"
                                                                        required={handleAddressStatus ? false : true}
                                                                        disabled={handleAddressStatus ? true : false}
                                                                        className="form-control"
                                                                        placeholder="Enter City"
                                                                        value={editData && editData.communication_address ? editData.communication_address.city : ""}

                                                                    >
                                                                        <option value=''>Enter City</option>
                                                                        {allCity2 && allCity2.map((data) => {
                                                                            return (<option
                                                                                key={data.id}
                                                                                value={data.id}
                                                                                label={data.name}
                                                                            />);
                                                                        })}
                                                                    </AvField>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 mt-4">
                                                            <div className="mb-3 row">
                                                                <label
                                                                    htmlFor="staticEmail"
                                                                    className="col-sm-3 col-form-label"
                                                                >
                                                                    Pincode :{" "}
                                                                </label>
                                                                <div className="col-sm-9">
                                                                    <AvField
                                                                        type="number"
                                                                        id="mPin"
                                                                        name="mPin"
                                                                        required={handleAddressStatus ? false : true}
                                                                        disabled={handleAddressStatus ? true : false}
                                                                        className="form-control"
                                                                        placeholder="Enter PIN"
                                                                        value={editData && editData.communication_address ? editData.communication_address.pincode : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: 'This field is required.'
                                                                            },
                                                                            minLength: {
                                                                                value: 6,
                                                                                errorMessage: 'Minimum 6 character required.'
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="accordion mt-4"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingFour"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFour"
                                                >
                                                    <p className="fw-bold m-0">Contact Details</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div
                                                                className="create-deal-btn"
                                                                style={{textAlign: "right"}}
                                                            >
                                                                <button
                                                                    className="mb-4 btn"
                                                                    type="button"
                                                                    value="Open"
                                                                    onClick={() => openModal()}
                                                                >
                                                                    Add User
                                                                </button>
                                                            </div>
                                                            <Box>
                                                                <div
                                                                    style={{
                                                                        height: 500,
                                                                        width: "100%",
                                                                        transition: "all 1.5s linear",

                                                                    }}
                                                                >
                                                                    <DataGrid
                                                                        rows={userData ? userData : []}
                                                                        columns={columns}
                                                                        selectionModel={selectionModel}
                                                                        // onSelectionModelChange={handleRowSelection}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="accordion mt-4 mb-5"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="panelsStayOpen-headingFour"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFive"
                                                >
                                                    <p className="fw-bold m-0">Documents</p>
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-12 create-deal-btn">
                                                            {/* <button
                                                                type="button"
                                                                className="mr-3 btn"
                                                                style={{marginRight: "15px"}}
                                                                onClick={() => handleDocument()}
                                                            >
                                                                <i className="fa fa-plus mr-2"></i>
                                                                Document
                                                            </button> */}
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                style={{marginRight: "15px"}}
                                                                onClick={() => handleDownloadData()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}
                                                            >
                                                                Download Selected Documents
                                                            </button>
                                                            {/* <button
                                                                className="btn"
                                                                type="button"
                                                                style={{marginRight: "15px"}}
                                                                onClick={() => handleRemoveDocument()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}
                                                            >
                                                                Remove
                                                            </button> */}
                                                            {/* <button
                                                                type="button"
                                                                className="btn"
                                                                style={{marginRight: "15px"}}
                                                                onClick={() => handleShareWithLender()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}
                                                            >
                                                                Share with Lender
                                                            </button> */}
                                                            {/* <button
                                                                type="button"
                                                                className="btn"
                                                                style={{marginRight: "20px"}}
                                                                onClick={() => handleUnShareWithLender()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}
                                                            >
                                                                UnShare with Lender
                                                            </button> */}
                                                            {/* <button
                                                                type="button"
                                                                className="btn"
                                                                style={{marginRight: "15px"}}
                                                                onClick={() => handleShare()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}

                                                            >
                                                                Share with Borrower
                                                            </button> */}
                                                            {/* <button
                                                                type="button"
                                                                className="btn"
                                                                style={{marginRight: "15px"}}
                                                                onClick={() => handleUnShare()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}
                                                            >
                                                                UnShare with Borrower
                                                            </button> */}
                                                            {/* <button
                                                                type="button"
                                                                className="btn"
                                                                style={{marginRight: "15px"}}
                                                                onClick={() => editRecurrence()}
                                                                disabled={selectedCheckboxes.length > 0 ? false : true}
                                                            >
                                                                Edit Recurrence
                                                            </button> */}
                                                        </div>

                                                        {Object.keys(groupedData && groupedData).map((category) => {
                                                            return (<div key={category}>
                                                                    <div className="row">
                                                                        <div className="col-md-12 mt-5">
                                                                            <div className="mb-3 row">
                                                                                <label
                                                                                    htmlFor="staticEmail"
                                                                                    className="col-sm-1 col-form-label"
                                                                                >
                                                                                    Category :{" "}
                                                                                </label>
                                                                                <div className="col-sm-11">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="staticEmail"
                                                                                        placeholder={category}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {Object.keys(groupedData[category]).map((categoryId) => {
                                                                        return (<div>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <table
                                                                                        className="table mt-5 main-deal-table">
                                                                                        <thead className="mb-3">
                                                                                        <tr className="text-center">
                                                                                            <th
                                                                                                scope="col"
                                                                                                className="data-check-input"
                                                                                            >
                                                                                            </th>
                                                                                            <th scope="col">Document
                                                                                                Name
                                                                                            </th>
                                                                                            <th scope="col">Status</th>
                                                                                            <th scope="col">Borrower
                                                                                                Comments
                                                                                            </th>
                                                                                            <th scope="col">Kreditserve
                                                                                                Comments
                                                                                            </th>
                                                                                            <th scope="col">Actions</th>
                                                                                        </tr>
                                                                                        </thead>

                                                                                        <tbody>
                                                                                        {groupedData[category][categoryId].map((item) => {
                                                                                            return (<tr
                                                                                                key={item.id}
                                                                                            >
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id={`${item.id}|${item.document_type}`}
                                                                                                        checked={selectedCheckboxes.includes(`${item.id}|${item.document_type}`)}
                                                                                                        onChange={handleCheckboxChange}
                                                                                                    />

                                                                                                </td>
                                                                                                <td>
                                                                                                    {item.title}
                                                                                                </td>
                                                                                                <td>{item.status}</td>
                                                                                                <td>
                                                                                                    <textarea
                                                                                                        type="text"
                                                                                                        id="borrowerComment"
                                                                                                        name="borrowerComment"
                                                                                                        className="form-control"
                                                                                                        placeholder="Enter Borrower Comment"
                                                                                                        value={item ? item.borrower_comments : ""}
                                                                                                        onChange={(e) => handleEdit(item.id, "borrower_comments", e.target.value)}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <textarea
                                                                                                        type="text"
                                                                                                        id="kreditserveComment"
                                                                                                        name="kreditserveComment"
                                                                                                        disabled={true}
                                                                                                        className="form-control"
                                                                                                        placeholder="Enter KreditServe Comment"
                                                                                                        value={item ? item.kreditserve_comments : ""}
                                                                                                        onChange={(e) => handleEdit(item.id, "kreditserve_comments", e.target.value)}
                                                                                                    />
                                                                                                </td>
                                                                                                <td className="create-deal-btn">
                                                                                                    {item.document ? <a
                                                                                                        type="button"
                                                                                                        href={item.document}
                                                                                                        target="_blank"
                                                                                                        title="View Document"
                                                                                                        className="btn"
                                                                                                        style={{marginRight: "10px"}}><i
                                                                                                        className="fa fa-eye"></i>
                                                                                                    </a> : <button
                                                                                                        type="button"
                                                                                                        className="btn"
                                                                                                        style={{marginRight: "10px"}}
                                                                                                        title="Upload Document"
                                                                                                        onClick={() => handleUploadData(item.id, item.document_type)}

                                                                                                    >
                                                                                                        <i className="fa fa-upload"></i>
                                                                                                    </button>}
                                                                                                    {item.document && item.status == 'verified' ?
                                                                                                        <i className="fa fa-trash"
                                                                                                           style={{marginRight: "10px"}}
                                                                                                           onClick={() => handleDelte(item.id, item.document_type)}></i> : ''}


                                                                                                    {item.template &&
                                                                                                        <a
                                                                                                            type="button"
                                                                                                            href={item.template}
                                                                                                            target="_blank"
                                                                                                            title="View Document"
                                                                                                            className="btn"
                                                                                                            style={{marginRight: "10px"}}><i
                                                                                                            className="fa fa-eye"></i>
                                                                                                        </a>

                                                                                                    }


                                                                                                </td>
                                                                                            </tr>)
                                                                                        })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>

                                                                        </div>)
                                                                    })}
                                                                </div>

                                                            )
                                                        })}


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4 create-deal-btn">
                                <FormGroup>
                                    <Button>Submit</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </section>
                </AvForm>
            </main>
            <Modal
                visible={visible}
                width="1000"
                height="590"
                effect="fadeInUp"
                onClickAway={() => closeModal()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeModal()}>
                        Close
                    </a>
                </div>
                <div className="container p-3">
                    <h2 className="text-center">Add User</h2>
                    <div className="container mt-2">
                        <AvForm onValidSubmit={handleValidUserSubmit}>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Name :{" "}
                                        </label>
                                        <div className="col-sm-10">
                                            <AvField
                                                type="text"
                                                id="name"
                                                name="name"
                                                required
                                                className="form-control"
                                                placeholder="Enter Full Name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Contact Number :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                id="cNumber"
                                                name="cNumber"
                                                required
                                                className="form-control"
                                                placeholder="Enter Contact Number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Email Id :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="email"
                                                id="emailId"
                                                required
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Enter Email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Designation :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                required
                                                id="designation"
                                                name="designation"
                                                className="form-control"
                                                placeholder="Enter Designation"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-7 col-form-label"
                                        >
                                            All communications to be sent :{" "}
                                        </label>
                                        <div className="col-sm-5 d-flex">
                                            <AvRadioGroup
                                                name="all_communication_sent"
                                                required
                                                value={String(mainStatus)}
                                                onChange={handleRadioChange}
                                            >
                                                <AvRadio label="Yes" value="true"/>
                                                <AvRadio label="No" value="false"/>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4 create-deal-btn">
                                    <FormGroup>
                                        <Button>Submit</Button>
                                    </FormGroup>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={editShowStatus}
                width="1000"
                height="590"
                effect="fadeInUp"
                onClickAway={() => closeModal()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeModal()}>
                        Close
                    </a>
                </div>
                <div className="container p-3">
                    <h2 className="text-center">Edit User</h2>
                    <div className="container mt-2">
                        <AvForm onValidSubmit={handleEditValidUserSubmit}>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Name :{" "}
                                        </label>
                                        <div className="col-sm-10">
                                            <AvField
                                                type="text"
                                                id="name"
                                                name="name"
                                                required
                                                className="form-control"
                                                placeholder="Enter Full Name"
                                                value={editUserData ? editUserData.first_name : ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Contact Number :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                id="cNumber"
                                                name="cNumber"
                                                required
                                                className="form-control"
                                                placeholder="Enter Contact Number"
                                                value={editUserData && editUserData.phones && Array.isArray(editUserData.phones) ? editUserData.phones.map((data) => data.number) : ''}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Email Id :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="email"
                                                id="emailId"
                                                required
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Enter Email"
                                                value={editUserData && editUserData.emails && Array.isArray(editUserData.emails) ? editUserData.emails.map((data) => data.email_id) : ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label
                                            htmlFor="staticEmail"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Designation :{" "}
                                        </label>
                                        <div className="col-sm-8">
                                            <AvField
                                                type="text"
                                                required
                                                id="designation"
                                                name="designation"
                                                className="form-control"
                                                placeholder="Enter Designation"
                                                value={editUserData ? editUserData.designation : ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="mb-3 row">
                                        <label htmlFor="staticEmail" className="col-sm-7 col-form-label">
                                            All communications to be sent :{" "}
                                        </label>
                                        <div className="col-sm-5">
                                            <AvRadioGroup
                                                name="communications_to_send"
                                                required
                                                value={String(mainStatus)}
                                                onChange={handleRadioChange}
                                            >
                                                <AvRadio label="Yes" value="true"/>
                                                <AvRadio label="No" value="false"/>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4 create-deal-btn">
                                    <FormGroup>
                                        <Button>Submit</Button>
                                    </FormGroup>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showDuplicate}
                width="500"
                height="200"
                effect="fadeInDown"
                onClickAway={() => closeDuplicateModal()}
            >
                <div className="text-center" style={{padding: "5px"}}>
                    <h3 style={{paddingTop: "15px"}}>Duplicate</h3>
                    <p>Are you sure want to make duplicate?</p>
                    <button
                        className="btn admin-sales-queue"
                        style={{
                            marginTop: "15px", backgroundColor: "#4154f1", color: "white", borderRadius: "0px",
                        }}
                        onClick={() => submitDuplicate()}
                    >
                        Yes
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn admin-sales-queue"
                        style={{
                            marginTop: "15px", backgroundColor: "#4154f1", color: "white", borderRadius: "0px",
                        }}
                        onClick={() => closeDuplicateModal()}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
            {/* <Modal
                visible={showDocument}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeDocument()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeDocument()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">

                    <div className="container p-3">
                        <h2 className="text-center">Create New Document </h2>
                        <Select
                            options={options}
                            onInputChange={handleInputChange}
                            onChange={setSelectedDocument}
                            placeholder="Search and select a document..."
                            className="mt-5"
                        />
                        <div className="text-center mt-4">
                            <button onClick={handledocumentSubmit} className="btn btn-success">Submit</button>
                        </div>
                    </div>
                </div>
            </Modal> */}
            <Modal
                visible={showDocumentData}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeDocument()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeDocument()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">
                    <div className="container p-3">
                        <h2 className="text-center">Create New Document </h2>
                        <Select
                            options={options}
                            onInputChange={handleInputChange}
                            onChange={setSelectedDocument}
                            placeholder="Search and select a document..."
                            className="mt-5"
                        />
                        <div className="text-center mt-4">
                            <button
                                onClick={handledocumentSubmit}
                                className="btn btn-success"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showUploadDocument}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeUploadData()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeUploadData()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">
                    <h2 className="text-center">Create Upload Document </h2>
                    <input
                        type="file"
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    />
                    <div className="text-center create-deal-btn">
                        <button type="submit" className="mt-4 btn" onClick={handleFileUpload}>
                            Upload
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showUploadTemplate}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeUploadTemplate()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeUploadTemplate()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">
                    <h2 className="text-center">Create Template Document </h2>
                    <input
                        type="file"
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    />
                    <div style={{textAlign: "center"}} className="p-3">
                        <button type="submit" className="mt-4 btn btn-success" onClick={handleFileUploadTemplate}>
                            Upload
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showDeactivate}
                width="500"
                height="200"
                effect="fadeInDown"
                onClickAway={() => closeDeactiveModal()}
            >
                <div className="text-center" style={{padding: "5px"}}>
                    <h3 style={{paddingTop: "15px"}}>Deactivate</h3>
                    <p>Are you sure want to deactivate?</p>
                    <button
                        className="btn admin-sales-queue"
                        style={{
                            marginTop: "15px",
                            backgroundColor: "#4154f1",
                            color: "white",
                            borderRadius: "0px",
                        }}
                        onClick={() => submitDeactivate()}
                    >
                        Yes
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn admin-sales-queue"
                        style={{
                            marginTop: "15px",
                            backgroundColor: "#4154f1",
                            color: "white",
                            borderRadius: "0px",
                        }}
                        onClick={() => closeDeactiveModal()}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
            <Modal
                visible={editReurrenceShow}
                width="800"
                height="320"
                effect="fadeInUp"
                onClickAway={() => closeEditRecurrence()}
            >
                <div style={{textAlign: "right"}} className="p-3">
                    <a href="javascript:void(0);" onClick={() => closeEditRecurrence()}>
                        Close
                    </a>
                </div>

                <div className="container p-3">
                    <h2 className="text-center">Edit Recurrence </h2>
                    <div className="row">
                        <div className="col-md-12">
                            <table
                                className="table mt-5 main-deal-table">
                                <thead className="mb-3">
                                <tr className="text-center">
                                    <th
                                        scope="col"
                                        className="data-check-input"
                                    >
                                    </th>
                                    <th scope="col">Document Name</th>

                                    <th scope="col">Recurrence?</th>
                                    <th scope="col">No of Days

                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {mainRecurrence && mainRecurrence.map((item) => {
                                    return (
                                        <tr
                                            key={item.id}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    // id={item.id}
                                                    // checked={selectedCheckboxes.includes(item.id)}
                                                    // onChange={handleCheckboxChange}
                                                />

                                            </td>
                                            <td>
                                                {item.title}
                                            </td>
                                            <td>
                                                <label className="d-flex">
                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChangeMain}
                                                        style={{height: '50px', width: '35px'}}
                                                        value={item.is_recurring}
                                                    />
                                                    Yes
                                                </label>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    id="noOfDays"
                                                    name="noOfDays"
                                                    className="form-control"
                                                    placeholder="No of Days"
                                                    value={item.recurring_days}

                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </Modal>;

        </Layout>
    );
};
export default BorrowerProfile;
